import { HTMLAttributes, ReactNode, useState } from 'react';
import {Autocomplete, TextFieldProps, TextField, Skeleton, Box, Typography } from '@mui/material';
import { ICustomInput, IList } from '../types';
import { EASSelect } from './EASSelect';
import { EASRadio } from './EASRadio';
import { EASCheck } from './EASCheck';


type EASCustomProps = { 
    customInput: ICustomInput | undefined;
    helperText?: string ;
    value?: string | Array<string>;
    isLoading?: boolean | undefined;
    onChange?: (event: any, value: any | null) => void;
  } 



export const EASCustom: React.FC<EASCustomProps> = ({...props}) => {
    

    return (
        <>
            {(
              () => {
                switch(props.customInput?.type_custom_input) {
                  case '1': 
                      return  <EASSelect
                                isLoading={props.isLoading}
                                defaultValue={null}
                                size="small"
                                options={props?.customInput?.options_custom_input ? props.customInput.options_custom_input : []}
                                label={props?.customInput?.label_custom_input}
                                getOptionLabel={(options) => options.text}
                                value={props.value}
                                onChangeSelect={props.onChange}
                                error={!!props.helperText}
                                helperText={props.helperText}
                            />;           
                  case '2': 
                    return  <EASRadio
                              isLoading={props.isLoading}
                              label={props?.customInput?.label_custom_input}
                              options={props?.customInput?.options_custom_input ? props.customInput.options_custom_input : []}
                              value={props.value}
                              onChange={props.onChange}
                            />;   
                    
                  case '3':
                    return  <EASCheck
                              isLoading={props.isLoading}
                              label={props?.customInput?.label_custom_input}
                              options={props?.customInput?.options_custom_input ? props.customInput.options_custom_input : []}
                              value={props?.value && Array.isArray(props.value) ? props.value : []}
                              onChange={props.onChange}
                              error={!!props.helperText}
                              helperText={props.helperText}
                            />;




                  default: 
                  return <Typography variant="overline" gutterBottom sx={{ display: 'block' }}>
                          Campo Personalizado não definido
                        </Typography>;
              }})()
            }
        </>


    );
};
