import React, { useState, Ref, useImperativeHandle, useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Divider, Avatar, Tabs, Tab,Skeleton, Typography } from '@mui/material';
 
 
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { MentoringService } from '../../shared/services/api/mentoring/MentoringService';

import { Environment } from '../../shared/environment';
import { useAuthContext } from '../../shared/contexts';

import { EASButtonList, EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';
import { UtilService } from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
import { MentoryTabDashboard } from './MentoryTabDashboard';
import { MentoryTabDetailing } from './MentoryTabDetailing';
import { MentoryTabMeetings } from './MentoryTabMeetings';



import { MentoryDialogUpdate } from './MentoryDialogUpdate';
import { MentoryTabPresentations } from './MentoryTabPresentations';
import { EASDialogAlert } from '../../shared/components';


export interface IRefMentory {
    saveMentory: () => void;
}

interface TabMentoryProps {
    children: React.ReactNode;
    index: number;
    value: number;
}
  

const MentoryTabPanel = (props: TabMentoryProps) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  };
  
  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  interface IMentoryTabDefaultProps {
    mentoryEdit?: IMentory;
    companies?:Array<IList>;
    programs?:Array<IList>;
    programTypes?: Array<IList>;
    tags?:Array<IList>;
    isLoading?:boolean;
    storeUpdate:(mentory:IMentory) => void;
  }

const MentoryTabDefault = React.forwardRef<IRefMentory, IMentoryTabDefaultProps>(({mentoryEdit, tags, companies, programs, programTypes, isLoading, storeUpdate}:IMentoryTabDefaultProps, ref:Ref<IRefMentory>) => {

    const { user } = useAuthContext();
    const [mentoryError, setMentoryError] = useState<IMentory | null>(null);
    const [mentory, setMentory] = useState<IMentory | undefined>(mentoryEdit);
    const [openUpdateTeam, setOpenUpdateTeam] = useState(false);
    const [typeUpdateTeam, setTypeUpdateTeam] = useState<string>('');

    const [valueTab, setValueTab] = React.useState<number>(0);


    const [openDialog, setOpenDialog] = useState(false);

    const [loadingDialog, setLoadingDialog] = useState(false);
    const [openDialogSuccess, setOpenDialogSuccess] = useState(false);

    const handleCancelDialog = () => {
        
        setMentory(existingValues => ({
            ...existingValues,
            id_program: '',
        }));
        setOpenDialog(false);
        setOpenDialogSuccess(false);
    };
    
    
    const handleConfirmeDialog = () => {
        const updateMentory: IMentory = {
            id_mentory: mentory?.id_mentory,
            id_program: mentory?.id_program,
            user_update: user?.id,
        };     
        storeUpdate(updateMentory);
    };


    const handleChangeValueTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const handleClickUpdate = (type?:string) => {
        if(type){
            setTypeUpdateTeam(type);
            setOpenUpdateTeam(true); 
        }
    }; 
    
    useImperativeHandle(ref, () => ({
        saveMentory,
    }));

    const updateProgram = () => {
        setOpenDialog(true);
    };



    const isValidMentory = yup.object({
        name_mentory: yup.string().required('Este campo é obrigatório'),
        start_mentory: yup.string().required('Este campo é obrigatório'),
        end_mentory: yup.string().required('Este campo é obrigatório'),
    });

    const saveMentory = () => {
        isValidMentory
            .validate( mentory , { abortEarly: false })
            .then(validateMentory => {
                const updateMentory: IMentory = {
                    id_mentory: mentory?.id_mentory,
                    name_mentory: validateMentory.name_mentory,
                    start_mentory: validateMentory.start_mentory,
                    end_mentory: validateMentory.end_mentory,
                    description_mentory: mentory?.description_mentory,
                    objective_sample_mentory: mentory?.objective_sample_mentory,
                    id_program: mentory?.id_program,
                    id_module: mentory?.id_module,
                    min_grade_mentory: mentory?.min_grade_mentory,	
                    max_grade_mentory: mentory?.max_grade_mentory,	
                    step_grade_mentory: mentory?.step_grade_mentory,
                    id_company: mentory?.id_company,
                    tags: mentory?.tags,
                    user_update: user?.id,
                };     
              storeUpdate(updateMentory);
          })
          .catch((errors: yup.ValidationError) => {
              errors.inner.forEach(error => {
                  if(error.path){
                      const keyError = error.path;                    
                      setMentoryError(existingValues => ({
                                          ...existingValues,
                                          [keyError]: error.message
                                      }));                        
                  }
              });
          });
    };

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
            <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                                <CardHeader
                                    title="Participantes"
                                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                                />
                                <Divider/>
                                <CardContent>
                                    {(isLoading )  && (
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item >
                                                        <Skeleton variant="circular" width={55} height={55} /> 
                                                    </Grid>
                                                    <Grid item zeroMinWidth sm={true}>
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={20} height={11} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={13} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={12} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{mt:'10px'}} >
                                                        <Skeleton variant="circular" width={10} height={10} /> 
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{mt:'10px'}}/>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item >
                                                        <Skeleton variant="circular" width={55} height={55} /> 
                                                    </Grid>
                                                    <Grid item zeroMinWidth sm={true}>
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={20} height={11} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={13} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={12} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{mt:'10px'}} >
                                                        <Skeleton variant="circular" width={10} height={10} /> 
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{mt:'10px'}}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    )}
                                    {(!(isLoading ) ) && (
                                    <Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} >
                                                    <Grid container spacing={2}>
                                                        <Grid item >
                                                            <Avatar sx={{width:55, height:55}}  alt={mentory?.name_mentorado} src={mentory?.image_mentorado ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + mentory?.image_mentorado  :  ''} />
                                                        </Grid>
                                                        <Grid item zeroMinWidth sm={true}>
                                                            <Grid container spacing={1} >
                                                                <Grid item xs={12}>
                                                                    <Typography component="h6" variant="caption" sx={{fontSize:11}}>Mentorado</Typography>
                                                                    <Typography component="h4" variant="subtitle2" sx={{fontWeight:500}}>{mentory?.name_mentorado}</Typography>
                                                                    <Typography component="h5" variant="caption">{mentory?.email_mentorado}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sx={{mt:'10px'}} >
                                                            <EASButtonList 
                                                                title="Alterar Menorado"
                                                                onClick={() => handleClickUpdate('Mentorado')} 
                                                                typeIcon="edit"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider sx={{mt:'10px'}}/>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Grid container spacing={2}>
                                                        <Grid item >
                                                            <Avatar sx={{width:55, height:55}}  alt={mentory?.name_mentor} src={mentory?.image_mentor ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + mentory?.image_mentor  :  ''} />
                                                        </Grid>
                                                        <Grid item zeroMinWidth sm={true}>
                                                            <Grid container spacing={1} >
                                                                <Grid item xs={12}>
                                                                    <Typography component="h6" variant="caption" sx={{fontSize:11}}>Mentor</Typography>
                                                                    <Typography component="h4" variant="subtitle2" sx={{fontWeight:500}}>{mentory?.name_mentor}</Typography>
                                                                    <Typography component="h5" variant="caption">{mentory?.email_mentor}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sx={{mt:'10px'}} >
                                                            <EASButtonList 
                                                                title="Alterar Mentor"
                                                                onClick={() => handleClickUpdate('Mentor')} 
                                                                typeIcon="edit"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                                <CardHeader
                                    title="Víncular a um Programa"
                                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                                />
                                <Divider/>
                                <CardContent>
                                    {(isLoading )  && (
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item >
                                                        <Skeleton variant="circular" width={55} height={55} /> 
                                                    </Grid>
                                                    <Grid item zeroMinWidth sm={true}>
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={20} height={11} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={13} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={12} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{mt:'10px'}} >
                                                        <Skeleton variant="circular" width={10} height={10} /> 
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{mt:'10px'}}/>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item >
                                                        <Skeleton variant="circular" width={55} height={55} /> 
                                                    </Grid>
                                                    <Grid item zeroMinWidth sm={true}>
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={20} height={11} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={13} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={12} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{mt:'10px'}} >
                                                        <Skeleton variant="circular" width={10} height={10} /> 
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{mt:'10px'}}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    )}
                                    {(!(isLoading ) ) && (
                                    <Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} >
                                                    <EASSelect 
                                                        isLoading={isLoading}
                                                        options={programs ? programs :[] }
                                                        label="Programa"
                                                        getOptionLabel={(option) => option.text}                                
                                                        value={programs ? programs.find(item => item.value == mentory?.id_program) : ''}                                                                 
                                                        onChangeSelect={(event, value:IList) => {
                                                            const valueDetail = (value && value.value) ? value.value : '';
                                                            setMentory(existingValues => ({
                                                                ...existingValues,
                                                                id_program: valueDetail,
                                                            }));
                                                            if(valueDetail != '')
                                                                updateProgram();
                                                        }}
                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={12} >
                                                    {!mentory?.id_program ? (
                                                        <Typography variant="caption"> Nenhum Programa Associado</Typography>
                                                    ):(
                                                        <Grid container spacing={2}>
                                                        <Grid item >
                                                            <Avatar variant="square" sx={{width:55, height:55}}  alt={mentory?.name_company} src={mentory?.logo_company ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + mentory?.logo_company  :  ''} />
                                                        </Grid>
                                                        <Grid item zeroMinWidth sm={true}>
                                                            <Grid container spacing={1} >
                                                                <Grid item xs={12}>
                                                                    <Typography component="h6" variant="caption" sx={{fontSize:11}}>Empresa</Typography>
                                                                    <Typography component="h4" variant="subtitle2" sx={{fontWeight:500}}>{mentory?.name_company}</Typography>
                                                                    <Typography component="h5" variant="caption">{mentory?.email_company}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    )}

                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                    <CardHeader
                            sx={{py:'2.5px'}}
                            title={
                        <Tabs variant="scrollable" value={valueTab} onChange={handleChangeValueTab} allowScrollButtonsMobile  textColor='primary' sx={{'& .MuiTabs-indicator': { backgroundColor: 'none', height: 0}}}>
                            <Tab label="Dados do Processo" {...a11yProps(0)} sx={{fontSize: 16, }}/>
                            <Tab label="Visão Geral" {...a11yProps(1)} sx={{fontSize: 16, }}/>
                            <Tab label="Competências" {...a11yProps(21)} sx={{fontSize: 16}}/>
                            <Tab label="Sessões" {...a11yProps(3)} sx={{fontSize: 16}}/>
                            <Tab label="Apresentação Final" {...a11yProps(4)} sx={{fontSize: 16}}/>
                        </Tabs>}
                    />
                        <Divider/>
                        <CardContent>
                            <MentoryTabPanel value={valueTab} index={0}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    onFocus={() => 
                                                        setMentoryError(existingValues => ({
                                                        ...existingValues,
                                                        name_mentory: '',
                                                        }))
                                                    }
                                                    label="Nome do Processo"
                                                    type="text"
                                                    value={mentory?.name_mentory}
                                                    onChange={(e) => 
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            name_mentory: e.target.value,
                                                        }))
                                                    }
                                                    disabled={!!(mentory?.id_program)}
                                                    error={!!mentoryError?.name_mentory}
                                                    helperText={mentoryError?.name_mentory}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <EASPicker 
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    onFocus={() => 
                                                        setMentoryError(existingValues => ({
                                                        ...existingValues,
                                                        start_mentory: '',
                                                        }))
                                                    }
                                                    onOpen={() => 
                                                        setMentoryError(existingValues => ({
                                                        ...existingValues,
                                                        start_mentory: '',
                                                        }))
                                                    }
                                                    label="Data de Início"
                                                    value={mentory?.start_mentory} 
                                                    error={!!mentoryError?.start_mentory}
                                                    helperText={mentoryError?.start_mentory}
                                                    onChangePiker={(e) => {  
                                                                        if(e instanceof Date) {         
                                                                        setMentory(existingValues => ({
                                                                                ...existingValues,
                                                                                start_mentory: e.toISOString().split('T')[0],
                                                                            }));
                                                                        }}
                                                                    }
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <EASPicker 
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    onFocus={() => 
                                                        setMentoryError(existingValues => ({
                                                        ...existingValues,
                                                        end_mentory: '',
                                                        }))
                                                    }
                                                    onOpen={() => 
                                                        setMentoryError(existingValues => ({
                                                        ...existingValues,
                                                        end_mentory: '',
                                                        }))
                                                    }
                                                    label="Data de Término"
                                                    value={mentory?.end_mentory} 
                                                    error={!!mentoryError?.end_mentory}
                                                    helperText={mentoryError?.end_mentory}
                                                    onChangePiker={(e) => {  
                                                                        if(e instanceof Date) {         
                                                                        setMentory(existingValues => ({
                                                                                ...existingValues,
                                                                                end_mentory: e.toISOString().split('T')[0],
                                                                            }));
                                                                        }}
                                                                    }
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <EASInput 
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    type="number"
                                                    min="-10"
                                                    max="100"                                                    
                                                    defaultValue="0"
                                                    label="Nota Mínima"
                                                    value={mentory?.min_grade_mentory ? mentory.min_grade_mentory : '0'}
                                                    onChange={(e) => 
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            min_grade_mentory: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                                        }))      
                                                    }
                                                    error={!!mentoryError?.min_grade_mentory}
                                                    helperText={mentoryError?.min_grade_mentory}

                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}  md={2}>
                                                <EASInput 
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    type="number"
                                                    min="-10"
                                                    max="100"  
                                                    defaultValue="10"
                                                    label="Nota Máxima"
                                                    value={mentory?.max_grade_mentory ? mentory?.max_grade_mentory : '10'}
                                                    onChange={(e) => 
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            max_grade_mentory: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                                        }))      
                                                    }
                                                    error={!!mentoryError?.max_grade_mentory}
                                                    helperText={mentoryError?.max_grade_mentory}

                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <EASInput 
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    type="number"
                                                    min="0"
                                                    max="10"
                                                    defaultValue="1"
                                                    label="Incremento"
                                                    value={mentory?.step_grade_mentory ? mentory?.step_grade_mentory : '1'}
                                                    onChange={(e) => 
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            step_grade_mentory: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                                        }))      
                                                    }
                                                    error={!!mentoryError?.step_grade_mentory}
                                                    helperText={mentoryError?.step_grade_mentory}

                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3}>
                                                <EASRadio
                                                    disabled={!!(mentory?.id_program)}
                                                    label="Sugerir Comportamentos"
                                                    isLoading={isLoading}
                                                    options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
                                                    value={mentory?.objective_sample_mentory}
                                                    onChange={(e) => 
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            objective_sample_mentory: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Grid> 
                                            <Grid item xs={12} md={6} lg={3}>
                                                <EASSelect 
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    defaultValue={null}
                                                    options={programTypes ? programTypes : []}
                                                    label="Tipo"
                                                    getOptionLabel={(option) => option.text}
                                                    value={programTypes && programTypes.find(item => item.value == mentory?.id_module)}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setMentory(existingValues => ({
                                                                ...existingValues,
                                                                id_module: value.value,
                                                            }));
                                                        }
                                                        
                                                    }
                                                    }  
                                                    error={!!mentoryError?.id_module}
                                                    helperText={mentoryError?.id_module}
                                                    />
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={6}>
                                                <EASSelect 
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    options={companies ? companies :[] }
                                                    label="Empresa"
                                                    getOptionLabel={(option) => option.text}                                
                                                    value={companies ? companies.find(item => item.value == mentory?.id_company) : ''}                                                                 
                                                    onChangeSelect={(event, value:IList) => {
                                                        const valueDetail = (value && value.value) ? value.value : '';
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            id_company: valueDetail,
                                                        }));
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EASSelect 
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    options={tags ? tags :[]}
                                                    label="Tags"
                                                    getOptionLabel={(option) => option.text}                                  
                                                    value={mentory?.tags && tags && mentory.tags.map((tag) => tags.find(item => item.value == tag))}                                  
                                                    onChangeSelect={(event, values:Array<IList>) => {
                                                            const NewValue:Array<string> = [];
                                                            values.map((value) => NewValue.push(value.value));
                                                            setMentory(existingValues => ({
                                                                ...existingValues,
                                                                tags: NewValue,
                                                            }));
                                                            } 
                                                        } 
                                                    fullWidth
                                                    multipleSelect
                                                    filterSelectedOptions
                                                    disableClearable
                                                />
                                            </Grid>
                                            {/*
                                            <Grid item xs={12} sm={4} md={3}>
                                                <EASRadio
                                                    label="Ativar Inscrições"
                                                    isLoading={isLoading}
                                                    options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
                                                    value={mentory?.enroll_mentory}
                                                    onChange={(e) => 
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            enroll_mentory: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Grid> 
                                            */}
                                            <Grid item xs={12}> 
                                                <EASInput
                                                    disabled={!!(mentory?.id_program)}
                                                    isLoading={isLoading}
                                                    multiline={true}
                                                    rows={6}
                                                    onFocus={() => 
                                                        setMentoryError(existingValues => ({
                                                        ...existingValues,
                                                        description_mentory: '',
                                                        }))
                                                    }
                                                    label="Descrição"
                                                    type='text'
                                                    value={mentory?.description_mentory}                                
                                                    onChange={(e) => 
                                                        setMentory(existingValues => ({
                                                            ...existingValues,
                                                            description_mentory: e.target.value,
                                                        }))
                                                    }
                                                    error={!!mentoryError?.description_mentory}
                                                    helperText={mentoryError?.description_mentory}
                                                />
                                            </Grid>
                                        </Grid>    
                                    </Grid>
                                </Grid>
                            </MentoryTabPanel>
                            <MentoryTabPanel value={valueTab} index={1}>
                                <MentoryTabDashboard mentory={mentory} />
                            </MentoryTabPanel>
                            <MentoryTabPanel value={valueTab} index={2}>
                                <MentoryTabDetailing mentory={mentory} />
                            </MentoryTabPanel>
                            <MentoryTabPanel value={valueTab} index={3}>
                                <MentoryTabMeetings mentory={mentory} />
                            </MentoryTabPanel>
                            <MentoryTabPanel value={valueTab} index={4}>
                                <MentoryTabPresentations mentory={mentory} />
                            </MentoryTabPanel>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <MentoryDialogUpdate open={openUpdateTeam} mentory={mentory} type={typeUpdateTeam} setOpen={(e) => setOpenUpdateTeam(e)} />
            <EASDialogAlert 
                type="warning"
                title="Vincular a um Programa"
                statusOpen={openDialog}
                actionCancel={handleCancelDialog}
                actionConfirme={handleConfirmeDialog}
                loading={loadingDialog}
            >
                <Typography component="span">Você tem certeza que deseja vincular este processo ao um programa? Todas as informações do processo serão substituidas pelas informações do programa.</Typography>
            </EASDialogAlert>
        </Box>
    );
});

MentoryTabDefault.displayName = 'MentoryTabDefault';

export default MentoryTabDefault;
