

import React, { RefObject } from 'react';
import ReactCrop, { PercentCrop } from 'react-image-crop';
import { Box, CircularProgress } from '@mui/material';

import 'react-image-crop/dist/ReactCrop.css';

interface IEASFileUploadPreviewProps {
  file: File;
  isLoadingUpload: boolean;
  percentCrop: PercentCrop;
  previewCanvasRef?:RefObject<HTMLCanvasElement>;
  imgRef?:RefObject<HTMLImageElement>
  setPercentCrop: (PercentCrop:PercentCrop) => void;
  circularCrop: boolean;
  aspectRatio:number;
}

//const ASPECT_RATIO = 1;
const MIN_DIMENSION = 100;

export const EASFileUploadPreview:React.FC<IEASFileUploadPreviewProps>  = ({...props}) => {

  const fileUrl = URL.createObjectURL(props.file);

  return (
    <>
    {props.isLoadingUpload && (
      <Box sx={{ width:'100% !important', height:180, textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
        <CircularProgress size={70}/>
      </Box>
    )}
    {!props.isLoadingUpload && (
    <Box sx={{ width:'100% !important'}}>
      
      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',backgroundColor:'#a9a9a9', minWidth:'100% !important'}}>
        <ReactCrop
            crop={props.percentCrop}
            onChange={(pixelCrop, percentCrop) => props.setPercentCrop(percentCrop)}
            circularCrop={props.circularCrop}
            keepSelection
            aspect={props.aspectRatio}
            minWidth={MIN_DIMENSION}
        >
          <img
            ref={props.imgRef}
            src={fileUrl}
            alt="Upload"
            style={{ maxHeight: '70vh'}}
          />
        </ReactCrop>
      </Box>
     
      {props.percentCrop && (
        <canvas
          ref={props.previewCanvasRef}
          className="mt-4"
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: 150,
            height: 150,
          }}
        />
      )}
      
    </Box>
        
    )}
    </>

  );
};
