import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Stack, Avatar, TableHead, TableRow, TableBody, TableCell, TableContainer, Typography, Chip, Skeleton, Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';


import { Environment } from '../../shared/environment';

import { IPerson } from '../../shared/types';
import { OperatorsService } from '../../shared/services/api/operators/OperatorsService';
import { PeopleService } from '../../shared/services/api/people/PeopleService';

import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { EASCard, EASDialogAlert, PaginationComponent} from '../../shared/components';

import { OperatorsNewOperator } from './OperatorsNewOperator';

export const OperatorsList:React.FC = (() => {

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const randomString = useRef<string | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  const [updateList, setUpdateList] = useState(false);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idOperator, setIdOperator] = useState<string | null>(null);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['operator-operator-list', [search, page]],() =>  OperatorsService.getOperators(page, search), {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de operadores.', { variant:'error'});
    },
  });

  const { mutateAsync:storeRemoveOperator } = useMutation((idPerson:string) => OperatorsService.deleteOperator(idPerson), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-operator-list');
      queryClient.invalidateQueries('operator-operators-list');
      enqueueSnackbar('Operador removido com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();      
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover o operador!', { variant:'error'});
      setLoadingDialog(false);
    },
  });

  const { mutateAsync:storeResetPassword }  = useMutation((resetPassPerson:IPerson) =>  PeopleService.updatePerson(resetPassPerson), {
    onSuccess: () => {
      enqueueSnackbar('Senha Redefinida com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();

      setTitleDialog('Senha Redefinida com sucesso');
      setTextDialog(<>
                      <Typography component="span" sx={{display:'flex'}}>A nova senha desta pessoa é:<strong>{randomString.current}</strong>.</Typography>
                      <Typography component="span">Quando for realizado o próximo login, será solicitado que seja registrado uma nova senha.</Typography>
                    </>
                    );
      setOpenDialogSuccess(true);
      randomString.current = null;

    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar redefinir a senha desta pessoa', { variant:'error'});
    },
  });

  const handleClickRemoveOperator = (id_user?:string) => {
    if(id_user){
      setIdOperator(id_user);
      setTitleDialog('Excluir Operador');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir este operador? A exclusão do operador não afetará outros vínculos do cadastro desta pessoa.</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 


  const handleClickResetOperator = (id_user?:string) => {
    if(id_user){
      setIdOperator(id_user);
      setTitleDialog('Redefinir Senha');
      setTextDialog(<Typography component="span">Você tem certeza que deseja redefinir a senha deste operador/pessoa? Ao confirmar, será gerado uma nova senha aleatória para este operador/pessoa.</Typography>);
      setExecuteDialog('reset');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdOperator(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };

  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removeOperator();
      break;
      case 'reset':
        resetPassword();
      break;
    }
  };



  const removeOperator = () => {
    setLoadingDialog(true);
    if(idOperator){
      storeRemoveOperator(idOperator);
    }
  };

  const resetPassword= () => {
    setLoadingDialog(true);
    if(idOperator){
      const newRandomstring = Math.random().toString(36).slice(-8);
      randomString.current = newRandomstring;
      const resetPerson:IPerson = {
        id_user:idOperator,
        password_user:newRandomstring,
        activation_user:'1',
      };
      storeResetPassword(resetPerson);
    }
  };



  return (
    <>          
      <EASCard 
        titleCard="Operadores"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                    </Stack>
                    }>
                    
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Status
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Acesso
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (data?.data && data.data.length) ? (data.data.map((operator) => (
              <TableRow 
                  hover 
                  key={operator.id_user} 
                  tabIndex={-1} 
                  role="checkbox"
              >
                <TableCell align="center">
                  {operator?.id_user}
                </TableCell>
                <TableCell scope="row" >
                  <Grid container spacing={2}>
                    <Grid item>
                      <Avatar alt={operator?.name_user} src={operator?.image_user ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + operator?.image_user  :  ''} />
                    </Grid>
                    <Grid item zeroMinWidth sm={true}>
                      <Typography variant="subtitle2">
                        {operator?.name_user}
                      </Typography>
                      <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        {operator?.email_user}
                      </Typography>
                    </Grid>
                  </Grid> 
                </TableCell>
                <TableCell align="center">
                  <Chip label={operator?.status_user == '1'? 'Ativo' : 'Bloqueado'} color={operator?.status_user == '1' ? 'success' : 'error'} />
                </TableCell>
                <TableCell align="center">
                    {operator?.name_user_group}
                </TableCell>
                <TableCell align="center">
                  <EASButtonList 
                      title="Editar Pessoa"
                      onClick={() => navigate(`/operadores/detalhes/${operator.id_user}`)} 
                      typeIcon="edit"
                  />
                  <EASButtonList 
                      title="Redefinir Senha"
                      onClick={() => handleClickResetOperator(operator.id_user)} 
                      typeIcon="reset"
                  />
                  <EASButtonList 
                    title="Remover Operador"
                    onClick={() => handleClickRemoveOperator(operator.id_user)} 
                    typeIcon="remove"
                  />
                </TableCell>
              </TableRow>
             ))) : (
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  Nenhum item encontrado 
                </TableCell>
              </TableRow>
             ))}
              </TableBody>
            </Table>
            </TableContainer>
            {!isLoading  && (
              <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
            )}
      </EASCard>
      <OperatorsNewOperator open={open} setOpen={(e) => setOpen(e)} setUpdateList={() => setUpdateList(!updateList)} />
      <EASDialogAlert 
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert 
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
  </>
  );
});

