
import React, { useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Avatar, ListItem, ListItemAvatar, ListItemText, Link } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { Environment } from '../../shared/environment';

import { IOperator } from '../../shared/contexts/OperatorsContext/types';
import { OperatorsService } from '../../shared/services/api/operators/OperatorsService';

import { EASSelect, EASCheck } from '../../shared/forms';

import { UtilService } from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
import { PeopleNewPerson } from '../people/PeopleNewPerson';



type IOperatorsNewOperator = {
    open: boolean;
    setOpen: (open:boolean) => void;
    setUpdateList:() => void;
};

export interface IOperatorError {
	id_user?: string;
	id_user_group?: string;
}

export const OperatorsNewOperator:React.FC<IOperatorsNewOperator> =  (({open, setOpen, setUpdateList}) => {

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const [openNewPerson, setOpenNewPerson] = useState(false);

    const [saveLoading, setSaveLoading] = useState(false);
    const [operator, setOperator] = useState<IOperator>({});
    const [operatorError, setOperatorError] = useState<IOperatorError | null>(null);


    const handleClose = () => {
        queryClient.invalidateQueries('operator-operator-list');
        setOperator({});
        setOpen(false);
    };
    
    const { data:userGroups, isLoading:loadingGroups  } = useQuery(['operator-groups-list'],() => UtilService.getUserGroups(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de tipos de operadores.', { variant:'error'});
        },
    });
    
    const { data:operators, isLoading:loadingOperators  } = useQuery(['operator-operators-list'],() => UtilService.getOperators(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de tipos de operadores.', { variant:'error'});
        },
    });
  
    const { mutateAsync:storeCreate } = useMutation((newOperator:IOperator) => OperatorsService.createOperator(newOperator), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-operator-edit');
            queryClient.invalidateQueries('operator-operator-list');
            queryClient.invalidateQueries('operator-operators-list');
            enqueueSnackbar('Operador cadastrado com sucesso!', { variant:'success'});
            setOpen(false);
            setOperator({});
            setSaveLoading(false);
        },
        onError: () => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao cadastrar novo operador.', { variant:'error'});
        },
    });

    const isValidOperator = yup.object({
        id_user: yup.string().required('Este campo é obrigatório'),
        id_user_group: yup.array().min(1,'Este campo é obrigatório').required('Este campo é obrigatório'),
    });
    

    const saveOperator = () => {
        isValidOperator
        .validate( operator , { abortEarly: false })
        .then(validateOperator => { 
            setSaveLoading(true);
            const newOperator: IOperator = {
                id_user: validateOperator.id_user,
                id_user_group: validateOperator.id_user_group,
            };            
            storeCreate(newOperator);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setOperatorError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
                <DialogTitle>Cadastrar Novo Operador</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', minWidth:'480px', minHeight:'280px'}}>
                    {saveLoading && (                   
                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                            <CircularProgress variant='indeterminate' />
                        </Box>
                    )}
                    {!saveLoading && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <EASSelect 
                                    isLoading={(loadingGroups && loadingOperators)}
                                    options={operators ? operators : []}
                                    onFocus={() => 
                                        setOperatorError(existingValues => ({
                                        ...existingValues,
                                        id_user: '',
                                        }))
                                    }
                                    label="Escolha uma Pessoa"
                                    getOptionLabel={(option) => option.text}
                                    renderOption={(props, option) => (
                                            <ListItem {...props}>
                                                <ListItemAvatar>
                                                    <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                                </ListItemAvatar>
                                                <ListItemText primary={option.text} />
                                            </ListItem>
                                    )}
                                    value={operators && operators.find(item => item.value == operator?.id_user)}                                  
                                    onChangeSelect={(event, value:IList) => {
                                        if (value && value.value) {
                                            setOperator(existingValues => ({
                                                ...existingValues,
                                                id_user: value.value,
                                            }));
                                        }
                                    }
                                    }
                                    error={!!operatorError?.id_user}
                                    helperText={operatorError?.id_user}
                                />
                                <Box sx={{pt:1}} >
                                    <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewPerson(true)} underline="none">
                                        Cadastrar nova pessoa
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <EASCheck
                                    label="Nível de Acesso"
                                    isLoading={(loadingGroups && loadingOperators)}
                                    onFocus={() => 
                                        setOperatorError(existingValues => ({
                                        ...existingValues,
                                        id_user_group: '',
                                        }))
                                    }
                                    options={userGroups}
                                    value={operator?.id_user_group}
                                    onChange={(e) =>{
                                        setOperatorError(existingValues => ({
                                        ...existingValues,
                                        id_user_group: '',
                                        }));
                                        const newUserGroup:Array<string> = operator?.id_user_group?.length ? 
                                            (e.target.checked === false ? 
                                                operator.id_user_group.filter(item => item !== e.target.name)
                                                : operator.id_user_group
                                            ) : [];

                                        if(e.target.checked === true)
                                            newUserGroup.push(e.target.name);

                                        setOperator(existingValues => ({
                                            ...existingValues,
                                            id_user_group:newUserGroup
                                        })) ;
                                    }}
                                    error={!!operatorError?.id_user_group}
                                    helperText={operatorError?.id_user_group}
                                />
                            </Grid>
                        </Grid>
                    )}
                    </Box>            
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</Button>
                    <Button variant="contained" onClick={saveOperator} disabled={saveLoading}>Confirmar</Button>
                </DialogActions>
            </Dialog>   
            <PeopleNewPerson open={openNewPerson} setOpen={(e) => setOpenNewPerson(e)}  />
        </>
    );
});

