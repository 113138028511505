
import React, { useEffect, useRef } from 'react';
import { useNavigate} from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import { useSnackbar } from 'notistack';

import { IPerson } from '../../shared/types';
import { useAuthContext } from '../../shared/contexts';

import { PeopleService } from '../../shared/services/api/people/PeopleService';

import {  UtilService} from '../../shared/services/api/util/UtilService';

import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import { LayoutDefault } from '../../shared/layouts';
import ProfileTab, {IRefProfile} from './ProfileTab';



export const Profile = () => {
    

    const { profile, isLoadingProfile } = useAuthContext();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const refProfile = useRef<IRefProfile>(null);

    const storeDisplay = () => {
        if(refProfile.current)
            refProfile.current.savePerson();
    };

    const returnList = () => {
        navigate('/selecionar-modulo');
    };

    useEffect(() => {
        queryClient.invalidateQueries('user-profile');
    }, []);

    /*

    const { data:person, isFetching:isLoadingPerson } = useQuery(['user-profile-edit', user?.id], () => PeopleService.getPerson(user?.id), 
    {
      staleTime: Infinity,
      onError: () => { 
        enqueueSnackbar('Erro ao carregar dados do perfil.', { variant:'error'});
      },
    });
*/
    const { data:estados, isLoading:isLoadingEstados } = useQuery(['list-estados'], () =>  UtilService.getEstados(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:estadoCivil, isLoading:isLoadingEstadoCivils } = useQuery(['list-estadoCivil'], () =>  UtilService.getEstadoCivil(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:generos, isLoading:isLoadingGeneros } = useQuery(['list-generos'], () =>  UtilService.getGeneros(), {
        
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });
   
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updateOperator:IPerson) =>PeopleService.updatePerson(updateOperator), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-people-list');
            queryClient.invalidateQueries('operator-person-edit');
            queryClient.invalidateQueries('user-profile');
            //queryClient.invalidateQueries('user-profile-edit');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar file.', { variant:'error'});
        },
    });


    return (
    <LayoutDefault hideSidebar={true}>
        <EASCard 
            titleCard={ 'Editar Perfil'}
            breadcrumbsCard=""
            actionCard={<Stack direction="row" spacing={1}>
                            <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                            <EASButton variant="outlined" onClick={returnList}>Sair</EASButton>
                        </Stack>} >
            <Box sx={{mt: 2}}>
                <ProfileTab 
                    personEdit={profile} 
                    isLoading={ isLoadingProfile || isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros || isLoadingStore} 
                    estadoCivil={estadoCivil}
                    generos={generos}
                    estados={estados}
                    storeUpdate={(operator) => storeUpdate(operator)}
                    ref={refProfile} 
                />
            </Box>
        </EASCard>
    </LayoutDefault>  
    );
};
