
import React, { useImperativeHandle, useState, Ref, useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Divider, Avatar, Tabs, Tab, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { useSearchParams } from 'react-router-dom';


import { IProgram, IList } from '../../shared/types';
import { useAuthContext } from '../../shared/contexts';


import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

import * as yup from 'yup';

import { Environment } from '../../shared/environment';
import { PeopleNewPerson } from '../people/PeopleNewPerson';
import { CompaniesNewCompany } from '../companies/CompaniesNewCompany';
import { ProgramResourcesHumanMentoring } from './ProgramResourcesHumanMentoring';
import { ProgramResourcesHumanInvite } from './ProgramResourcesHumanInvite';
import { ProgramResourcesHumanLayout } from './ProgramResourcesHumanLayout';
import { ProgramResourcesHumanTeams } from './ProgramResourcesHumanTeams';
import { ProgramResourcesHumanMatch } from './ProgramResourcesHumanMatch';
import { CustomInputComponent } from '../../shared/components';


export interface IRefProfile {
  saveProgram: () => void;
}

type IProgramResourcesHumanProfileProps = {
  programEdit?: IProgram;
  programTypes?: Array<IList>;
  companies?: Array<IList>;
  tags?: Array<IList>;
  users?: Array<IList>;
  isLoading?: boolean;
  storeUpdate: (operator: IProgram) => void;
};


interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const ProgramTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};


const ProgramResourcesHumanProfile = React.forwardRef<IRefProfile, IProgramResourcesHumanProfileProps>(({ programEdit, isLoading, programTypes, companies, tags, users, storeUpdate }: IProgramResourcesHumanProfileProps, ref: Ref<IRefProfile>) => {

  const { user } = useAuthContext();

  const [openNewPerson, setOpenNewPerson] = useState(false);
  const [openNewCompany, setOpenNewCompany] = useState(false);
  const [program, setProgram] = useState<IProgram | undefined>(programEdit);
  const [programError, setProgramError] = useState<IProgram | null>(null);
  const [ImageNameProgram, setImageNameProgram] = useState<string | undefined>(undefined);
  const [ImageNameDarkProgram, setImageNameDarkProgram] = useState<string | undefined>(undefined);
  const [base64, setBase64] = useState<string | ArrayBuffer | null>();
  const [base64Dark, setBase64Dark] = useState<string | ArrayBuffer | null>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [valueTab, setValueTab] = React.useState<number>(0);

  const handleColor = (key: string, value: string) => {
    setProgram({ ...program, [key]: value });
  };

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);


  const valueTabMemo = useMemo(() => {
    return valueTab;
  }, [valueTab]);



  const handleChangeValueTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  useImperativeHandle(ref, () => ({
    saveProgram,
  }));

  const isValidProgram = yup.object({
    name_program: yup.string().required('Este campo é obrigatório'),
    id_module: yup.string().required('Este campo é obrigatório'),
    start_program: yup.string().required('Este campo é obrigatório'),
    close_program: yup.string().required('Este campo é obrigatório')
  });

  const saveProgram = () => {
    if (!isLoading) {
      isValidProgram
        .validate(program, { abortEarly: false })
        .then(validateProgram => {
          //setIsLoading(true); 
          const updateProgram: IProgram = {
            id_program: program?.id_program,
            start_program: validateProgram.start_program,
            close_program: validateProgram.close_program,
            name_program: validateProgram.name_program,
            description_program: program?.description_program,
            id_module: validateProgram.id_module,
            mentor_program: (program?.mentor_program ? program?.mentor_program : 'NULL'),
            objective_sample_program: program?.objective_sample_program,
            id_company: (program?.id_company ? program?.id_company : 'NULL'),
            min_grade_program: program?.min_grade_program,
            max_grade_program: program?.max_grade_program,
            step_grade_program: program?.step_grade_program,
            id_user_module_rh: (program?.id_user_module_rh ? program?.id_user_module_rh : 'NULL'),
            color_program: program?.color_program,
            color_dark_program: program?.color_dark_program,
            base64_image_program: base64 ? base64.toString() : undefined,
            name_temp_image_program: ImageNameProgram,

            base64_image_dark_program: base64Dark ? base64Dark.toString() : undefined,
            name_temp_image_dark_program: ImageNameDarkProgram,

            //name_temp_image_program:ImageNameProgram,
            //name_temp_image_dark_program:ImageNameDarkProgram,
            //image_program: program?.image_program,
            //image_dark_program: program?.image_dark_program,
            //id_program?: string;
            //value_program?: string;
            //discount_program?: string;
            //plots_program?: string;
            //start_enroll_program?: string;
            //close_enroll_program?: string;
            //enroll_program?: string;
            //limit_program?: string;
            //image_program?: string;
            //color_program: program?.color_program,
            tags: program?.tags,
            users: program?.users,
            user_register: user?.id,
            user_update: user?.id,
          };
          storeUpdate(updateProgram);
        })
        .catch((errors: yup.ValidationError) => {
          errors.inner.forEach(error => {
            if (error.path) {
              const keyError = error.path;
              setProgramError(existingValues => ({
                ...existingValues,
                [keyError]: error.message
              }));
            }
          });
        });
    }
  };



  return (
    <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2 }} >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ borderRadius: 2, bgcolor: 'transparent' }} variant='outlined' elevation={0}>
            <CardHeader
              sx={{ py: '2.5px' }}
              title={
                <Tabs variant="scrollable" value={valueTabMemo} onChange={handleChangeValueTab} allowScrollButtonsMobile textColor='primary' sx={{ '& .MuiTabs-indicator': { backgroundColor: 'none', height: 0 } }}>
                  <Tab label="Parâmetros" {...a11yProps(0)} sx={{ fontSize: 16, }} />
                  <Tab label="Layout" {...a11yProps(1)} sx={{ fontSize: 16 }} />
                  <Tab label="Inscrições" {...a11yProps(2)} sx={{ fontSize: 16 }} />
                  <Tab label="Times" {...a11yProps(3)} sx={{ fontSize: 16 }} />
                  <Tab label="Match" {...a11yProps(4)} sx={{ fontSize: 16 }} />
                  <Tab label="Duplas" {...a11yProps(5)} sx={{ fontSize: 16 }} />
                </Tabs>}
            />
            <Divider />
            <CardContent>
              <ProgramTabPanel value={valueTab} index={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <EASInput
                          isLoading={isLoading}
                          onFocus={() =>
                            setProgramError(existingValues => ({
                              ...existingValues,
                              name_program: '',
                            }))
                          }
                          label="Nome do Programa"
                          type="text"
                          value={program?.name_program}
                          onChange={(e) =>
                            setProgram(existingValues => ({
                              ...existingValues,
                              name_program: e.target.value,
                            }))
                          }

                          error={!!programError?.name_program}
                          helperText={programError?.name_program}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <EASPicker
                          isLoading={isLoading}
                          onFocus={() =>
                            setProgramError(existingValues => ({
                              ...existingValues,
                              start_program: '',
                            }))
                          }
                          onOpen={() =>
                            setProgramError(existingValues => ({
                              ...existingValues,
                              start_program: '',
                            }))
                          }
                          label="Data de Início"
                          value={program?.start_program}
                          error={!!programError?.start_program}
                          helperText={programError?.start_program}
                          onChangePiker={(e) => {
                            if (e instanceof Date) {
                              setProgram(existingValues => ({
                                ...existingValues,
                                start_program: e.toISOString().split('T')[0],
                              }));
                            }
                          }
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <EASPicker
                          isLoading={isLoading}
                          onFocus={() =>
                            setProgramError(existingValues => ({
                              ...existingValues,
                              close_program: '',
                            }))
                          }
                          onOpen={() =>
                            setProgramError(existingValues => ({
                              ...existingValues,
                              close_program: '',
                            }))
                          }
                          label="Data de Término"
                          value={program?.close_program}
                          error={!!programError?.close_program}
                          helperText={programError?.close_program}
                          onChangePiker={(e) => {
                            if (e instanceof Date) {
                              setProgram(existingValues => ({
                                ...existingValues,
                                close_program: e.toISOString().split('T')[0],
                              }));
                            }
                          }
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <EASInput
                          isLoading={isLoading}
                          type="number"
                          min="-10"
                          max="100"
                          defaultValue="0"
                          label="Nota Mínima"
                          value={program?.min_grade_program ? program.min_grade_program : '0'}
                          onChange={(e) =>
                            setProgram(existingValues => ({
                              ...existingValues,
                              min_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                            }))
                          }
                          error={!!programError?.min_grade_program}
                          helperText={programError?.min_grade_program}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <EASInput
                          isLoading={isLoading}
                          type="number"
                          min="-10"
                          max="100"
                          defaultValue="10"
                          label="Nota Máxima"
                          value={program?.max_grade_program ? program?.max_grade_program : '10'}
                          onChange={(e) =>
                            setProgram(existingValues => ({
                              ...existingValues,
                              max_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                            }))
                          }
                          error={!!programError?.max_grade_program}
                          helperText={programError?.max_grade_program}

                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={2}>
                        <EASInput
                          isLoading={isLoading}
                          type="number"
                          min="0"
                          max="10"
                          defaultValue="1"
                          label="Incremento"
                          value={program?.step_grade_program ? program?.step_grade_program : '1'}
                          onChange={(e) =>
                            setProgram(existingValues => ({
                              ...existingValues,
                              step_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                            }))
                          }
                          error={!!programError?.step_grade_program}
                          helperText={programError?.step_grade_program}

                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <EASRadio
                          label="Sugerir Comportamentos"
                          isLoading={isLoading}
                          options={[{ text: 'Não', value: '0' }, { text: 'Sim', value: '1' }]}
                          value={program?.objective_sample_program}
                          onChange={(e) =>
                            setProgram(existingValues => ({
                              ...existingValues,
                              objective_sample_program: e.target.value,
                            }))
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <EASSelect
                          isLoading={isLoading}
                          defaultValue={null}
                          options={programTypes ? programTypes : []}
                          label="Tipo"
                          getOptionLabel={(option) => option.text}
                          value={programTypes && programTypes.find(item => item.value == program?.id_module)}
                          onChangeSelect={(event, value: IList) => {
                            if (value && value.value) {
                              setProgram(existingValues => ({
                                ...existingValues,
                                id_module: value.value,
                              }));
                            }

                          }
                          }
                          error={!!programError?.id_module}
                          helperText={programError?.id_module}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <EASSelect
                          isLoading={isLoading}
                          options={users ? users : []}
                          label="Pré definir Mentor"
                          getOptionLabel={(option) => option.text}
                          renderOption={(props, option) => (
                            <ListItem {...props}>
                              <ListItemAvatar>
                                <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image : ''} />
                              </ListItemAvatar>
                              <ListItemText primary={option.text} />
                            </ListItem>
                          )}
                          value={users && users.find(item => item.value == program?.mentor_program)}
                          onChangeSelect={(event, value: IList) => {
                            if (value && value.value) {
                              setProgram(existingValues => ({
                                ...existingValues,
                                mentor_program: value.value,
                              }));
                            } else {
                              if (event.target.nodeName == 'svg') {
                                setProgram(existingValues => ({
                                  ...existingValues,
                                  mentor_program: '',
                                }));
                              }

                            }
                          }
                          }
                          error={!!programError?.mentor_program}
                          helperText={programError?.mentor_program}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <EASSelect
                          isLoading={isLoading}
                          options={tags ? tags : []}
                          label="Tags"
                          getOptionLabel={(option) => option.text}
                          value={program?.tags && tags && program.tags.map((tag) => tags.find(item => item.value == tag))}
                          onChangeSelect={(event, values: Array<IList>) => {
                            const NewValue: Array<string> = [];
                            values.map((value) => NewValue.push(value.value));
                            setProgram(existingValues => ({
                              ...existingValues,
                              tags: NewValue,
                            }));
                          }
                          }
                          fullWidth
                          multipleSelect
                          filterSelectedOptions
                          disableClearable
                        />
                      </Grid>
                      {/*
                                            <Grid item xs={12} sm={4} md={3}>
                                                <EASRadio
                                                    label="Ativar Inscrições"
                                                    isLoading={isLoading}
                                                    options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
                                                    value={program?.enroll_program}
                                                    onChange={(e) => 
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            enroll_program: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Grid> 
                                            */}
                      <Grid item xs={12}>
                        <EASInput
                          isLoading={isLoading}
                          multiline={true}
                          rows={6}
                          onFocus={() =>
                            setProgramError(existingValues => ({
                              ...existingValues,
                              description_program: '',
                            }))
                          }
                          label="Descrição"
                          type='text'
                          value={program?.description_program}
                          onChange={(e) =>
                            setProgram(existingValues => ({
                              ...existingValues,
                              description_program: e.target.value,
                            }))
                          }
                          error={!!programError?.description_program}
                          helperText={programError?.description_program}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ProgramTabPanel>
              <ProgramTabPanel value={valueTab} index={1}>
                <ProgramResourcesHumanLayout program={program} setProgram={(e) => setProgram(e)}
                  setImageNameProgram={(e) => setImageNameProgram(e)}
                  setImageNameDarkProgram={(e) => setImageNameDarkProgram(e)}
                  setBase64={(e) => setBase64(e)}
                  setBase64Dark={(e) => setBase64Dark(e)}
                />
              </ProgramTabPanel>
              <ProgramTabPanel value={valueTab} index={2}>
                <ProgramResourcesHumanInvite program={program} />
              </ProgramTabPanel>
              <ProgramTabPanel value={valueTab} index={3}>
                <ProgramResourcesHumanTeams />
              </ProgramTabPanel>
              <ProgramTabPanel value={valueTab} index={4}>
                <ProgramResourcesHumanMatch mentorings={
                  [
                    {
                      id_mentoring: 1,
                      name: 'Juan Gerling',
                      image: 'https://static1.squarespace.com/static/656f4e4dababbd7c042c4946/657236350931ee4538eea52c/65baf15103d8ad2826032a8a/1727029299965/how-to-stop-being-a-people-pleaser-1_1.jpg?format=1500w',
                      mentors: [
                        {
                          id_mentor: 1,
                          name: 'Everton Schuh',
                          image: 'https://t4.ftcdn.net/jpg/03/96/16/79/360_F_396167959_aAhZiGlJoeXOBHivMvaO0Aloxvhg3eVT.jpg',
                          matchPercentage: 90,
                        },
                        {
                          id_mentor: 2,
                          name: 'Jessica Crixel',
                          image: 'https://img.freepik.com/free-photo/headshot-beautiful-dark-skinned-curly-has-pleased-expression-rejoices-success-enjoys-spare-time-wears-casual-t-shirt-isolated-yellow-wall-people-positive-emotions-feelings-concept_273609-27729.jpg',
                          matchPercentage: 60,
                        },
                        {
                          id_mentor: 3,
                          name: 'Miriam Gerling',
                          image: 'https://static.vecteezy.com/system/resources/thumbnails/011/100/782/small/close-up-shot-of-attractive-young-woman-with-light-crisp-hair-blue-eyes-and-shining-smile-looks-positively-at-camera-being-in-good-mood-after-walk-outdoor-isolated-over-light-blue-background-photo.JPG',
                          matchPercentage: 25,
                        },
                      ],
                    },
                    {
                      id_mentoring: 2,
                      name: 'Phillip Gorgen',
                      image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK-g7z7zo9SJJBcOurvT-eVatPe8vZURYc-_zz1Jbh5I8zD45EjZ6edhm-ivyJzGeQdOY&usqp=CAU',
                      mentors: [
                        {
                          id_mentor: 4,
                          name: 'Johann Gorgen',
                          image: 'https://images.pexels.com/photos/5506141/pexels-photo-5506141.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                          matchPercentage: 30,
                        },
                        {
                          id_mentor: 5,
                          name: 'Cristhine Gorgen',
                          image: 'https://images.pexels.com/photos/1642161/pexels-photo-1642161.jpeg',
                          matchPercentage: 55,
                        },
                        {
                          id_mentor: 6,
                          name: 'Viviane Schuh',
                          image: 'https://media.istockphoto.com/id/1180994600/es/foto/chica-linda-en-un-parque-de-oto%C3%B1o.jpg?s=612x612&w=0&k=20&c=EIJWDerv681r5Dq4gEql7s1A4qfsv79t4z7Ap37lHgE=',
                          matchPercentage: 80,
                        },
                      ],
                    },
                  ]
                } />
              </ProgramTabPanel>
              <ProgramTabPanel value={valueTab} index={5}>
                <ProgramResourcesHumanMentoring program={program} />
              </ProgramTabPanel>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <PeopleNewPerson open={openNewPerson} setOpen={(e) => setOpenNewPerson(e)} />
      <CompaniesNewCompany open={openNewCompany} setOpen={(e) => setOpenNewCompany(e)} />
    </Box>
  );
});

ProgramResourcesHumanProfile.displayName = 'ProgramResourcesHumanProfile';

export default ProgramResourcesHumanProfile;
