import { Alert, Autocomplete, Avatar, Box, Button, Card, CardContent, CardHeader, Chip, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, styled, Switch, TextField, Tooltip, tooltipClasses, TooltipProps, Typography, useTheme } from '@mui/material';
import { ICustomInput, IProgram } from '../../shared/types';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from 'react';
import { EASButton, EASInput, EASSelect } from '../../shared/forms';
import { CustomInputComponent } from '../../shared/components';

interface IProgramResourcesHumanMatchProps {
  programEdit?: IProgram;
  mentorings?: Mentoring[];
}

interface GenderOption {
  title: string;
  value: string;
}

interface hierarchicalOption {
  title: string;
  value: string;
}

interface ValueOption {
  title: string;
  value: string;
}

interface Mentor {
  id_mentor: number;
  name: string;
  image: string;
  matchPercentage: number;
}

interface Mentoring {
  id_mentoring: number;
  name: string;
  image: string;
  mentors: Mentor[];
}

interface MentorshipCardProps {
  mentorings: Mentoring[];
}

export const ProgramResourcesHumanMatch: React.FC<IProgramResourcesHumanMatchProps> = ({ programEdit, mentorings }) => {

  const theme = useTheme();

  const titleMentoring = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: 12 }}>
          Motivos da porcentagem:
        </Typography>
        <List sx={{ maxHeight: 150, overflow: 'auto' }}>
          <ListItem>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
              <InfoIcon color="error" sx={{ fontSize: 20 }} />
              <ListItemText primary="Qual é a sua idade?" />
            </Box>
          </ListItem>
          <ListItem>
            <InfoIcon color="primary" />
            <ListItemText primary="Qual é o seu gênero?" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InfoIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Idade:" />
          </ListItem>
        </List>
      </Box>
    );
  };



  const [customInputs, setCustomInputs] = useState<Array<ICustomInput>>([]);



  const [genderEquity, setGenderEquity] = useState(false);
  const [rhIndication, setRhIndication] = useState(false);
  const [selectedValue, setSelectedValue] = useState<ValueOption | null>(null);
  const [hierarchicalNivel, setHierarchicalNivel] = useState(false);
  const [similarityDisk, setSimilarityDisk] = useState(false);
  const [differentAreas, setDifferentAreas] = useState(false);
  const [valueList, setValueList] = useState<ValueOption[]>([
    { title: '1', value: '1' },
    { title: '2', value: '2' },
    { title: '3', value: '3' },
    { title: '4', value: '4' },
    { title: '5', value: '5' },
  ]);
  const [genderList, setGenderList] = useState<GenderOption[]>([
    { title: 'Masculino', value: 'masculino' },
    { title: 'Feminino', value: 'feminino' },
  ]);
  const [hierarchicalList, setHierarchicalList] = useState<hierarchicalOption[]>([
    { title: 'Atendente', value: 'atendente' },
    { title: 'Gestor', value: 'gestor' },
    { title: 'Supervisor', value: 'supervisor' },
    { title: 'Diretor', value: 'diretor' },
  ]);

  // Função para capturar a opção selecionada
  const handleValueChange = (event: any, newValue: ValueOption | null) => {
    setSelectedValue(newValue); // Atualiza o valor selecionado
  };


  const handleGenderEquityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGenderEquity(event.target.checked);
  };

  const handleHierarchicalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHierarchicalNivel(event.target.checked);
  };

  const handleRhIndicationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRhIndication(event.target.checked); // Atualiza o estado com o valor do switch
  };

  const handleSimilarityDiskChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSimilarityDisk(event.target.checked); // Atualiza o estado com o valor do switch
  };

  const handleDifferentAreasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDifferentAreas(event.target.checked); // Atualiza o estado com o valor do switch
  };

  const handleAddGender = (newGender: string) => {
    const trimmedGender = newGender.trim().toLowerCase();
    if (trimmedGender && !genderList.some(g => g.value === trimmedGender)) {
      const updatedList = [
        ...genderList,
        { title: newGender, value: trimmedGender },
      ];
      setGenderList(updatedList);
    }
  };

  const handleAddHierarchical = (newHierarchical: string) => {
    const trimmedHierarchical = newHierarchical.trim().toLowerCase();
    if (trimmedHierarchical && !hierarchicalList.some(h => h.value === trimmedHierarchical)) {
      const updatedList = [
        ...hierarchicalList,
        { title: newHierarchical, value: trimmedHierarchical },
      ];
      setHierarchicalList(updatedList);
    }
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 400,
    },
  });


  const setCustonComponetgAdd = () => {

    const newCustomInput: ICustomInput = {};
    setCustomInputs(existingValues => [...existingValues, newCustomInput]);


  };

  return (
    <Card elevation={0} >
      <CardHeader
        title={<Typography variant="h6">Match do Programa</Typography>}
        subheader="Configure as opções de match para formação das duplas do programa."
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Ativa Mach
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <Card sx={{ borderRadius: 2, }} variant='outlined' elevation={0}>
                      <CardHeader
                        title='Parâmetros do Match'
                        titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', }}
                      />
                      <Divider />
                      <CardContent>
                        <Grid container spacing={2}>

                          <Grid item xs={12}>
                            A escolha das duplas será realizada pelos mentores e mentorados ou pelo RH
                            Se vai ser pelo RH, terá q ter um periodo de inscrição definido
                            Se o RH quer que o mentorado escolha alguns mentores de sua preferencia, terá q ter um periodo de inscrição diferente para mentor e mentorado
                            Se vai ser pelos proprios, tera q ter um periodo de inscrição e depois um periodo de escolha de dupla conforme resulados do match
                          </Grid>
                          <Grid item xs={12}>
                            A quantidade de mentorados de cada mentor será escolhida por ele mesmo limitando X
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <Card sx={{ borderRadius: 2, }} variant='outlined' elevation={0}>
                      <CardHeader
                        action={<EASButton variant="contained" size="small" onClick={() => setCustonComponetgAdd()}>Criar Nova Pergunta</EASButton>}
                        title='Perguntas do Match'
                        titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold' }}
                      />
                      <Divider />

                      <CardContent>
                        <Grid container spacing={2}>
                          {customInputs.length > 0 ? customInputs.map((customInput, index) => (
                            <Grid item key={index} xs={12}>
                              <CustomInputComponent customInputEdit={customInput} />
                            </Grid>
                          )) : (
                            <Grid item xs={12}>
                              <Typography variant="overline">Nenhuma Pergunta cadastrada</Typography>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xs={12}>
                <Card sx={{ borderRadius: 2, }} variant='outlined' elevation={0}>
                  <CardHeader
                    title='Resultados do Match'
                    titleTypographyProps={{ fontSize: 14, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', }}
                  />
                  <Divider />
                  <CardContent>

                    <Grid container spacing={2}>
                      {mentorings && mentorings.map((mentoring) => (
                        <Grid item xs={12} md={6} key={mentoring.id_mentoring}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Card variant='outlined' elevation={0}>
                                <CardHeader
                                  title={`Dupla ${mentoring.id_mentoring}`}
                                  titleTypographyProps={{ fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center', }}
                                  subheader='Para selecionar o mentor da dupla, clique no nome do mentor.'
                                  subheaderTypographyProps={{ fontSize: 14, textAlign: 'center' }}
                                />
                                <Divider sx={{ width: '100%' }} />
                                <CardContent>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                      <Avatar src={mentoring.image} sx={{ width: 100, height: 100, border: '4px solid #673ab7' }} />
                                      <Typography variant="h6" fontSize={14} sx={{ color: 'text.secondary', textAlign: 'center' }} >
                                        {mentoring.name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                      {mentoring.mentors.map((mentor) => (
                                        <Box key={mentor.id_mentor} gap={1} sx={{
                                          display: 'flex', justifyContent: 'left', alignItems: 'center', m: 2, '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                            borderRadius: 2,
                                            cursor: 'pointer',
                                          }
                                        }}>
                                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} position='relative'>
                                            <CircularProgress
                                              variant='determinate'
                                              value={mentor.matchPercentage}
                                              size={65}
                                              thickness={4}
                                              color={mentor.matchPercentage <= 30 ? 'error' : (mentor.matchPercentage > 30 && mentor.matchPercentage <= 70) ? 'primary' : 'success'}
                                              sx={{ position: 'absolute', top: 3.2, left: 10.6 }}
                                            />
                                            <Tooltip title={`${mentor.name} - ${mentor.matchPercentage}% match`} >
                                              <Box gap={4} px={2} py={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'right' }}>
                                                <Avatar
                                                  src={mentor.image}
                                                  sx={{ width: 55, height: 55, border: 'none', top: 0, left: 0 }}
                                                />
                                              </Box>
                                            </Tooltip>
                                          </Box>
                                          <Box>
                                            <Typography variant="h6" fontSize={14} sx={{ color: 'text.secondary' }} >
                                              Mentor: {mentor.name}
                                            </Typography>
                                            <Tooltip title={titleMentoring()} arrow>
                                              <Chip icon={<InfoIcon />} label={`${mentor.matchPercentage}% de match`} variant='outlined' size='small' color={mentor.matchPercentage <= 30 ? 'error' : (mentor.matchPercentage > 30 && mentor.matchPercentage <= 70) ? 'primary' : 'success'} />
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                      ))}

                                    </Grid>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
