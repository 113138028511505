import React, { ReactEventHandler, useState } from 'react';
import { Dialog,  DialogContent, DialogActions, Typography, Button, IconButton, Grid, DialogTitle, Card, CardHeader, CardContent, Divider } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { EASButton, EASCustom, EASInput, EASSelect } from '../../forms';
import { ICustomInput, ICustomInputRules, IList } from '../../types';

interface ICustomInputComponentProps {
    title?: string | null;
    loading?:boolean;
    customInputEdit?:ICustomInput;
    actionCancel?:ReactEventHandler;
    titleCancel?:string;
    actionConfirme?:ReactEventHandler;
}

export const CustomInputComponent:React.FC<ICustomInputComponentProps> = ({...props}) => {

  const [statusOpen, setStatusOpen] = useState<boolean>(false);

  const [customInput, setCustomInput] = useState<ICustomInput | undefined>(props.customInputEdit);
  const [customInputError, setCustomInputError] = useState<ICustomInput | null>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const typeCustomInput:Array<IList> = [
    { text: 'Caixa de Seleção',   value: '1'  },
    { text: 'Escolha Única',   value: '2'  },
    { text: 'Escolha Multipla',   value: '3'  },
  ];

   const addOptions = () => {

        const newList:Array<IList> = customInput?.options_custom_input?.length ? customInput.options_custom_input : [];
        const newItem:IList = {
          value: customInput?.options_custom_input?.length ? (customInput.options_custom_input?.length + 1 ).toString() : '1',
          text: '',
        };
        newList.push(newItem);
        setCustomInput(existingValues => ({
          ...existingValues,
          options_custom_input: newList,
        }));
      
    };

    const deleteOptions = (value_option:string) => {
      const newList:Array<IList> =  customInput?.options_custom_input?.length ? customInput?.options_custom_input?.filter(
        (_: any) => _.value !== value_option
      ) : [];
      setCustomInput(existingValues => ({
        ...existingValues,
        options_custom_input: newList,
      }));

    };
  
    const addRules = () => {

      const newList:Array<ICustomInputRules> = customInput?.rules_custom_input?.length ? customInput.rules_custom_input : [];
      const newItem:ICustomInputRules = {
        id: customInput?.rules_custom_input?.length ? (customInput.rules_custom_input?.length + 1 ).toString() : '1',
        mentor: '',        
        mentorado: '',
        weight: '',

      };
      newList.push(newItem);
      setCustomInput(existingValues => ({
        ...existingValues,
        rules_custom_input: newList,
      }));
    
  };

  const deleteRules = (id_rules:string) => {
    const newList:Array<ICustomInputRules> =  customInput?.rules_custom_input?.length ? customInput?.rules_custom_input?.filter(
      (_: any) => _.id !== id_rules
    ) : [];
    setCustomInput(existingValues => ({
      ...existingValues,
      rules_custom_input: newList,
    }));
  };

  return (
        <>
        <EASInput
            nameIconRight={'edit'}
            label='Campo Personalizado'
            type={'text'}
            value={customInput?.name_custom_input}
            handleIconRight={() => setStatusOpen(true)}
          />
        <Dialog
          open={statusOpen}
          onClose={() =>setStatusOpen(false)}
          PaperProps={{ sx: { borderRadius: '12px' } }}
          fullWidth
          maxWidth={'md'}
        >
        <DialogTitle>
          {'Parâmetros do Campo Personalizado'}
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={() => setStatusOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={props.loading}
          >
            <CloseIcon />
          </IconButton>
        <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <EASInput
                    isLoading={isLoading}
                    onFocus={() =>
                      setCustomInputError(existingValues => ({
                            ...existingValues,
                            name_custom_input: '',
                        }))
                    }
                    label="Nome do Campo"
                    type="text"
                    value={customInput?.name_custom_input}
                    onChange={(e) =>
                        setCustomInput(existingValues => ({
                            ...existingValues,
                            name_custom_input: e.target.value,
                        }))
                    }
                    error={!!customInputError?.name_custom_input}
                    helperText={customInputError?.name_custom_input}
                />
              </Grid>
              <Grid item md={6}>
                <EASInput
                    isLoading={isLoading}
                    onFocus={() =>
                      setCustomInputError(existingValues => ({
                            ...existingValues,
                            label_custom_input: '',
                        }))
                    }
                    label="Enunciado do Campo"
                    type="text"
                    value={customInput?.label_custom_input}
                    onChange={(e) =>
                        setCustomInput(existingValues => ({
                            ...existingValues,
                            label_custom_input: e.target.value,
                        }))
                    }
                    error={!!customInputError?.label_custom_input}
                    helperText={customInputError?.label_custom_input}
                />
              </Grid>
              <Grid item xs={6}> 
                <EASSelect
                    isLoading={isLoading}
                    defaultValue={null}
                    options={typeCustomInput ? typeCustomInput : []}
                    label="Tipo"
                    getOptionLabel={(option) => option.text}
                    value={typeCustomInput && typeCustomInput.find(item => item.value == customInput?.type_custom_input)}
                    onChangeSelect={(event, value: IList) => {
                        if (value && value.value) {
                            setCustomInput(existingValues => ({
                                ...existingValues,
                                type_custom_input: value.value,
                            }));
                        }

                    }
                    }
                    error={!!customInputError?.type_custom_input}
                    helperText={customInputError?.type_custom_input}
                />
              </Grid>
              <Grid item xs={6}> 
                <EASInput
                    isLoading={isLoading}
                    onFocus={() =>
                      setCustomInputError(existingValues => ({
                            ...existingValues,
                            weight_custom_input: '',
                        }))
                    }
                    label="Peso"
                    type="number"
                    value={customInput?.weight_custom_input}
                    onChange={(e) =>
                        setCustomInput(existingValues => ({
                            ...existingValues,
                            weight_custom_input: e.target.value,
                        }))
                    }
                    error={!!customInputError?.weight_custom_input}
                    helperText={customInputError?.weight_custom_input}
                />
              </Grid>
            </Grid>
          </Grid> 
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Grid container spacing={2}>

                  <Grid item xs={12}> 
                    <Card elevation={0}                     
                          sx={{
                            border:'solid',
                            borderColor: 'grey.300',
                            borderWidth: 1,
                            borderRadius: 2,
                          }}
                    >
                      <CardHeader                 
                        title={<Typography sx={{color:'grey.600'}} >Opções do Campo</Typography>}
                        sx={{
                          backgroundColor:'grey.200'
                        }}
                        action={customInput?.type_custom_input && customInput.type_custom_input != '' && (
                          <EASButton variant="contained" size="small" onClick={addOptions}>Adicionar</EASButton>
                        )}
                      />
                      <CardContent>
                        {customInput?.type_custom_input && customInput.type_custom_input != ''  ? (
                          <Grid container spacing={2}>
                            {customInput?.options_custom_input && customInput.options_custom_input.length > 0 ? customInput.options_custom_input.map((options, index) => (
                              <Grid key={index} item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={true}>
                                    <EASInput
                                        isLoading={isLoading}
                                        onFocus={() =>
                                          setCustomInputError(existingValues => ({
                                                ...existingValues,
                                                name_custom_input: '',
                                            }))
                                        }
                                        size="small"
                                        label={'Opção ' + (index + 1)} 
                                        type="text"
                                        value={options?.text}
                                        onChange={(e) =>
                                          setCustomInput(existingValues => ({
                                            ...existingValues,
                                            options_custom_input: customInput?.options_custom_input?.length ? 
                                                                    customInput?.options_custom_input.map(item =>
                                                                      item.value === options?.value ? { ...item, text: e.target.value } : item
                                                                    )
                                                                  : 
                                                                  undefined
                                            }))
                                        }
                                        error={!!customInputError?.name_custom_input}
                                        helperText={customInputError?.name_custom_input}
                                    />
                                  </Grid>
                                  <Grid item zeroMinWidth>
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => deleteOptions(options?.value)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )):(
                              <Grid item>
                                <Typography>Nenhuma Opção Cadastrada</Typography>
                              </Grid>
                            )} 
                          </Grid>
                        ):(
                          <Grid item>
                            <Typography>Escolha o Tipo de Campo</Typography>
                          </Grid>
                        )} 
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6}>
                <Grid container spacing={2}>  
                  <Grid item xs={12}> 
                    <Card elevation={0}                     
                          sx={{
                            border:'solid',
                            borderColor: '#367cf7',
                            borderWidth: 1,
                            borderRadius: 2,
                          }}
                        >
                      <CardHeader                 
                        title={<Typography sx={{color:'#367cf7'}} >Pré-Visualização</Typography>}
                      />
                      <Divider sx={{borderColor:'#367cf7'}} />
                      <CardContent sx={{pb:0}}>
                        <EASCustom customInput={customInput} />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12}> 
            <Card elevation={0}                     
                  sx={{
                    border:'solid',
                    borderColor: 'grey.300',
                    borderWidth: 1,
                    borderRadius: 2,
                  }}
            >
              <CardHeader                 
                title={<Typography sx={{color:'grey.600'}} >Regras e Pesos</Typography>}
                sx={{
                  backgroundColor:'grey.200'
                }}
                action={customInput?.type_custom_input && customInput.type_custom_input != '' && (
                  <EASButton variant="contained" size="small" onClick={addRules}>Adicionar</EASButton>
                )}
              />
              <CardContent>
                {customInput?.type_custom_input && customInput.type_custom_input != '' && (
                  <Grid container spacing={2}>
                    {customInput?.rules_custom_input && customInput.rules_custom_input.length > 0 ? customInput.rules_custom_input.map((rules, index) => (
                      <Grid key={index} item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={5}>
                            <EASSelect
                              isLoading={isLoading}
                              defaultValue={null}
                              multipleSelect={true}
                              size="small"
                              options={customInput?.options_custom_input ? customInput?.options_custom_input : []}
                              label="Se a escolha do mentorado conter algum"
                              getOptionLabel={(options) => options.text}
                              value={customInput?.options_custom_input && customInput?.options_custom_input.find(item => item.value == rules?.mentor)}
                              onChangeSelect={(event, value: IList) => {
                                  if (value && value.value) {
                                    setCustomInput(existingValues => ({
                                      ...existingValues,
                                      rules_custom_input: customInput?.rules_custom_input?.length ? 
                                                              customInput?.rules_custom_input.map(item =>
                                                                item.id === rules?.id ? { ...item, mentor: value.value } : item
                                                              )
                                                            : 
                                                            undefined
                                      }));
                                  }
                              }}
                              error={!!customInputError?.type_custom_input}
                              helperText={customInputError?.type_custom_input}
                          />
                          </Grid>
                          <Grid item xs={5}>
                            <EASSelect
                                isLoading={isLoading}
                                defaultValue={null}
                                options={customInput?.options_custom_input ? customInput?.options_custom_input : []}
                                label="E a escolha do mentor conter algum"
                                multipleSelect={true}
                                size="small"
                                getOptionLabel={(options) => options.text}
                                value={customInput?.options_custom_input && customInput?.options_custom_input.find(item => item.value == rules?.mentorado)}
                                onChangeSelect={(event, value: IList) => {
                                    if (value && value.value) {
                                      setCustomInput(existingValues => ({
                                        ...existingValues,
                                        rules_custom_input: customInput?.rules_custom_input?.length ? 
                                                                customInput?.rules_custom_input.map(item =>
                                                                  item.id === rules?.id ? { ...item, mentorado: value.value } : item
                                                                )
                                                              : 
                                                              undefined
                                        }));
                                    }
                                }}
                                error={!!customInputError?.type_custom_input}
                                helperText={customInputError?.type_custom_input}
                            />

                          </Grid>
                          <Grid item xs={true} zeroMinWidth >
                            <EASInput
                                isLoading={isLoading}
                                onFocus={() =>
                                  setCustomInputError(existingValues => ({
                                        ...existingValues,
                                        name_custom_input: '',
                                    }))
                                }
                                size="small"
                                label="Peso" 
                                type="number"
                                value={rules?.weight}
                                onChange={(e) =>
                                  setCustomInput(existingValues => ({
                                    ...existingValues,
                                    rules_custom_input: customInput?.rules_custom_input?.length ? 
                                                            customInput?.rules_custom_input.map(item =>
                                                              item.id === rules?.id ? { ...item, weight: e.target.value } : item
                                                            )
                                                          : 
                                                          undefined
                                    }))
                                }
                                error={!!customInputError?.name_custom_input}
                                helperText={customInputError?.name_custom_input}
                            />
                          </Grid>
                          <Grid item zeroMinWidth>
                            <IconButton
                              aria-label="close"
                              onClick={() => deleteRules(rules.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    )):(
                      <Grid item>

                      </Grid>
                    )} 
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        </DialogContent>
        <DialogActions sx={{mr:2, mb:1}}>
          {props.actionConfirme ? (
            <>
              <Button onClick={props.actionCancel} disabled={props.loading} variant="outlined">Desistir</Button>
              <Button onClick={props.actionConfirme} disabled={props.loading} variant="contained" autoFocus>
                Sim, Confirmar
              </Button>
            </>
          ) : (
            <Button onClick={props.actionCancel} disabled={props.loading} variant="contained">{props.titleCancel ? props.titleCancel : 'Entendido'}</Button>
          )}
        </DialogActions>
      </Dialog> 
      </>
  );
};
