
import React, { useImperativeHandle, useEffect, useState, Ref, useRef } from 'react';
import { Box, Card,CardContent, CardHeader, Grid, Divider, Skeleton, Avatar, Badge, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Tabs, Tab, Hidden, Alert  } from '@mui/material';

import BackupIcon from '@mui/icons-material/Backup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { useSnackbar } from 'notistack';

import { ICompany } from '../../shared/types';
import { useAuthContext } from '../../shared/contexts';


import { EASInput, EASSelect } from '../../shared/forms';
import { EASFileUploadCrop } from '../../shared/components/eas-file-upload/EASFileUploadCrop';

import * as yup from 'yup';
import { isValidCNPJ, UtilService, uniqueCNPJ} from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
import { Environment } from '../../shared/environment';
import { useQuery } from 'react-query';

import { centerCrop, convertToPixelCrop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';

interface IAcceptFile{
    [key: string]: string[];
}

export interface IRefProfile {
    saveCompany: () => void;
}

type ICompanyProfileProps = {
    companyEdit?: ICompany;
    isLoading?:boolean;
    estados?:Array<IList>;
    users?:Array<IList>;
    storeUpdate:(operator:ICompany) => void;
};

interface TabPanelProps {
    children: React.ReactNode;
    index: number;
    value: number;
}
  
const CompanyTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
};
  
const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};

  
const ASPECT_RATIO = 1;
const MIN_DIMENSION = 200;

const CompanyTabProfile = React.forwardRef<IRefProfile, ICompanyProfileProps>(({companyEdit, isLoading, users, estados, storeUpdate }:ICompanyProfileProps, ref:Ref<IRefProfile>) => {

    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoadingUpload, setIsLoadingUpload ] = useState(false);

    const [company, setCompany] = useState<ICompany | undefined>(companyEdit);
    const [companyError, setCompanyError] = useState<ICompany | null>(null);

    const [file, setFile] = useState<File | undefined>(undefined);
    const [urlNewImage, setUrlNewImage] = useState<string | null>(null);
    const [ImageName, setImageName] = useState<string | undefined>(undefined);
    const [base64, setBase64] = useState<string | ArrayBuffer | null>();

  
    const deFaultCrop = makeAspectCrop({unit: '%', width: 25, }, 200, 200, 200 );

    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [ percentCrop, setPercentCrop] = useState<PercentCrop>(deFaultCrop);

    const [open, setOpen] = useState(false);

    const [valueTab, setValueTab] = useState<number>(0);

    const handleChangeValueTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
        setFile(undefined);
        setPercentCrop(deFaultCrop);
        setOpen(false);
    };

    const handleCancelUpdate = () => {
        setFile(undefined);
        setPercentCrop(deFaultCrop);
        setImageName(undefined);
        setBase64(null);
        setUrlNewImage(null);
    };

    const { data:cidades, isFetching:isFetchingCidades, isLoading:isLoadingCidades } = useQuery(['list-cidades-company' , [company?.id_estado]], () =>  UtilService.getCidades(company?.id_estado), {
        enabled:!isLoading && !!(company?.id_estado),
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de cidades.', { variant:'error'});
        },
    });

    useEffect(() => {
        setValueTab(0);
    }, []);

    useImperativeHandle(ref, () => ({
        saveCompany,
    }));



    const [isLoadingUpdateAvatar, setIsLoadingUpdateAvatar ] = useState(false);

    const isValidCompany = yup.object({
        razao_social_company: yup.string().required('Este campo é obrigatório'),
        email_company: yup.string().email('Informe um e-mail válido'),
        cnpj_company: yup.string().test('cnpj_company', 'CNPJ já cadastrado', (cnpj) => (cnpj ? (isValidCNPJ(cnpj) && uniqueCNPJ(cnpj, company?.id_company)) :  false)).test('cnpj_company', 'Informe um CNPJ válido', (cnpj) => (cnpj ? isValidCNPJ(cnpj) :  false)).required('Este campo é obrigatório'),
  
    });
    
    const saveCompany = () => {
        if (!isLoading) {
            isValidCompany
            .validate( company , { abortEarly: false })
            .then(validateCompany => {
                const updateCompany: ICompany = {
                    id_company: company?.id_company,
                    razao_social_company: validateCompany.razao_social_company,
                    email_company: validateCompany.email_company,
                    cnpj_company: validateCompany.cnpj_company,

                    nome_fantasia_company: company?.nome_fantasia_company,
                    pessoa_contato_company: company?.pessoa_contato_company,
                    site_company: company?.site_company,
                    telefone_company: company?.telefone_company,
                    celular_company: company?.celular_company,

                    
                    users: company?.users,

                    id_estado: company?.id_estado,
                    id_cidade: company?.id_cidade,
                    cep_company: company?.cep_company,
                    bairro_company: company?.bairro_company,
                    logradouro_company: company?.logradouro_company,
                    numero_company: company?.numero_company,
                    complemento_company: company?.complemento_company,

                    base64_logo_company: base64 ? base64.toString() : undefined,
                    name_temp_logo_company:ImageName,
                    user_update: user?.id,
                };            
                storeUpdate(updateCompany);  
            })
            .catch((errors: yup.ValidationError) => {
                errors.inner.forEach(error => {
                    if(error.path){
                        const keyError = error.path;                    
                        setCompanyError(existingValues => ({
                                            ...existingValues,
                                            [keyError]: error.message
                                        }));                        
                    }
                });
            });
        }
    };

    const convertToBase64 = (file:File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    };

    const toBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
        return new Promise((resolve) => {
          canvas.toBlob(resolve);
        });
    };

    const storeAvatar = () =>{

        setCanvasPreview(
            imgRef.current, // HTMLImageElement
            previewCanvasRef.current, // HTMLCanvasElement
            convertToPixelCrop(
              percentCrop,
              (imgRef.current == null ? 0 : imgRef.current.width),
              (imgRef.current == null ? 0 : imgRef.current.height)
            )
          );
          if(previewCanvasRef?.current && file) {

            const dataUrl = previewCanvasRef.current.toDataURL();
            setUrlNewImage(dataUrl);

            toBlob(previewCanvasRef.current).then((blob) => {
                
                if (!blob) {
                    return '';
                }
                const newFileImage = new File([blob], file.name, { type: blob.type });
                setImageName(file.name);
                                
                convertToBase64(newFileImage).then((response) => {
                    if (response instanceof Error) {
                        setUrlNewImage(null);
                        setImageName(undefined);
                        setBase64(null);
                        setIsLoadingUpload(false);
                        enqueueSnackbar('Erro ao carregar arquivo.', { variant:'error'});
                    }
                    else {
                        setBase64(response);
                        setIsLoadingUpload(false);
                    }
                });
            });
            setOpen(false);

          }
    };

    const loadFile = (selectedFile:File) => {
        
        if(selectedFile){
            setFile(selectedFile);

            const reader = new FileReader();
            reader.addEventListener('load', () => {
              const imageElement = new Image();
              const imageUrl = reader.result?.toString() || '';
              imageElement.src = imageUrl;
        
              imageElement.addEventListener('load', () => {
              
                const height = imageElement.height;
                const width = imageElement.width;
               
                const cropWidthInPercent = (MIN_DIMENSION / width) * 100;
        
                const newCrop = makeAspectCrop(
                  {
                    unit: '%',
                    width: cropWidthInPercent >=25 ? cropWidthInPercent : 25,
                  },
                  ASPECT_RATIO,
                  width,
                  height
                );
                const centeredCrop:PercentCrop = centerCrop(newCrop, width, height);
                setPercentCrop(centeredCrop);
              });
            });
            reader.readAsDataURL(selectedFile);
        }
        else{
            setPercentCrop(deFaultCrop);
            setFile(undefined);


        }

    };

      
    const setCanvasPreview = async ( image: HTMLImageElement | null, canvas: HTMLCanvasElement | null, crop: PixelCrop, scale = 1, rotate = 0 ) => {
  
        if(image === null || canvas === null)
          return;
    
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          throw new Error('No 2d context');
        }
    
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
    
        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
    
        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = 'high';
        ctx.save();
    
        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;
    
        // Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );
    
        ctx.restore();
      };
  
      const NewacceptFile : IAcceptFile = {'image/png':['.png'],
                                         'image/jpg': ['.jpg'], 
                                         'image/jpeg': ['.jpeg']};


    return (
        <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>  
                            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                                <CardHeader
                                    title='Logo'
                                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                                />
                                <Divider/>
                                <CardContent>
                                    {(isLoading )  && (
                                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 1}}>
                                            <Skeleton width={120} height={200} />
                                        </Box>
                                    )}
                                    {(!isLoading) && (
                                    <Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 5}}>
                                            {urlNewImage ? (
                                            <>
                                                <Box sx={{display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
                                                    <Badge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        badgeContent={
                                                            <Fab size="small" color="error" aria-label="add" onClick={handleCancelUpdate}>
                                                                <DeleteForeverIcon />
                                                            </Fab> 
                                                        }
                                                    >
                                                        <Avatar variant="square" sx={{width: 120, height: 120}} alt={company?.razao_social_company} src={urlNewImage}/> 
                                                    </Badge>
                                                    <PublishedWithChangesIcon sx={{m:'-15px', p:0, margin:'auto', textAlign:'center'}} />
                                                    <Avatar variant="square" sx={{width: 120, height: 120}} alt={company?.razao_social_company} src={company?.logo_company ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/logo/medium_' + company?.logo_company : ''}/>    
                                                </Box>
                                                <Alert severity="info" sx={{marginTop:'15px'}}>Salve para confirmar a substituição da imagem ou cancele clicando no ícone da lixeira</Alert>
                                            </>
                                            ) : (
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    <Fab size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
                                                        <BackupIcon />
                                                    </Fab> 
                                                }
                                            >
                                                <Avatar variant="square"  sx={{width: 120, height: 120}} alt={company?.razao_social_company} src={company?.logo_company ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/logo/medium_' + company?.logo_company : '/'}/>
                                            </Badge>
                                            )}
                                        </Box>
                                    </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>  
                            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                                <CardHeader
                                    title='Administradores'
                                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                                />
                                <Divider/>
                                <CardContent>
                                    <Grid item xs={12} >
                                        <EASSelect 
                                            isLoading={isLoading}
                                            options={users ? users :[]}
                                            label="Usuários autorizados"
                                            getOptionLabel={(option) => option.text}                                  
                                            value={company?.users && users && company.users.map((tag) => users.find(item => item.value == tag))}                                  
                                            onChangeSelect={(event, values:Array<IList>) => {
                                                    const NewValue:Array<string> = [];
                                                    values.map((value) => NewValue.push(value.value));
                                                    setCompany(existingValues => ({
                                                        ...existingValues,
                                                        users: NewValue,
                                                    }));
                                                    } 
                                                } 
                                            fullWidth
                                            multipleSelect
                                            filterSelectedOptions
                                            disableClearable
                                        />
                                    </Grid> 
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                    <CardHeader
                            sx={{py:'2.5px'}}
                            title={
                        <Tabs variant="scrollable" value={valueTab} onChange={handleChangeValueTab} allowScrollButtonsMobile  textColor='primary' sx={{'& .MuiTabs-indicator': { backgroundColor: 'none', height: 0}}}>
                            <Tab label="Dados da Empresa" {...a11yProps(0)} sx={{fontSize: 16, }}/>
                            <Tab label="Endereço e Contato" {...a11yProps(1)} sx={{fontSize: 16}}/>
                            <Tab label="Programas" {...a11yProps(2)} sx={{fontSize: 16}}/>
                        </Tabs>}
                        //<CardHeader
                        //    title="Informações da Pessoa"
                       //     titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                        />
                        <Divider/>
                        <CardContent>
                            <CompanyTabPanel value={valueTab} index={0}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label="Razão Social"
                                                    type="text"
                                                    value={company?.razao_social_company}
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            razao_social_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.razao_social_company}
                                                    helperText={companyError?.razao_social_company}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={8}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label="E-mail"
                                                    type='text'
                                                    value={company?.email_company}                                
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            email_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.email_company}
                                                    helperText={companyError?.email_company}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <EASInput
                                                    id="cnpj_company"
                                                    onFocus={() => 
                                                        setCompanyError(existingValues => ({
                                                        ...existingValues,
                                                        cnpj_company: '',
                                                        }))
                                                    }
                                                    mask={'maskCNPJ'}
                                                    isLoading={isLoading}
                                                    label='CNPJ'
                                                    type='text'
                                                    value={company?.cnpj_company}                                
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            cnpj_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.cnpj_company}
                                                    helperText={companyError?.cnpj_company}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label="Nome Fantasia"
                                                    type="text"
                                                    value={company?.nome_fantasia_company}
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            nome_fantasia_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.nome_fantasia_company}
                                                    helperText={companyError?.nome_fantasia_company}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label="Site"
                                                    type="text"
                                                    value={company?.site_company}
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            site_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.site_company}
                                                    helperText={companyError?.site_company}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label="Pessoa para contato"
                                                    type="text"
                                                    value={company?.pessoa_contato_company}
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            pessoa_contato_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.pessoa_contato_company}
                                                    helperText={companyError?.pessoa_contato_company}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CompanyTabPanel>
                            <CompanyTabPanel value={valueTab} index={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                                <EASInput
                                                    mask={'maskTel'}
                                                    isLoading={isLoading}
                                                    label="Telefone Movel"
                                                    type='text'
                                                    value={company?.celular_company}                                
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            celular_company: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                                <EASInput
                                                        mask={'maskTel'}
                                                        isLoading={isLoading}
                                                        label="Telefone Residencial"
                                                        type='text'
                                                        value={company?.telefone_company}                                
                                                        onChange={(e) => 
                                                            setCompany(existingValues => ({
                                                                ...existingValues,
                                                                telefone_company: e.target.value,
                                                            }))
                                                        }
                                                    />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    mask={'maskCEP'}
                                                    label='CEP'
                                                    type='text'
                                                    value={company?.cep_company}                                
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            cep_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.cep_company}
                                                    helperText={companyError?.cep_company}
                                                />
                                            </Grid>
                                            <Hidden lgDown>
                                                <Grid item sm={6} lg={9}>
                                                </Grid>
                                            </Hidden>

                                            <Grid item xs={8} sm={10}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label='Logradouro'
                                                    type='text'
                                                    value={company?.logradouro_company}                                
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            logradouro_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.logradouro_company}
                                                    helperText={companyError?.logradouro_company}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label='Número'
                                                    type='text'
                                                    value={company?.numero_company}                                
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            numero_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.numero_company}
                                                    helperText={companyError?.numero_company}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label='Complemento'
                                                    type='text'
                                                    value={company?.complemento_company}                                
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            complemento_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.complemento_company}
                                                    helperText={companyError?.complemento_company}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label='Bairro'
                                                    type='text'
                                                    value={company?.bairro_company}                                
                                                    onChange={(e) => 
                                                        setCompany(existingValues => ({
                                                            ...existingValues,
                                                            bairro_company: e.target.value,
                                                        }))
                                                    }
                                                    error={!!companyError?.bairro_company}
                                                    helperText={companyError?.bairro_company}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASSelect 
                                                    isLoading={isLoading}
                                                    options={estados ? estados : []}
                                                    label="Estado"
                                                    getOptionLabel={(option) => option.text || ''}
                                                    value={estados ? estados.find(item => item.value == company?.id_estado) : ''}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setCompany(existingValues => ({
                                                                ...existingValues,
                                                                id_estado: value.value,
                                                            }));
                                                            
                                                    }}}  
                                                    error={!!companyError?.id_estado}
                                                    helperText={companyError?.id_estado}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASSelect 
                                                    isLoading={isLoading || (isFetchingCidades && isLoadingCidades)}
                                                    options={cidades ? cidades : []}
                                                    label="Cidade"
                                                    getOptionLabel={(option) => option.text || ''}
                                                    value={(cidades && company?.id_cidade) ? (cidades.find(item => item.value == company?.id_cidade) ? cidades.find(item => item.value == company?.id_cidade) : null) : null}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setCompany(existingValues => ({
                                                                ...existingValues,
                                                                id_cidade: value.value,
                                                            }));
                                                    }}}                              
                                                    error={!!companyError?.id_cidade}
                                                    helperText={companyError?.id_cidade}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>                               
                            </CompanyTabPanel>
                            <CompanyTabPanel value={valueTab} index={2}>
                                ... em contrução
                            </CompanyTabPanel>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Enviar Imagem</DialogTitle>
                <Divider/>
                <DialogContent sx={{maxWidth:'380px'}}>
                    <DialogContentText>
                        <Typography component="span" variant="subtitle2" >
                            Adicione ou atualize a logomarca da empresa. Recomendamos imagens quadradas.
                        </Typography>
                    </DialogContentText>
                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 3}}>
                        <EASFileUploadCrop 
                            titleButton="Enviar Arquivo"
                            acceptFile={NewacceptFile}
                            isLoadingUpload={isLoadingUpload}
                            elementIsDragAccept={<Box sx={{p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed green', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="green" variant="h6">Pode soltar a imagem agora</Typography></Box>}
                            elementIsDragReject={<Box sx={{ p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed red', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="red" variant="h6">Este arquivo não é permitido</Typography></Box>}
                            elementIsNotDragActive={<Box sx={{ p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed grey', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="grey" variant="h6">Clique ou arraste a imagem até aqui</Typography></Box>}
                            setFile={(e) => loadFile(e) }
                            file={file}
                            previewCanvasRef={previewCanvasRef}
                            imgRef={imgRef}
                            setPercentCrop={(e) => setPercentCrop(e)}
                            percentCrop={percentCrop}

                        />
                     </Box>            
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose} disabled={isLoadingUpload}>Cancelar</Button>
                    <Button variant="contained" onClick={storeAvatar} disabled={isLoadingUpload}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </Box>
        
    );
});

CompanyTabProfile.displayName = 'CompanyTabProfile';

export default CompanyTabProfile;
