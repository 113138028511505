
import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography, Alert, Skeleton } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';


import { IFile } from '../../shared/contexts/FilesContext/types';
import { FilesService } from '../../shared/services/api/files/FilesService';

import { EASInput, EASSelect } from '../../shared/forms';

import { isValidCPF, UtilService } from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
import { useAuthContext } from '../../shared/contexts';
import { EASFileUpload } from '../../shared/components/eas-file-upload/EASFileUpload';
import { EASButtonFile } from '../../shared/forms/EASButtonFile';
import { useMutation, useQuery, useQueryClient } from 'react-query';

interface IAcceptFile{
    [key: string]: string[];
}

type IFilesNewFileProps = {
    open: boolean;
    setOpen: (open:boolean) => void;
};



export const FilesNewFile:React.FC<IFilesNewFileProps> =  (({open, setOpen }) => {

    const [saveLoading, setSaveLoading] = useState(false);
    //const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUpload, setIsLoadingUpload] = useState(false);

    const [file, setFile] = useState<IFile>({});
    const [fileError, setFileError] = useState<IFile | null>(null);
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [fileTemp, setFileTemp] = useState<File | null>(null);
    const [base64, setBase64] = useState<string | ArrayBuffer | null>();
    const { user } = useAuthContext();

    const { enqueueSnackbar } = useSnackbar();
    
    const queryClient = useQueryClient();

    const { data:tags, isLoading  } = useQuery(['tags-list'],() =>  UtilService.getTags(),  {
        staleTime: Infinity,
        onError: () => {
          enqueueSnackbar('Ocorreu um problema carregar a lista Tags.', { variant:'error'});
        },
      });

      const { mutateAsync:storeNew } = useMutation((newFile:IFile) => FilesService.createFile(newFile), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-file-list');
            setFile({});
            setSaveLoading(false);
            enqueueSnackbar('Arquivo cadastrado com sucesso!', { variant:'success'});
            setOpen(false);
            setFileName(undefined);
            setFileTemp(null);
            setBase64(null);
            setIsLoadingUpload(false);
        },
        onError: () => {
            setSaveLoading(false);
            enqueueSnackbar('Erro ao cadastrar arquivo.', { variant:'error'});
        },
    });


    const isValidFile = yup.object({
        name_file: yup.string().required('Este campo é obrigatório'),
     });


    const saveFile = () => {
        isValidFile
        .validate( file , { abortEarly: false })
        .then(validateFile => { 
            setSaveLoading(true);
            const newFile: IFile = {
                status_file: '1',
                name_file: validateFile.name_file,
                description_file: file?.description_file,
                tags: file?.tags,
                user_register: user?.id,
                //tmp_file: fileTemp ? fileTemp : null
                base64_file: base64 ? base64.toString() : undefined,
                name_temp_file:fileName
            };          
            storeNew(newFile);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setFileError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };



    const convertToBase64 = (file:File): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };


    const loadFile = (selectedFile:File) => {
        setIsLoadingUpload(true);
        setFileTemp(selectedFile);
        setFileName(selectedFile.name);

        convertToBase64(selectedFile).then((response) => {
            if (response instanceof Error) {
                setFileName(undefined);
                setFileTemp(null);
                setBase64(null);
                setIsLoadingUpload(false);
                enqueueSnackbar('Erro ao carregar arquivo.', { variant:'error'});
            }
            else {
                setBase64(response);
                setIsLoadingUpload(false);
            }
        });
    };

    const NewacceptFile : IAcceptFile = {'application/pdf':['.pdf'],
                                        'application/msword': ['.doc', '.docx'], 
                                        'application/vnd.ms-excel': ['.xls', '.xlsx'],
                                        'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
                                        'text/plain': ['.txt', '.csv']};

    const handleClose = () => {
        //
        setFileError(null);
       //
        setFileName(undefined);
        setFileTemp(null);
        setOpen(false);
        setFile({});
        //setTags([]);
    };


    return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Cadastrar Novo Arquivo</DialogTitle>
        <Divider/>
        <DialogContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {saveLoading && (                   
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress variant='indeterminate' />
                </Box>
            )}
            {!saveLoading && (
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        {(isLoadingUpload )  && (
                            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 5}}>
                                <Skeleton variant="rectangular" width={150} height={150} />
                            </Box>
                        )}
                        {(!isLoadingUpload) && (
                            fileTemp && fileName ? (
                        <>
                            <Box sx={{display: 'flex',  justifyContent: 'center', alignItems: 'center'}}>
                                <EASButtonFile title="Novo Arquivo" typeIcon={fileName.split('.').pop()} size="medium" /> 
                            </Box>
                            <Alert severity="info" sx={{marginTop:'15px'}}>Pronto para salvar.</Alert>
                        </>
                        ) : (
                        <EASFileUpload 
                            acceptFile={NewacceptFile}
                            //isLoadingUpload={isLoadingUpload}
                            elementIsDragAccept={<Box sx={{p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed green', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="green" variant="h6">Pode soltar a imagem agora</Typography></Box>}
                            elementIsDragReject={<Box sx={{ p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed red', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="red" variant="h6">Este arquivo não é permitido</Typography></Box>}
                            elementIsNotDragActive={<Box sx={{ p: 2, mb:3, borderRadius:'8px', height:140, border: '1px dashed grey', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}><Typography color="grey" variant="h6">Clique ou arraste a imagem até aqui</Typography></Box>}
                            onSelected={(e) => loadFile(e)}
                        />
                        ))}
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <EASInput
                                    isLoading={isLoading}
                                    label="Nome"
                                    type="text"
                                    value={file?.name_file}
                                    onChange={(e) => 
                                        setFile(existingValues => ({
                                            ...existingValues,
                                            name_file: e.target.value,
                                        }))
                                    }
                                    error={!!fileError?.name_file}
                                    helperText={fileError?.name_file}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <EASInput
                                    isLoading={isLoading}
                                    label="Descição"
                                    type='text'
                                    value={file?.description_file}                                
                                    onChange={(e) => 
                                        setFile(existingValues => ({
                                            ...existingValues,
                                            description_file: e.target.value,
                                        }))
                                    }
                                    multiline
                                    rows="2"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EASSelect 
                                    isLoading={isLoading}
                                    options={tags ? tags : []}
                                    label="Tags do Arquivo"
                                    getOptionLabel={(option) => option.text}                                  
                                    value={(file?.tags && tags) && file?.tags.map((tag) => tags.find(item => item.value == tag))}                                                                 
                                    onChangeSelect={(event, values:Array<IList>) => {
                                                        const NewValue:Array<string> = [];
                                                        values.map((value) => NewValue.push(value.value));
                                                        setFile(existingValues => ({
                                                            ...existingValues,
                                                            tags: NewValue,
                                                        }));
                                                    }} 
                                    fullWidth
                                    multipleSelect
                                    filterSelectedOptions
                                    disableClearable
                                />
                            </Grid>                   
                        </Grid>
                    </Grid>
                </Grid>
            )}
            </Box>            
        </DialogContent>
        <Divider/>
        <DialogActions>
            <Button variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</Button>
            <Button variant="contained" onClick={saveFile} disabled={saveLoading}>Confirmar</Button>
        </DialogActions>
    </Dialog>   
    );
});

