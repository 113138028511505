
import React, { useEffect, useState } from 'react';
import { Box, Grid, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, Hidden, CircularProgress } from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { IPerson, IList} from '../../shared/types';
import { PeopleService } from '../../shared/services/api/people/PeopleService';

import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

import { isValidCPF, UtilService, uniqueCPF, uniqueMail } from '../../shared/services/api/util/UtilService';


import { useAuthContext } from '../../shared/contexts';
import { useMutation, useQuery, useQueryClient } from 'react-query';


type IPeopleNewPerson = {
    open: boolean;
    setOpen: (open:boolean) => void;
};



export const PeopleNewPerson:React.FC<IPeopleNewPerson> =  (({open, setOpen}) => {


    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const [saveLoading, setSaveLoading] = useState(false);
    const [person, setPerson] = useState<IPerson>({});
    const [personError, setPersonError] = useState<IPerson | null>(null);
    
    const { user } = useAuthContext();

    const handleClose = () => {
      setPerson({});
      setOpen(false);
    };
      
    const { data:estados, isLoading:isLoadingEstados } = useQuery(['list-estados'], () =>  UtilService.getEstados(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:estadoCivil, isLoading:isLoadingEstadoCivils } = useQuery(['list-estadoCivil'], () =>  UtilService.getEstadoCivil(), {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:generos, isLoading:isLoadingGeneros } = useQuery(['list-generos'], () =>  UtilService.getGeneros(), {
        
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de estados.', { variant:'error'});
        },
    });

    const { data:cidades, isFetching:isFetchingCidades, isLoading:isLoadingCidades } = useQuery(['list-cidades-person' , [person?.id_estado]], () =>  UtilService.getCidades(person?.id_estado), {
        enabled:!(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros) && !!(person?.id_estado),
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema ao tentar carregar a lista de cidades.', { variant:'error'});
        },
    });
   
    const { mutateAsync:storeCreate, } = useMutation((newPerson:IPerson) =>PeopleService.createPerson(newPerson), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-people-list');
            queryClient.invalidateQueries('operator-person-edit');
            queryClient.invalidateQueries('users-list');
            enqueueSnackbar('Pessoa cadastrada com sucesso!', { variant:'success'});
            setOpen(false);
            setSaveLoading(false);
            setPerson({});

        },
        onError: () => {
            enqueueSnackbar('Erro ao tentar cadastrar nova pessoa.', { variant:'error'});
            setSaveLoading(false);
        },
    });


    const isValidPerson = yup.object({
        name_user: yup.string().required('Este campo é obrigatório'),
        email_user: yup.string().test('email_user', 'Email já cadastrado para outra pessoa', (email) => (email ? uniqueMail(email, '') : false)).email('Informe um e-mail válido').required('Este campo é obrigatório'),
        cpf_user: yup.string().test('cpf_user', 'CPF já cadastrado', (cpf) => (cpf ? (isValidCPF(cpf) && uniqueCPF(cpf, '')) :  false)).test('cpf_user', 'Informe um CPF válido', (cpf) => (cpf ? isValidCPF(cpf) :  false)).required('Este campo é obrigatório'),
       // data_nascimento_user: yup.string().required('Este campo é obrigatório')
    });
    


    const savePerson = () => {
        isValidPerson
        .validate( person , { abortEarly: false })
        .then(validatePerson => { 
            setSaveLoading(true);
            const newPerson: IPerson = {
                status_user: '1',
                name_user: validatePerson.name_user,
                email_user: validatePerson.email_user,
                block_user: '0',
                public_user: '1',
                password_user: validatePerson.cpf_user.substring(0,3) + validatePerson.cpf_user.substring(0,3) + validatePerson.cpf_user.substring(0,3),
                activation_user: '1',
                cpf_user: validatePerson.cpf_user,
               // nmae_user: '',
               // npai_user: '',
               // tsangue_user: '',
               // rg_user: '',
                sexo_user: person?.sexo_user,
                data_nascimento_user: person?.data_nascimento_user,
                id_estado_civil: person?.id_estado_civil,
               // nacionalidade_user: '',
               // profissao_user: '',
                tel_celular_user: person?.tel_celular_user,
                tel_residencial_user: person?.tel_residencial_user,
                id_estado: person?.id_estado,
                id_cidade: person?.id_cidade,
                cep_user: person?.cep_user,
                bairro_user: person?.bairro_user,
                logradouro_user: person?.logradouro_user,
                numero_user: person?.numero_user,
                complemento_user: person?.complemento_user,
                //image_user: '',
                //observacao_user:'',
                user_register: user?.id,
            };        
            storeCreate(newPerson);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setPersonError(existingValues => ({
                                        ...existingValues,
                                        [keyError]: error.message
                                    }));                        
                }
            });
        });
    };

    return (
    <Dialog open={open} onClose={handleClose} fullWidth={true}
    maxWidth={'md'}>
        <DialogTitle>Cadastrar Nova Pessoa</DialogTitle>
        <Divider/>
        <DialogContent>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight:'501px'}}>
            {saveLoading && (                   
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress variant='indeterminate' />
                </Box>
            )}
            {!saveLoading && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <EASInput
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    onFocus={() => 
                                        setPersonError(existingValues => ({
                                        ...existingValues,
                                        name_user: '',
                                        }))
                                    }
                                    label="Nome"
                                    type="text"
                                    value={person?.name_user}
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            name_user: e.target.value,
                                        }))
                                    }
                                    
                                    error={!!personError?.name_user}
                                    helperText={personError?.name_user}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={3}>
                                <EASPicker 
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    label="Data de Nascimento"
                                    value={person?.data_nascimento_user} 
                                    error={!!personError?.data_nascimento_user}
                                    helperText={personError?.data_nascimento_user}
                                    onChangePiker={(e) => {  
                                                        if(e instanceof Date) {         
                                                        setPerson(existingValues => ({
                                                                ...existingValues,
                                                                data_nascimento_user: e.toISOString().split('T')[0],
                                                            }));
                                                        }}
                                                    }
                                    />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={3}>
                                <EASInput
                                    id="cpf_user"
                                    onFocus={() => 
                                        setPersonError(existingValues => ({
                                        ...existingValues,
                                        cpf_user: '',
                                        }))
                                    }
                                    mask={'maskCPF'}
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    label='CPF'
                                    type='text'
                                    value={person?.cpf_user}                                
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            cpf_user: e.target.value,
                                        }))
                                    }
                                    error={!!personError?.cpf_user}
                                    helperText={personError?.cpf_user}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} lg={6}> 
                                <EASInput
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    onFocus={() => 
                                        setPersonError(existingValues => ({
                                        ...existingValues,
                                        email_user: '',
                                        }))
                                    }
                                    label="E-mail"
                                    type='text'
                                    value={person?.email_user}                                
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            email_user: e.target.value,
                                        }))
                                    }
                                    error={!!personError?.email_user}
                                    helperText={personError?.email_user}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={3}>
                                <EASInput
                                    mask={'maskTel'}
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    label="Telefone Movel"
                                    type='text'
                                    value={person?.tel_celular_user}                                
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            tel_celular_user: e.target.value,
                                        }))
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={3}>
                                <EASInput
                                        mask={'maskTel'}
                                        isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                        label="Telefone Residencial"
                                        type='text'
                                        value={person?.tel_residencial_user}                                
                                        onChange={(e) => 
                                            setPerson(existingValues => ({
                                                ...existingValues,
                                                tel_residencial_user: e.target.value,
                                            }))
                                        }
                                    />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <EASRadio
                                    label="Sexo"
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    options={generos}
                                    value={person?.sexo_user}
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            sexo_user: e.target.value,
                                        }))
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <EASInput
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    mask={'maskCEP'}
                                    label='CEP'
                                    type='text'
                                    value={person?.cep_user}                                
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            cep_user: e.target.value,
                                        }))
                                    }
                                    error={!!personError?.cep_user}
                                    helperText={personError?.cep_user}
                                />
                            </Grid>
                            <Hidden lgDown>
                                <Grid item xs={12} sm={6} md={8} lg={9}>
                                </Grid>
                            </Hidden>
                            <Grid item xs={8} sm={10}>
                                <EASInput
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    label='Logradouro'
                                    type='text'
                                    value={person?.logradouro_user}                                
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            logradouro_user: e.target.value,
                                        }))
                                    }
                                    error={!!personError?.logradouro_user}
                                    helperText={personError?.logradouro_user}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <EASInput
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    label='Número'
                                    type='text'
                                    value={person?.numero_user}                                
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            numero_user: e.target.value,
                                        }))
                                    }
                                    error={!!personError?.numero_user}
                                    helperText={personError?.numero_user}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASInput
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    label='Complemento'
                                    type='text'
                                    value={person?.complemento_user}                                
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            complemento_user: e.target.value,
                                        }))
                                    }
                                    error={!!personError?.complemento_user}
                                    helperText={personError?.complemento_user}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASInput
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    label='Bairro'
                                    type='text'
                                    value={person?.bairro_user}                                
                                    onChange={(e) => 
                                        setPerson(existingValues => ({
                                            ...existingValues,
                                            bairro_user: e.target.value,
                                        }))
                                    }
                                    error={!!personError?.bairro_user}
                                    helperText={personError?.bairro_user}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASSelect 
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros)}
                                    options={estados ? estados : []}
                                    label="Estado"
                                    getOptionLabel={(option) => option.text || ''}
                                    value={estados ? estados.find(item => item.value == person?.id_estado) : ''}                                  
                                    onChangeSelect={(event, value:IList) => {
                                        if (value && value.value) {
                                            setPerson(existingValues => ({
                                                ...existingValues,
                                                id_estado: value.value,
                                            }));
                                            
                                    }}}  
                                    error={!!personError?.id_estado}
                                    helperText={personError?.id_estado}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <EASSelect 
                                    isLoading={(isLoadingEstados || isLoadingEstadoCivils || isLoadingGeneros) || (isFetchingCidades && isLoadingCidades)}
                                    options={cidades ? cidades : []}
                                    label="Cidade"
                                    getOptionLabel={(option) => option.text || ''}
                                    value={(cidades && person?.id_cidade) ? (cidades.find(item => item.value == person?.id_cidade) ? cidades.find(item => item.value == person?.id_cidade) : null) : null}                                  
                                    onChangeSelect={(event, value:IList) => {
                                        if (value && value.value) {
                                            setPerson(existingValues => ({
                                                ...existingValues,
                                                id_cidade: value.value,
                                            }));
                                    }}}                              
                                    error={!!personError?.id_cidade}
                                    helperText={personError?.id_cidade}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            </Box>            
        </DialogContent>
        <Divider/>
        <DialogActions>
            <Button variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</Button>
            <Button variant="contained" onClick={savePerson} disabled={saveLoading}>Confirmar</Button>
        </DialogActions>
    </Dialog>   
    );
});

