import { Api } from '../axios-config';
import { Environment } from '../../../environment';
import { IResponseReport, IReportFinal } from '../../../types';

interface IReportService {
  id?: string;
}




const createReport = async (reportFinal: IReportFinal): Promise<IReportService | Error> => {
  try {
    const { data } = await Api.post('/report', { reportFinal: reportFinal } );
  
    if (data) 
      return data;

    return new Error('Erro de Conexao.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const updateReport = async (reportFinal: IReportFinal): Promise<void | Error> => {
  try {
    const { data } = await  Api.put(`/report/${reportFinal.id_report_final}`, reportFinal);
    
    if (data) 
      return data;  

  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};

const deleteReport = async (id: string): Promise<void | Error> => {
  try {
    const { data } = await Api.delete(`/report/${id}`);
    if (data) 
      return data;   
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro de conexão.');
  }
};


const getReport = async (id?: string): Promise<IResponseReport> => {
    const { data } = await Api.get(`/report/${id}/?public_token=${Environment.PUBLIC_TOKEN}`);
    return data;
};

const getReports = async (id_program?:string ): Promise<IResponseReport> => {
    const  {data} = await Api.get(`/report?public_token=${Environment.PUBLIC_TOKEN}&mentory=${id_program}`);
    return data;
   
};

export const ReportService = {
  getReports,
  getReport,
  createReport,
  updateReport,
  deleteReport,
};
