import { HTMLAttributes, ReactNode, useState } from 'react';
import {Autocomplete, AutocompleteProps, TextFieldProps, TextField, Skeleton } from '@mui/material';


type EASSelectProps = {
    options: Array<any>
    isLoading?: boolean | undefined;
    inputValue?: string ;
    freeSolo?: boolean;
    multipleSelect?: boolean;
    disableClearable?: boolean;
    filterSelectedOptions?: boolean;
    onChangeTextField?: (event: any | null) => void;
    getOptionLabel?: (option: any) => string
    onChangeSelect?: (event: any, newValue: any | null) => void;
    onInputChangeSelect?:  (event: any, newValue: any | null) => void;
    renderOption?:(props: HTMLAttributes<HTMLLIElement>, option: any) => ReactNode
  } & TextFieldProps;



export const EASSelect: React.FC<EASSelectProps> = ({...props}) => {
    

    return (
        <>

            {props.isLoading && (
                <Skeleton variant="rectangular" height={50} sx={{borderRadius:'8px'}}/>
            )}
            {!props.isLoading && (

                <Autocomplete
                    size={props.size}
                    autoHighlight
                    clearOnEscape
                    includeInputInList
                    freeSolo={props.freeSolo}
                    value={props.value} 
                    getOptionLabel={props.getOptionLabel}
                    onChange={props.onChangeSelect}
                    onInputChange={props.onInputChangeSelect}            
                    inputValue={props.inputValue}
                    options={props.options}
                    renderOption={props.renderOption}
                    multiple={props.multipleSelect}
                    disableClearable={props.disableClearable}
                    disabled={props.disabled}
                    fullWidth={props.fullWidth}
                    filterSelectedOptions={props.filterSelectedOptions}
                    renderInput={(params) => <TextField sx={{   fontSize: '0.85rem', 
                                                                borderRadius: 2,  
                                                                backgroundColor: 'eas.inputBackground',
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius:2,
                                                                  }
                                                            }}
                                                                    
                                                            onChange={props.onChangeTextField}                       
                                                            error={props.error}
                                                            helperText={props.helperText}
                                                            onFocus={props.onFocus}
                                                            label={props.label}
                                                            {...params}  />}
                    />
            )}
        </>


    );
};
