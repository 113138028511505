import React, { useState } from 'react';
import Sketch from '@uiw/react-color-sketch';
import { hsvaToHex } from '@uiw/color-convert';
import { TextFieldProps, InputAdornment, TextField, useTheme, Theme, Skeleton, Box } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { IMaskInput } from 'react-imask';

import { useEASThemeContext } from '../contexts';

type EASColorProps = {
  value: string;
  isLoading?: boolean | undefined;
  onChangeColor: (value: string) => void
} & TextFieldProps;


export const EASColor: React.FC<EASColorProps> = ({ ...props }) => {

  const { themeName } = useEASThemeContext();

  const [showColorPicker, setShowColorPicker] = useState(false);

  const autoFillDark = { WebkitBoxShadow: '0 0 0 500px #1f1f1f inset', borderRadius: 8 };
  const autoFillLight = { WebkitBoxShadow: '0 0 0 500px eas.inputBackground inset', borderRadius: 8 };


  return (
    <>
      {props.isLoading && (
        <Skeleton variant="rectangular" height={50} sx={{ borderRadius: '8px' }} />
      )}
      {!props.isLoading && (
        <ClickAwayListener onClickAway={() => setShowColorPicker(false)}>
          <Box sx={{ position: 'relative' }}>
            <TextField
              InputProps={{
                autoComplete: 'off',
                sx: { borderRadius: 2, backgroundColor: 'eas.inputBackground' },
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ backgroundColor: props?.value ? props.value : '#ffffff', height: 30, width: 40 }} >
                    </Box>
                  </InputAdornment>


                ),
              }}
              onFocus={() => setShowColorPicker(true)}
              label={props.label}
              type={props.type}
              value={props.value ? props.value : ''}
              onChange={props.onChange}
              onKeyDown={props.onKeyDown}
              size={props.size}
              error={props.error}
              helperText={props.helperText}
              disabled={props.disabled}
              fullWidth
              multiline={props.multiline}
              rows={props.rows}
              maxRows={props.maxRows}
              minRows={props.minRows}
              sx={props.sx}
              id={props.id}
              InputLabelProps={{ shrink: true }}
            />
            {
              showColorPicker && (
                <Box sx={{ position: 'absolute', zIndex: 11 }}  >
                  <Sketch
                    editableDisable={false}
                    disableAlpha={true}
                    color={props?.value ? props.value : undefined}
                    onChange={(e) => {
                      if (e)
                        props.onChangeColor(hsvaToHex(e.hsva));
                    }}
                  />
                </Box>
              )
            }
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};
