import React from 'react';

import { IReportObjectives } from '../../shared/types';
import { Heading, Slide, Text, Box } from 'spectacle';

interface IPresentationResourcesHumanObjectivesProps {
  Key: number;
  indexKey: number;
  Objectives?: Array<IReportObjectives>;
  primary: string;
  background: string;
}

export const PresentationResourcesHumanObjectives: React.FC<IPresentationResourcesHumanObjectivesProps> = ({ indexKey, Objectives, primary, background }) => {

  return (
    <Slide key={indexKey} backgroundColor={background}>
      <Heading fontWeight="400" fontSize="36px" margin="0px" color="#000000">COMPETÊNCIAS ELEITAS</Heading>
      <Box backgroundColor="#858585"
        position="absolute"
        top="130px"
        borderRadius="50%"
        left="450px"
        width="10px"
        height="10px">
      </Box>
      <Box backgroundColor="#858585"
        position="absolute"
        top="134px"
        left="455px"
        width="452px"
        height="2px">
      </Box>
      <Box backgroundColor="#858585"
        position="absolute"
        top="130px"
        borderRadius="50%"
        right="450px"
        width="10px"
        height="10px">
      </Box>
      <Box backgroundColor="#858585"
        position="absolute"
        top="190px"
        borderRadius="50%"
        left="680px"
        width="10px"
        height="10px">
      </Box>
      <Box backgroundColor="#858585"
        position="absolute"
        top="192px"
        left="684px"
        width="2px"
        height="322px">
      </Box>
      <Box backgroundColor="#858585"
        position="absolute"
        top="510px"
        borderRadius="50%"
        left="680px"
        width="10px"
        height="10px">
      </Box>
      {(Objectives && Objectives.length) && Objectives.map((objective, index) => (
          <div key={index} >
            <Box backgroundColor="#e9e9e9"
              position="absolute"
              top={(240 + (Math.trunc(index / 2) * 100)) + 'px'}
              left={(index % 2) == 0 ? '200px' : '760px'}
              width="400px"
              height="80px">
            </Box>
            <Box backgroundColor="#e9e9e9"
              borderRadius="50%"
              position="absolute"
              top={(240 + (Math.trunc(index / 2) * 100)) + 'px'}
              left={(index % 2) == 0 ? '570px' : '1130px'}
              width="80px"
              height="80px">
            </Box>
            <Box backgroundColor={primary}
              borderRadius="50%"
              position="absolute"
              top={(240 + (Math.trunc(index / 2) * 100)) + 'px'}
              left={(index % 2) == 0 ? '170px' : '730px'}
              width="80px"
              height="80px">
            </Box>
            <Box backgroundColor="trasnparent"
              position="absolute"
              top={(265 + (Math.trunc(index / 2) * 100)) + 'px'}
              left={(index % 2) == 0 ? '170px' : '730px'}
              width="83px"
              >
              <Text fontWeight="700"
                fontSize="28px"
                textAlign="center"
                margin="0px"
                padding="0px"
                color="#000000">
                {objective.percent_objective}
              </Text>
            </Box>
            <Box backgroundColor="trasnparent"
              position="absolute"
              top={(220 + (Math.trunc(index / 2) * 100)) + 'px'}
              left={(index % 2) == 0 ? '230px' : '790px'}
              width="400px">
              <Text fontWeight="600"
                fontSize="22px"
                color="#000000">
                {objective.text_objective}
              </Text>
            </Box>
            <Box backgroundColor="trasnparent"
              position="absolute"
              top={(250 + (Math.trunc(index / 2) * 100)) + 'px'}
              left={(index % 2) == 0 ? '230px' : '790px'}
              width="400px">
              <Text fontWeight="400"
                fontSize="22px"
                color="#000000">
                {objective.process_objective + ' Processos '}
              </Text>
            </Box>
          </div>
        ))}
    </Slide>
                  
  );

};
