import { Environment } from '../../../environment';
import { IList, IVideoTumb } from '../../../types';
import { Api } from '../axios-config';

const getGuests = async (id?: string): Promise<Array<IList>> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getGuests&value=${id}`);
  return data;
};

const getUsers = async (): Promise<Array<IList>> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getUsers`);
  return data;
};

const getOperators = async (): Promise<Array<IList>> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getOperators`);
  return data;
};

const getCompanies = async (): Promise<Array<IList>> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getCompanies`);
  return data;
};


const getEstados = async ():Promise<Array<IList>> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getEstados`);
  return data;
};

const getCidades = async (id?: string): Promise<Array<IList>> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getCidades&value=${id}`);
  return data;
};

const getEstadoCivil = async ():Promise<Array<IList>> => {
  const estadoCivil = [
    { text: 'Solteiro',   value: '1'  },
    { text: 'Casado',     value: '2'  },
    { text: 'Separado',   value: '3'  },
    { text: 'Divorciado', value: '4'  },
    { text: 'Viúvo',      value: '5'  },
  ];
  return estadoCivil;
};

const getGeneros = async ():Promise<Array<IList>> => {
  const generos = [
    { text: 'Masculino',   value: '1'  },
    { text: 'Feminino',     value: '2'  },
    { text: 'Outros',   value: '3'  },
  ];
  return generos;
};

const getUserGroups = async ():Promise<Array<IList>> => {
  const userGroups = [
    { text: 'Operador',       value: '1'  },
    { text: 'Financeiro',     value: '2'  },
    { text: 'Administrador',  value: '3'  },
  ];
  return userGroups;
};

const getPrograms = async (): Promise<Array<IList>> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getPrograms`);
  return data;
};

const getMentoryTypes = async ():Promise<Array<IList>> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getMentoryTypes`);
  return data;
};

const getTags = async (): Promise<Array<IList>> => {
    const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getTags`);
    return data;
};

const getInterval = async ():Promise<Array<IList>> => {
  const interval = [
    { text: 'Há cada 15 dias',   value: '1'  },
    { text: 'Há cada 1 mês',     value: '2'  },
    { text: 'Há cada 2 meses',   value: '3'  },
    { text: 'Há cada 3 meses',   value: '4'  },
    { text: 'Há cada 6 meses',   value: '5'  },
  ];
  return interval;
};

const getPeriod = async ():Promise<Array<IList>> => {
  const period = [
    { text: '3 dias',   value: '1'  },
    { text: '5 dias',     value: '2'  },
    { text: '10 dias',   value: '3'  },
    { text: 'Todo o período',   value: '4'  }
  ];
  return period;
};

const getRepeat = async ():Promise<Array<IList>> => {
  const repeat = [
    { text: 'Apenas 1 vez',   value: '1'  },
    { text: '2 vezes',     value: '2'  },
    { text: '3 vezes',   value: '3'  },
    { text: '4 vezes',   value: '4'  },
    { text: 'Indeterminado',   value: '5'  }
  ];
  return repeat;
};

const getDuration = async ():Promise<Array<IList>> => {
  const duration = [
    { text: '30 minutos',   value: '1'  },
    { text: '60 minutos',     value: '2'  },
    { text: '90 minutos',   value: '3'  },
    { text: '120 minutos',   value: '4'  },
    { text: 'Indeterminado',   value: '5'  }
  ];
  return duration;
};

const getTypeMeeting = async ():Promise<Array<IList>> => {
  const typeMeeting = [
    { text: 'Online',    value: '0'  },
    { text: 'Presencial',     value: '1'  },
  ];
  return typeMeeting;
};

const getTaskPlanStatus = async ():Promise<Array<IList>> => {
  const taskPlanStatus = [
    { text: 'Previsto',     value: '1'  },
    { text: 'Executando',   value: '3'  },
    { text: 'Pausado',      value: '0'  },
    { text: 'Finalizado',   value: '2'  },
  ];
  return taskPlanStatus;
};


export const getVideo = async (code_vide: string): Promise<IVideoTumb | boolean> => {
  try {
    const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getVideo&value=${code_vide}`);
    if (data) {
        return data;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getObjectivesSample = async (): Promise<Array<IList>>  => {
    const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getObjectivesSample`);
    return data;
};

export const getDetailings = async (mentory?: string): Promise<Array<IList>>  => {
    const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=getDetailing&value=${mentory}`);
    return data;
};


export const uniqueCPF = async (cpf: string, id?:string): Promise<boolean> => {
  try {
    const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=uniqueCPF&value=${cpf}&id=${id}`);
    if (data) {
      if(data === 'true')
        return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const uniqueCNPJ = async (cnpj: string, id?:string): Promise<boolean> => {
  try {
    const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=uniqueCNPJ&value=${cnpj}&id=${id}`);
    if (data) {
      if(data === 'true')
        return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const uniqueMail = async (email: string, id?:string): Promise<boolean> => {
    const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=uniqueMail&value=${email}&id=${id}`);
    return data;
};
export const uniqueMailPJ = async (email: string, id?:string): Promise<boolean> => {
  const { data } = await Api.get(`/util?public_token=${Environment.PUBLIC_TOKEN}&execute=uniqueMailPJ&value=${email}`);
  return data;
};

export const isValidCPF = (cpf:string) => {

  if (typeof cpf !== 'string') 
      return false;
  
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) 
      return false;

  const validator = cpf.split('')
      .filter((digit:string, index:number, array:Array<string>) => index >= array.length - 2 && digit)
      .map( el => + el );

  const toValidate = (pop:number) => cpf.split('')
      .filter((digit:string, index:number, array:Array<string>) => index < array.length - pop && digit)
      .map(el => +el);

  const rest = (count:number, pop:number) => (toValidate(pop)
      .reduce((soma:number, el:number, i:number) => soma + el * (count - i), 0) * 10) % 11 % 10;
  return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1]);
};

export const isValidCNPJ = (cnpj:string) => {

  return true;
  if (typeof cnpj !== 'string') 
      return false;

  cnpj = cnpj.replace(/[^\d]+/g, '');
  if (cnpj.length !== 14 || !!cnpj.match(/(\d)\1{10}/)) 
    return false;


  const validator = cnpj.split('')
  .filter((digit:string, index:number, array:Array<string>) => index >= array.length - 2 && digit)
  .map( el => + el );

  const toValidate = (pop:number) => cnpj.split('')
    .filter((digit:string, index:number, array:Array<string>) => index < array.length - pop && digit)
    .map(el => +el);

  const rest = (count:number, pop:number) => (toValidate(pop)
    .reduce((soma:number, el:number, i:number) => soma + el * (count - i), 0) * 10) % 11 % 10;
  return !(rest(10,2) !== validator[0] || rest(11,1) !== validator[1]);

};

export const UtilService = {
  getGuests,
  getUsers,
  getOperators,
  getCompanies,
  getEstados,
  getCidades,
  getEstadoCivil,
  getGeneros,
  getUserGroups,
  getTags,
  getMentoryTypes,
  getPrograms,
  getDetailings,
  getInterval,
  getPeriod,
  getRepeat,
  getDuration,
  getTypeMeeting,
  getObjectivesSample,
  getTaskPlanStatus
};
