import React, { useState, useEffect } from 'react';
import { CircularProgress, Box, Grid, Tooltip, IconButton, Divider, Typography }from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { ITaskPlan } from '../../shared/contexts/TaskPlanContext/types';
import { EASButton, EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';
import { useAuthContext, useMentoringContext } from '../../shared/contexts';
import { IList } from '../../shared/types';
import { UtilService } from '../../shared/services/api/util/UtilService';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';

import { useQueryClient } from 'react-query';
import { Stack } from '@mui/system';

interface ITaskPlanEditProps {
  TaskPlan:ITaskPlan;
  Detailings:Array<IList>;
  setOpenDrawer: (open:boolean) => void;
  removeTaskPlan:(idTaskPlan?:string) => void;
  updateEditTaskPlan:(newTaskPlan?:ITaskPlan) => void;

}

export const TaskPlanEdit: React.FC<ITaskPlanEditProps> = ({ TaskPlan, Detailings, setOpenDrawer, removeTaskPlan, updateEditTaskPlan }) => {


  const { user } = useAuthContext();
  const [saveLoading, setSaveLoading] = useState(false);

  const [taskPlanEdit, setTaskPlanEdit] = useState<ITaskPlan>(TaskPlan);
  const [taskPlanError, setTaskPlanError] = useState<ITaskPlan | null>(null);

  const [detailings, setDetailings] = useState<Array<IList>>(Detailings);
  const [taskPlanStatus, setTaskPlanStatus] = useState<Array<IList>>([]);
  const { mentory } = useMentoringContext();
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {

    async function getTaskPlanStatus() {
      const statusResponse = await UtilService.getTaskPlanStatus();
      setTaskPlanStatus(statusResponse);
    }
    getTaskPlanStatus();
       
  }, []);

  const queryClient = useQueryClient();

  const closeDrawer = () => {
    queryClient.invalidateQueries('task-plan-edit');
    setTaskPlanEdit({});
    setTaskPlanError(null);
    setOpenDrawer(false);
    
  };

  
  const taskPlanSchema = yup.object().shape({
    title_task_plan: yup.string().required('Informe a título da tarefa'),
    text_task_plan: yup.string().required('Descreva a tarefa'),
    date_task_plan: yup.string().required('Informe a previsão de execução'),
    forecast_task_plan: yup.string().required('Informe a previsão de conclusão')
  });

  const dialogSave = () => {
    
   
      setSaveLoading(true);
      taskPlanSchema
      .validate( taskPlanEdit, { abortEarly: false })
      .then(validateEditValues => {
     
          const EditTaskPlan: ITaskPlan = {
            id_task_plan: taskPlanEdit?.id_task_plan,
            id_mentory: taskPlanEdit?.id_mentory,
            status_task_plan: taskPlanEdit?.status_task_plan,
            title_task_plan: validateEditValues.title_task_plan,
            text_task_plan: validateEditValues.text_task_plan,
            date_task_plan: validateEditValues.date_task_plan,
            forecast_task_plan: validateEditValues.forecast_task_plan,
            execute_task_plan: (taskPlanEdit?.status_task_plan == '2' || taskPlanEdit?.status_task_plan == '3') && taskPlanEdit?.execute_task_plan ? taskPlanEdit?.execute_task_plan : 'NULL',
            finish_task_plan: taskPlanEdit?.status_task_plan == '2' && taskPlanEdit?.finish_task_plan ? taskPlanEdit?.finish_task_plan : 'NULL',
            schedule_task_plan: taskPlanEdit?.schedule_task_plan,
            presentation_task_plan: taskPlanEdit?.presentation_task_plan,
            id_objective_detail: taskPlanEdit?.id_objective_detail,
            user_update: user?.id,
          };  
          
          updateEditTaskPlan(EditTaskPlan);
          /*
          TaskPlanService.updateTaskPlan(EditTaskPlan)
          .then((taskPlanResponse) => {
            if (taskPlanResponse instanceof Error) {
              setSaveLoading(false);
              //enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
            } else {
              setSaveLoading(false);
             // enqueueSnackbar('Tarefa atualizada com sucesso!', { variant:'success'});
             // setOpen(false);
             // setUpdateList();
            }
          }).catch((error) => {
           // setSaveLoading(false);
           // enqueueSnackbar('Erro ao atualizar tarefa.', { variant:'error'});
          });*/
      })
      .catch((errors: yup.ValidationError) => {
        setSaveLoading(false);
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setTaskPlanError(existingValues => ({
                                    ...existingValues,
                                    [keyError]: error.message
                                }));                        
            }
        });
      });
  };




  return (


    <Box sx={{px:2, py:2, width:'520px'}}>
      <Grid container spacing={2}>
        <Grid item zeroMinWidth sx={{mt:-0.5}} >
          <Tooltip title="Fechar Edição" >
            <IconButton onClick={closeDrawer} >
              <KeyboardTabIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      
        <Grid item xs={true} >
          <Typography variant="h6">Detalhes da tarefa</Typography>
        </Grid>
        <Grid item zeroMinWidth sx={{mt:-0.5}} >
          <Tooltip title="Remover Tarefa" >
            <IconButton onClick={() => removeTaskPlan(taskPlanEdit?.id_task_plan)} >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>      
      <Divider />
      <Grid container spacing={2} sx={{mt:1}}>
        <Grid item xs={12}>
          <EASInput
             // isLoading={LoagindDetailings}
              onFocus={() => 
                  setTaskPlanError(existingValues => ({
                  ...existingValues,
                  title_task_plan: '',
                  }))
              }
              label="Título da Tarefa"
              fullWidth
              type='text'
              value={taskPlanEdit?.title_task_plan}                                
              onChange={(e) => 
                setTaskPlanEdit(existingValues => ({
                      ...existingValues,
                      title_task_plan: e.target.value,
                  }))
              }
              error={!!taskPlanError?.title_task_plan}
              helperText={taskPlanError?.title_task_plan}
          />
        </Grid>
        <Grid item xs={12}>
          <EASInput
             // isLoading={LoagindDetailings}
              onFocus={() => 
                  setTaskPlanError(existingValues => ({
                  ...existingValues,
                  text_task_plan: '',
                  }))
              }
              label="Tarefa"
              multiline
              fullWidth
              rows={10}
              type='text'
              value={taskPlanEdit?.text_task_plan}                                
              onChange={(e) => 
                setTaskPlanEdit(existingValues => ({
                      ...existingValues,
                      text_task_plan: e.target.value,
                  }))
              }
              error={!!taskPlanError?.text_task_plan}
              helperText={taskPlanError?.text_task_plan}
          />
        </Grid>
        <Grid item xs={12} >
          <EASRadio
              label="Situação Atual"
             // isLoading={}
              options={taskPlanStatus}
              value={taskPlanEdit?.status_task_plan}
              onChange={(e) => 
                  setTaskPlanEdit(existingValues => ({
                    ...existingValues,
                    status_task_plan: e.target.value,
                }))
              }
          />
        </Grid> 
        <Grid item xs={6}>
          <EASPicker 
           // isLoading={LoagindDetailings}
            onFocus={() => 
              setTaskPlanError(existingValues => ({
                ...existingValues,
                date_task_plan: '',
                }))
            }
            onOpen={() => 
              setTaskPlanError(existingValues => ({
                ...existingValues,
                date_task_plan: '',
                }))
            }
            label="Previsão de Execução"
            value={taskPlanEdit?.date_task_plan} 
            error={!!taskPlanError?.date_task_plan}
            helperText={taskPlanError?.date_task_plan}
            onChangePiker={(e) => {  
                                if(e instanceof Date) {         
                                  setTaskPlanEdit(existingValues => ({
                                        ...existingValues,
                                        date_task_plan: e.toISOString().split('T')[0],
                                    }));
                                }}
                            }
            />
        </Grid>
        <Grid item xs={6}>
          <EASPicker 
          //  isLoading={LoagindDetailings }
            onFocus={() => 
              setTaskPlanError(existingValues => ({
                ...existingValues,
                forecast_task_plan: '',
                }))
            }
            onOpen={() => 
              setTaskPlanError(existingValues => ({
                ...existingValues,
                forecast_task_plan: '',
                }))
            }
            label="Previsão para Conclusão"
            value={taskPlanEdit?.forecast_task_plan} 
            error={!!taskPlanError?.forecast_task_plan}
            helperText={taskPlanError?.forecast_task_plan}
            onChangePiker={(e) => {  
                                if(e instanceof Date) {         
                                  setTaskPlanEdit(existingValues => ({
                                        ...existingValues,
                                        forecast_task_plan: e.toISOString().split('T')[0],
                                    }));
                                }}
                            }
            />
        </Grid>
        <Grid item xs={6}>
          <EASPicker 
          //  isLoading={LoagindDetailings }
            
            disabled={!((taskPlanEdit?.status_task_plan == '2' || taskPlanEdit?.status_task_plan == '3') )} 
            onFocus={() => 
              setTaskPlanError(existingValues => ({
                ...existingValues,
                execute_task_plan: '',
                }))
            }
            onOpen={() => 
              setTaskPlanError(existingValues => ({
                ...existingValues,
                execute_task_plan: '',
                }))
            }
            label="Inicio da Execução"
            value={(taskPlanEdit?.status_task_plan == '2' || taskPlanEdit?.status_task_plan == '3') && taskPlanEdit?.execute_task_plan} 
            error={!!taskPlanError?.execute_task_plan}
            helperText={taskPlanError?.execute_task_plan}
            onChangePiker={(e) => {  
                                if(e instanceof Date) {         
                                  setTaskPlanEdit(existingValues => ({
                                        ...existingValues,
                                        execute_task_plan: e.toISOString().split('T')[0],
                                    }));
                                }}
                            }
            />
        </Grid>
        <Grid item xs={6}>
          <EASPicker 
          //  isLoading={LoagindDetailings }
            disabled={!(taskPlanEdit?.status_task_plan == '2')}
            onFocus={() => 
              setTaskPlanError(existingValues => ({
                ...existingValues,
                finish_task_plan: '',
                }))
            }
            onOpen={() => 
              setTaskPlanError(existingValues => ({
                ...existingValues,
                finish_task_plan: '',
                }))
            }
            label="Data da Conclusão"
            value={taskPlanEdit?.finish_task_plan} 
            error={!!taskPlanError?.finish_task_plan}
            helperText={taskPlanError?.finish_task_plan}
            onChangePiker={(e) => {  
                                if(e instanceof Date) {         
                                  setTaskPlanEdit(existingValues => ({
                                        ...existingValues,
                                        finish_task_plan: e.toISOString().split('T')[0],
                                    }));
                                }}
                            }
            />
        </Grid>

        <Grid item xs={12}>
          <EASSelect 
           //isLoading={LoagindDetailings}
            options={detailings}
            label="Vincular a um Comportamento"
            getOptionLabel={(option) => option.text}
            value={detailings.find(item => item.value == taskPlanEdit?.id_objective_detail)}                                  
            onChangeSelect={(event, value:IList) => {
                                const valueDetail = (value && value.value) ? value.value : '';
                                setTaskPlanEdit(existingValues => ({
                                    ...existingValues,
                                    id_objective_detail: valueDetail,
                                }));
                            }}  
              
            error={!!taskPlanError?.id_objective_detail}
            helperText={taskPlanError?.id_objective_detail}               
            />
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={2} direction="row" sx={{float:'right'}}>
            <EASButton variant="outlined" onClick={closeDrawer} disabled={saveLoading}>Fechar Janela</EASButton>
            <EASButton 
                variant='contained'
                disabled={saveLoading}
                endIcon={saveLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <CheckIcon/>}
                onClick={dialogSave}>Salvar Alterações
            </EASButton>
          </Stack>
        </Grid>
      </Grid>    
    </Box>

  );
};
