import React, { Ref, useState, useImperativeHandle } from 'react';
import { Grid, CircularProgress, Box, Link } from '@mui/material';

import { useSnackbar } from 'notistack';

import * as yup from 'yup';

import { PeopleNewPerson } from '../people/PeopleNewPerson';

import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

import { IProgram, IList } from '../../shared/types';

import { IHumanResource } from '../../shared/contexts/HumanResourcesContext/types';

import { useAuthContext } from '../../shared/contexts';

export interface IRefHumanResourceInfo {
  handleNextInfo: () => void;
}

type IHumanResourceInfoProps = {
  HumanResource: IHumanResource;
  isLoading:boolean;
  tags?:Array<IList>;
  setHumanResourceUpdate:(humanresource : IHumanResource) => void;
  setActiveStep:() => void
};

const HumanResourceInfo = React.forwardRef<IRefHumanResourceInfo, IHumanResourceInfoProps>(({HumanResource, tags, isLoading, setHumanResourceUpdate, setActiveStep}:IHumanResourceInfoProps, ref:Ref<IRefHumanResourceInfo>) => {

  const { enqueueSnackbar } = useSnackbar();
    
  const [saveLoading, setSaveLoading] = useState(false);

  const { user } = useAuthContext();
  const [openNewPerson, setOpenNewPerson] = useState(false);

  const [program, setProgram] = useState<IProgram>({});
  const [programError, setProgramError] = useState<IProgram | null>(null);
  const [programTypes, setProgramTypes] = useState<Array<IList>>([]);

  useImperativeHandle(ref, () => ({
    handleNextInfo,
  }));

  const isValidProgram = yup.object({
    name_program: yup.string().required('Este campo é obrigatório'),
    id_module: yup.string().required('Este campo é obrigatório'),
    start_program: yup.string().required('Este campo é obrigatório'),
    close_program: yup.string().required('Este campo é obrigatório')
});

const handleNextInfo = () => {
    isValidProgram
    .validate( program , { abortEarly: false })
    .then(validateProgram => { 
        setSaveLoading(true);
       // if(validateProgram) {
            const newProgram: IProgram = {
                status_program: '1',
                start_program: validateProgram.start_program,
                close_program: validateProgram.close_program,
                name_program: validateProgram.name_program,
                description_program: program?.description_program,
                id_module: validateProgram.id_module,
                mentor_program: program?.mentor_program,
                enroll_program: program?.enroll_program,
                user_register: user?.id,
            };            

/*
            ProgramsService.createProgram(newProgram)
            .then((ProgramResponse) => {
                if (ProgramResponse instanceof Error) {
                    setSaveLoading(false);
                    enqueueSnackbar('Erro ao cadastrar programa.', { variant:'error'});
                } else {*/

                    /*setHumanResourceUpdate(createNewHumanResource);
                    setActiveStep();*/
                    enqueueSnackbar('Programa cadastrado com sucesso!', { variant:'success'});
                    setSaveLoading(false);
            /*    }
            }).catch(() => {
                setSaveLoading(false);
                enqueueSnackbar('Erro ao cadastrar programa.', { variant:'error'});
            });*/
       /* }
        else{
            setSaveLoading(false);
            enqueueSnackbar('Erro ao cadastrar programa.', { variant:'error'});
        }*/
    })
    .catch((errors: yup.ValidationError) => {
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setProgramError(existingValues => ({
                                    ...existingValues,
                                    [keyError]: error.message
                                }));                        
            }
        });
    });
  };

  return (
    <>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        {saveLoading && (                   
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress variant='indeterminate' />
            </Box>
        )}
        {!saveLoading && (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <EASInput
                                isLoading={isLoading}
                                onFocus={() => 
                                    setProgramError(existingValues => ({
                                    ...existingValues,
                                    name_program: '',
                                    }))
                                }
                                label="Nome"
                                type="text"
                                value={program?.name_program}
                                onChange={(e) => 
                                    setProgram(existingValues => ({
                                        ...existingValues,
                                        name_program: e.target.value,
                                    }))
                                }
                                error={!!programError?.name_program}
                                helperText={programError?.name_program}
                            />
                        </Grid>
                        <Grid item xs={12}> 
                            <EASInput
                                isLoading={isLoading}
                                multiline={true}
                                onFocus={() => 
                                    setProgramError(existingValues => ({
                                    ...existingValues,
                                    description_program: '',
                                    }))
                                }
                                label="Descrição"
                                type='text'
                                rows={3}
                                value={program?.description_program}                                
                                onChange={(e) => 
                                    setProgram(existingValues => ({
                                        ...existingValues,
                                        description_program: e.target.value,
                                    }))
                                }
                                error={!!programError?.description_program}
                                helperText={programError?.description_program}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <EASSelect 
                                isLoading={isLoading}
                                options={programTypes}
                                label="Tipo"
                                getOptionLabel={(option) => option.text}
                                value={programTypes.find(item => item.value == program?.id_module)}                                  
                                onChangeSelect={(event, value:IList) => {
                                    if (value && value.value) {
                                        setProgram(existingValues => ({
                                            ...existingValues,
                                            id_module: value.value,
                                        }));
                                    }
                                }
                                }  
                                error={!!programError?.id_module}
                                helperText={programError?.id_module}
                                />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <EASRadio
                                label="Ativar Inscrições"
                                isLoading={isLoading}
                                options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
                                value={program?.enroll_program}
                                onChange={(e) => 
                                    setProgram(existingValues => ({
                                        ...existingValues,
                                        enroll_program: e.target.value,
                                    }))
                                }
                            />
                        </Grid> 
                        <Grid item xs={12}>
                            {/*
                            <EASSelect 
                                isLoading={isLoading}
                                options={people}
                                label="Pré definir Mentor"
                                getOptionLabel={(option) => option.text}
                                renderOption={(props, option) => (
                                        <ListItem {...props}>
                                            <ListItemAvatar>
                                                <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                            </ListItemAvatar>
                                            <ListItemText primary={option.text} />
                                        </ListItem>
                                )}
                                value={people.find(item => item.value == program?.mentor_program)}                                  
                                onChangeSelect={(event, value:IList) => {
                                        if (value && value.value) {
                                            setProgram(existingValues => ({
                                                ...existingValues,
                                                mentor_program: value.value,
                                            }));
                                        }
                                    }
                                }
                                error={!!programError?.mentor_program}
                                helperText={programError?.mentor_program}
                            />
                            */}
                            <Box sx={{pt:1}} >
                                <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewPerson(true)} underline="none">
                                    Cadastrar nova pessoa
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <EASPicker 
                                isLoading={isLoading}
                                onFocus={() => 
                                    setProgramError(existingValues => ({
                                    ...existingValues,
                                    start_program: '',
                                    }))
                                }
                                onOpen={() => 
                                    setProgramError(existingValues => ({
                                    ...existingValues,
                                    start_program: '',
                                    }))
                                }
                                label="Data de Início"
                                value={program?.start_program} 
                                error={!!programError?.start_program}
                                helperText={programError?.start_program}
                                onChangePiker={(e) => {  
                                                    if(e instanceof Date) {         
                                                    setProgram(existingValues => ({
                                                            ...existingValues,
                                                            start_program: e.toISOString().split('T')[0],
                                                        }));
                                                    }}
                                                }
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <EASPicker 
                                isLoading={isLoading}
                                onFocus={() => 
                                    setProgramError(existingValues => ({
                                    ...existingValues,
                                    close_program: '',
                                    }))
                                }
                                onOpen={() => 
                                    setProgramError(existingValues => ({
                                    ...existingValues,
                                    close_program: '',
                                    }))
                                }
                                label="Data de Término"
                                value={program?.close_program} 
                                error={!!programError?.close_program}
                                helperText={programError?.close_program}
                                onChangePiker={(e) => {  
                                                    if(e instanceof Date) {         
                                                    setProgram(existingValues => ({
                                                            ...existingValues,
                                                            close_program: e.toISOString().split('T')[0],
                                                        }));
                                                    }}
                                                }
                                />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )}
        </Box>
        <PeopleNewPerson open={openNewPerson} setOpen={(e) => setOpenNewPerson(e)}  />
    </>
  );
});

HumanResourceInfo.displayName = 'HumanResourceInfo';

export default HumanResourceInfo;



{/*
<Dialog 
  open={open}
  keepMounted
  onClose={cancel}
  aria-describedby="alert-dialog-slide-description"
  fullWidth
  maxWidth="xs"
>
  <DialogTitle>{'Adicionar Novo Programa'}</DialogTitle>
  <DialogContent>
    {isFetching && (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress variant='indeterminate' />
    </Box>
    )}
    {!isFetching && ( 
      program.id_program ? (

        <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
        <Grid sx={{width:'100%'}} container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                  <Grid item  zeroMinWidth sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>Programa Encontrado</Typography>
                  </Grid>
                  <Grid item xs={true} >
                    <Box sx={{  display: 'flex', alignItems: 'flex-end', flexDirection:'column', textAlign: 'center', marginTop:'5px', marginRight:'10px' }}>
                      {program?.enroll_program == '0' && (
                        <Chip label="Inscrições Fechadas" color="error" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                      )}
                      {program?.enroll_program == '1' && (
                        <Chip label="Inscrições Abertas" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', minHeight:'100px' }}>
                  <Typography variant="caption" sx={{ fontSize: 14 }}>
                    Nome do Programa: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.name_program}</Typography>
                  </Typography>
                  <Typography variant="caption">
                    Início: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.start_program && new Date(program?.start_program).toLocaleString('pt-BR', {dateStyle: 'medium'}) }</Typography>
                  </Typography>
                  <Typography variant="caption">
                    Tipo: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.name_type_program}</Typography>
                  </Typography>
                  {program?.mentor_program ? (
                  <Typography variant="caption">
                    Mentor: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>{program?.mentor_program ? 'Fase 1' : 'Matching'}</Typography>
                  </Typography>
                  ) : (
                  <Typography variant="caption">
                    Fase: <Typography variant="caption" sx={{ fontWeight: 600, fontSize: 14  }}>Matching</Typography>
                  </Typography>
                  )}
                  {program?.name_company && (
                  <Typography variant='caption'>
                    Empresa: <Typography variant='caption' sx={{fontWeight: 600, fontSize: 14 }}>{program?.name_company}</Typography>
                  </Typography>
                  )}

                </Box>
              </Grid>


               // 686661961571206656
              <Grid item xs={12}>
                <EASButton color="primary" variant="outlined" fullWidth> Acessar Programa</EASButton>
                <EASButton color="primary" variant="outlined" fullWidth> Acessar Programa</EASButton>
              </Grid>
             
            </Grid>
          </Grid> 
      </Grid>
    </Box> 
     ) : (
    <Box style={{ justifyContent: 'center', padding:'8px 0 0 8px' }}>
      <Grid sx={{width:'100%'}} container spacing={2}>
        <Grid item xs={12}>
          <EASInput
            //isLoading={isLoading}
            label={'Insira o Código Aqui'}
            multiline
            disabled={isFetching}
            value={codeProgram}                
            onChange={(e) => setCodeProgram(e.target.value)}
            onFocus={() => setCodeProgramError(null)}
            fullWidth
            error={!!codeProgramError}
            helperText={codeProgramError}
          />    
        </Grid> 
      </Grid>
    </Box> 
    ))}
  </DialogContent>
  <DialogActions>

    <EASButton onClick={cancel} disabled={isFetching} variant="outlined">Cancelar</EASButton>

    {program.id_program ? (
    <EASButton 
    variant='contained'
    disabled={isFetching || program?.enroll_program == '0' }


    endIcon={isFetching ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
    onClick={searchCode}>Inscrever-se</EASButton>
    ):(
      <EASButton 
      variant='contained'
      disabled={isFetching}
      endIcon={isFetching ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <SearchIcon/>}
      onClick={searchCode}>Pesquisar</EASButton>
    )}

    <EASButton onClick={dialogSave} disabled={isLoading} variant="contained" autoFocus>Adicionar</EASButton>
    
  </DialogActions>
</Dialog>
*/
}
