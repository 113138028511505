

import { Box } from '@mui/material';
//
import SimpleBarReact from 'simplebar-react';

import 'simplebar-react/dist/simplebar.min.css';
// @mui
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export const StyledRootScrollbar = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

export const StyledScrollbar = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
 // height:'350px',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: theme.palette.primary.main + '70 !important',
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

interface IScrollbarComponentProps  {
    sx?: object;
    children: React.ReactNode;
}
  
export const ScrollbarComponent:React.FC<IScrollbarComponentProps> = ({ children, sx, ...other }) => {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  
    if (isMobile) {
      return (
        <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
          {children}
        </Box>
      );
    }

  
    return (
      <StyledRootScrollbar >
        <StyledScrollbar  clickOnTrack={false} sx={{ overflowX: 'auto', ...sx}} { ...other}>
          {children}
        </StyledScrollbar>
      </StyledRootScrollbar>
    );
    /*
    return (
      <SimpleBarReact>
        {children}
      </SimpleBarReact>
    );*/
  };


