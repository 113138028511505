import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Avatar, Typography, Box, Skeleton, Grid, Card, useTheme, CardContent, Icon, Chip } from '@mui/material';

import { Environment } from '../../shared/environment';

import { useEASThemeContext, useHumanResourcesContext, useMentoringContext } from '../../shared/contexts';
import { EASCard, EASProgressList } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

import { IHumanResource } from '../../shared/contexts/HumanResourcesContext/types';

export const SelectHumanResourceList = (() => {


  const theme = useTheme();

  const { themeName } = useEASThemeContext();
  const { markHumanResource, humanResources, isLoadingHumanResource, humanResourceBuy } = useHumanResourcesContext();
  const { markMentory } = useMentoringContext();

  const navigate = useNavigate();

  const handleSelectHumanResource = (humanresource:IHumanResource) => {
    markMentory(null);
    markHumanResource(humanresource);
    navigate('/pagina-inicial');
  };


  const handleNewHumanResource = (humanresource:IHumanResource) => {
    markMentory(null);
    markHumanResource(humanresource);
    navigate('/novo-modulo-controle-rh');
  };
   
  return (
    <>          
      <EASCard 
        titleCard="Selecionar Programa"
        bgcolorContent={theme.palette.background.default}>
            <Grid container spacing={2}>
            {isLoadingHumanResource && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
                <Grid item xs={12} md={3} >
                  <Skeleton variant="rectangular" sx={{borderRadius:'8px'}} width="100%" height={380} />
                </Grid>
              </Grid>
            </Grid>
            )}
            {!isLoadingHumanResource && (humanResources && humanResources?.map((humanresource) => (
              <Grid key={humanresource?.id_human_resources} item sm={6} md={4} lg={3} >    
                <Card variant="outlined" sx={{p:'24px', borderRadius:'12px' }}>
                  <CardContent >
                    <Grid container spacing={1} sx={{mb:'12px'}}>
                      <Grid item xs={12} sx={{display:'flex', alignItems: 'center', justifyContent: 'center', flexShrink: '0'}}>
                        <Box
                          component="img"
                          sx={{ mx:'auto',mt: 1, width: 100, height: 'auto'}}  
                          alt={humanresource?.name_program} 
                          src={(  themeName === 'light' && humanresource?.image_program 
                                  ? (
                                      (
                                        Environment.AMBIENT == '1' 
                                        ? Environment.URL_CDN 
                                        : Environment.URL_CDN_SANDBOX
                                      ) + 'images/stories/program/medium_' + humanresource?.image_program
                                    ) 
                                  : ( 
                                      themeName !== 'light'  &&  humanresource?.image_dark_program
                                      ? (
                                          (
                                            Environment.AMBIENT == '1' 
                                            ? Environment.URL_CDN 
                                            : Environment.URL_CDN_SANDBOX
                                          ) + 'images/stories/program/medium_' + humanresource?.image_dark_program
                                        ) 
                                      : 
                                        (
                                          '/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'
                                        )
                                    )
                                )} />
                      </Grid>
                      <Grid item xs={12} >
                        <Typography color="primary"component="h6" sx={{textAlign:'center', fontWeight: 400, fontSize: '0.75rem', lineHeight: '1.66'}} >{humanresource?.name_type_program}</Typography>
                      </Grid>
                      <Grid item xs={12} >
                        <Typography component="h4" sx={{textAlign:'center', fontWeight: 500, fontSize: '1.095rem', lineHeight: '1.6rem'}} >{humanresource?.name_program}</Typography>
                      </Grid>
                    </Grid>
                    <EASProgressList
                        dateInit={humanresource?.start_program}
                        dateFinish={humanresource.close_program}
                        progress={humanresource?.progress_program}
                        textProgress={humanresource?.name_progress_program}
                      />   
                  </CardContent> 
                  <EASButton color="primary" disabled={humanresource?.status_program == '0' ?  true : false} variant="outlined" fullWidth onClick={() => handleSelectHumanResource(humanresource)}><OpenInBrowserIcon sx={{mr:1}}/>Acessar Programa</EASButton>
                </Card>
                {/*        
                <Card variant="outlined" sx={{ p:'25px', borderRadius: '8px', border:'none'}}>  
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={true}  sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexDirection: 'column' }}>
                          <Typography variant="h6" sx={{fontSize:'1.1em'}}>{humanresource?.name_program ? humanresource?.name_program : 'Programa não definido'}</Typography>
                        </Grid>
                        <Grid item zeroMinWidth>
                          <Box >
                            {humanresource?.status_human_resources == '2' && (
                              <Chip label="Encerrado" size="small" color="success" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                            )}
                            {humanresource?.status_human_resources != '2' && (
                                <Chip label="Em curso" size="small" color="info" sx={{ fontWeight: '500', letterSpacing: '0.5px' }} />
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                        {humanresource?.name_company ? (
                          <Grid container spacing={2}>
                            <Grid item>
                              <Avatar variant="square" alt={humanresource?.name_company} src={humanresource?.logo_company ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/logo/small_' + humanresource?.logo_company  :  '/'} />
                            </Grid>
                            <Grid item zeroMinWidth sm={true} >
                              <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>Empresa</Typography>
                              <Typography variant="subtitle2">{humanresource?.name_company}</Typography>
                            </Grid>
                          </Grid> 
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="caption">{'Empresa não definida'}</Typography>
                            </Grid> 
                          </Grid>  
                        )} 
                        </Grid> 
                        <Grid item xs={4}>
                          <Typography variant="subtitle2" sx={{textAlign:'center'}}>{humanresource?.name_type_program}</Typography>
                        </Grid> 
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <EASProgressList
                        dateInit={humanresource?.start_program}
                        dateFinish={humanresource.close_program}
                        progress={humanresource?.progress_program}
                        textProgress={humanresource?.name_progress_program}
                      />
                    </Grid> 
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <EASButton 
                            color="primary" 
                            variant="outlined" 
                            startIcon={<OpenInBrowserIcon sx={{pb: '4.3px'}}/>} 
                            onClick={() => handleSelectHumanResource(humanresource)}
                            fullWidth>Acessar Programa
                          </EASButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card> 
                */}   
              </Grid>
             )))}
             {!isLoadingHumanResource && (humanResourceBuy > 0 && (
              <Grid item xs={12} sm={6} lg={4} xl={3}> 
                <Card onClick={() => navigate('/novo-programa')} variant="outlined" sx={{p:'35px', cursor:'pointer', borderRadius: '12px', border:'4px dashed', borderColor:theme.palette.text.secondary, color:theme.palette.text.secondary, background:'transparent'}}>  
                  <CardContent >
                    <Grid container sx={{px:4,flexDirection:'column', display:'flex', alignItems:'center', justifyContent:'center',textTransform:'none'}} >
                      <Grid item xs={12}>
                        <Avatar sx={{width: 80, height: 80, background:theme.palette.background.default}}>
                          <Icon sx={{fontSize: 50,color:theme.palette.text.secondary}}>control_point</Icon>
                        </Avatar>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body1" component="span" sx={{textAlign:'center', fontWeight:600, fontSize: '1.0375rem'}}>Módulo de Controle RH</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" component="span" sx={{textAlign:'center', fontWeight:400, fontSize: '0.9125rem'}}>Crie um novo Programa de Mentoria</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" component="span" sx={{ pt:5, textAlign:'center', fontWeight:200, fontSize: '0.6125rem'}}>{humanResourceBuy + (humanResourceBuy > 1 ? ' licenças' : ' licença')} restantes</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card> 
              </Grid>
            ))} 
            {!isLoadingHumanResource && (!humanResources && humanResourceBuy <= 0) && (
              <Grid item xs={12} > 
                  Nenhum item encontrado 
              </Grid>
            )} 

            </Grid>
      </EASCard>
  </>
  );
});

