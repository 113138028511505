import { useEffect, useState } from 'react';
import { Box,  CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import { useMentoringContext, useAuthContext } from '../../shared/contexts';
import { ITaskPlan } from '../../shared/contexts/TaskPlanContext/types';
import { IList } from '../../shared/types';
import { EASButton, EASInput, EASPicker, EASSelect } from '../../shared/forms';
import AddIcon from '@mui/icons-material/Add';

import * as yup from 'yup';

interface ITaskPlanProps {
  open: boolean;
  Detailings:Array<IList>;
  setOpen: (open:boolean) => void;
  storeTaskPlan:(newTaskPlan?:ITaskPlan) => void;
}

export const TaskPlanNew: React.FC<ITaskPlanProps> = ({ open, setOpen, storeTaskPlan, Detailings }) => {


  const [saveLoading, setSaveLoading] = useState(false);

  const [taskPlan, setTaskPlan] = useState<ITaskPlan>({});
  const [taskPlanError, setTaskPlanError] = useState<ITaskPlan | null>(null);

  const { mentory } = useMentoringContext();
  const { user } = useAuthContext();


  useEffect(() => {
    setTaskPlan({});
    setSaveLoading(false);
    setTaskPlanError(null);
  }, [open]);

  const handleClose = () => {
    setTaskPlan({});
    setSaveLoading(false);
    setTaskPlanError(null);
    setOpen(false);
  };

  const taskPlanSchema = yup.object().shape({
    title_task_plan: yup.string().required('Informe a título da tarefa'),
    text_task_plan: yup.string().required('Descreva a tarefa'),
    date_task_plan: yup.string().required('Informe uma data para a execução'),
    forecast_task_plan: yup.string().required('Informe uma data de conclusão')
  });
  
  const handleButtonClick = () => {

    taskPlanSchema
      .validate(taskPlan, { abortEarly: false })
      .then(validateValues => {
        setSaveLoading(true);
        const newTaskPlan: ITaskPlan = {
          id_task_plan: '',
          id_mentory: mentory?.id_mentory,
          status_task_plan: '1',
          title_task_plan: validateValues.title_task_plan,
          text_task_plan: validateValues.text_task_plan,
          date_task_plan: validateValues.date_task_plan,
          forecast_task_plan: validateValues.forecast_task_plan,
          unread_task_plan: (user?.id === mentory?.id_user_mentorado ? '0' : '1'),
          id_objective_detail: taskPlan?.id_objective_detail,
          user_register: user?.id,
          presentation_task_plan: '0',
        }; 
        storeTaskPlan(newTaskPlan);
      })
      .catch((errors: yup.ValidationError) => {
        setSaveLoading(false);
        errors.inner.forEach(error => {
            if(error.path){
                const keyError = error.path;                    
                setTaskPlanError(existingValues => ({
                                    ...existingValues,
                                    [keyError]: error.message
                                }));                        
            }
        });
    });
  };


  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>Cadastrar Nova Tarefa</DialogTitle>
        <Divider/>
        <DialogContent>
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {saveLoading && (                   
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress variant='indeterminate' />
                </Box>
            )}
            {!saveLoading && (
            <Grid container spacing={2} >
                <Grid item xs={12}>
                  <EASInput
                    // isLoading={LoagindDetailings}
                      onFocus={() => 
                          setTaskPlanError(existingValues => ({
                          ...existingValues,
                          title_task_plan: '',
                          }))
                      }
                      label="Título da Tarefa"
                      fullWidth
                      type='text'
                      value={taskPlan?.title_task_plan}                                
                      onChange={(e) => 
                        setTaskPlan(existingValues => ({
                              ...existingValues,
                              title_task_plan: e.target.value,
                          }))
                      }
                      error={!!taskPlanError?.title_task_plan}
                      helperText={taskPlanError?.title_task_plan}
                  />
              </Grid>
              <Grid item xs={12}>
                <EASInput
                   // isLoading={isLoading}
                    onFocus={() => 
                        setTaskPlanError(existingValues => ({
                        ...existingValues,
                        text_task_plan: '',
                        }))
                    }
                    label="Tarefa"
                    multiline
                    fullWidth
                    rows={5}
                    type='text'
                    value={taskPlan?.text_task_plan}                                
                    onChange={(e) => 
                      setTaskPlan(existingValues => ({
                            ...existingValues,
                            text_task_plan: e.target.value,
                        }))
                    }
                    error={!!taskPlanError?.text_task_plan}
                    helperText={taskPlanError?.text_task_plan}
                />
              </Grid>
              <Grid item xs={6}>
                <EASPicker 
                  //isLoading={isLoading}
                  onFocus={() => 
                    setTaskPlanError(existingValues => ({
                      ...existingValues,
                      date_task_plan: '',
                      }))
                  }
                  onOpen={() => 
                    setTaskPlanError(existingValues => ({
                      ...existingValues,
                      date_task_plan: '',
                      }))
                  }
                  label="Previsão de Execução"
                  value={taskPlan?.date_task_plan} 
                  error={!!taskPlanError?.date_task_plan}
                  helperText={taskPlanError?.date_task_plan}
                  onChangePiker={(e) => {  
                                      if(e instanceof Date) {         
                                        setTaskPlan(existingValues => ({
                                              ...existingValues,
                                              date_task_plan: e.toISOString().split('T')[0],
                                          }));
                                      }}
                                  }
                  />
              </Grid>
              <Grid item xs={6}>
                <EASPicker 
                 // isLoading={isLoading}
                  onFocus={() => 
                    setTaskPlanError(existingValues => ({
                      ...existingValues,
                      forecast_task_plan: '',
                      }))
                  }
                  onOpen={() => 
                    setTaskPlanError(existingValues => ({
                      ...existingValues,
                      forecast_task_plan: '',
                      }))
                  }
                  label="Previsão para Conclusão"
                  value={taskPlan?.forecast_task_plan} 
                  error={!!taskPlanError?.forecast_task_plan}
                  helperText={taskPlanError?.forecast_task_plan}
                  onChangePiker={(e) => {  
                                      if(e instanceof Date) {         
                                        setTaskPlan(existingValues => ({
                                              ...existingValues,
                                              forecast_task_plan: e.toISOString().split('T')[0],
                                          }));
                                      }}
                                  }
                  />
              </Grid>



              
              <Grid item xs={12}>
                <EASSelect 
                   // isLoading={isLoading}
                    options={Detailings}
                    label="Vincular a um Comportamento"
                    getOptionLabel={(option) => option.text}
                    value={Detailings.find(item => item.value == taskPlan?.id_objective_detail)}                                  
                    onChangeSelect={(event, value:IList) => {
                                        if (value && value.value) {
                                          setTaskPlan(existingValues => ({
                                                ...existingValues,
                                                id_objective_detail: value.value,
                                            }));
                                        }
                                    }}  
                     
                    error={!!taskPlanError?.id_objective_detail}
                    helperText={taskPlanError?.id_objective_detail}               
                    />
                </Grid>
            </Grid>
          )}
          </Box>            
        </DialogContent>
        <Divider/>
        <DialogActions>
            <EASButton variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</EASButton>
            <EASButton 
                  //isLoading={isLoading}
                  variant='contained'
                  disabled={saveLoading}
                  endIcon={saveLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : <AddIcon/>}
                  onClick={handleButtonClick}>Inserir Tarefa</EASButton>
        </DialogActions>
    </Dialog>   
  );
};
