import React, { Ref, useEffect, useState, useImperativeHandle } from 'react';
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, Skeleton, Typography, styled } from '@mui/material';

import { useAuthContext, useMentoringContext } from '../../shared/contexts';
import { IMeeting } from '../../shared/types';
import { UtilService } from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
import { MeetingsService } from '../../shared/services/api/meetings/MeetingsService';
import { useSnackbar } from 'notistack';
import { EASInput, EASDateTimePicker, EASSelect } from '../../shared/forms';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

export interface IRefMeeting {
  saveMeeting: () => void;
}

type IMeetingEditProps = {
  MeetingID: string;
};

const MeetingEdit = React.forwardRef<IRefMeeting, IMeetingEditProps>(({MeetingID}:IMeetingEditProps, ref:Ref<IRefMeeting>) => {
  const { mentory } = useMentoringContext();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [meeting, setMeeting] = useState<IMeeting | null>(null);
  const [meetingError, setMeetingError] = useState<IMeeting | null>(null);

  const [durations, setDurations] = useState<Array<IList>>([]);
  const [typesMeeting, setTypesMeeting] = useState<Array<IList>>([]);

  const { isError, isFetching } = useQuery(['meeting-item', MeetingID],() => MeetingsService.getMeeting(MeetingID), {
    staleTime: Infinity,
    onSuccess: data => {
      setMeetingError(null);
      setMeeting(data);
      
    },
    onError: () => {
      setMeeting(null);
      setMeetingError(null);
      enqueueSnackbar('Erro ao carregar dados da sessão. Feche e acesse novamente', { variant:'error'});
    },
  });


  useEffect(() => {
    async function getDurations() {
      const durationsResponse = await UtilService.getDuration();
      setDurations(durationsResponse);
    }
    async function getTypeMeeting() {
      const typesMeetingResponse = await UtilService.getTypeMeeting();
      setTypesMeeting(typesMeetingResponse);
    }
    getDurations().then(() => {
      getTypeMeeting();
    }); 
  }, []);

  const queryClient = useQueryClient();

  const meetingSchema = yup.object().shape({
    subject_meeting: yup.string().required('Informe um assunto'),
    date_meeting: yup.string().required('Informe uma data'),
    duration_meeting: yup.string().required('Informe a duração')
  });

  
  const { mutate } = useMutation((Meeting:IMeeting) => MeetingsService.updateMeeting(Meeting), {
    onSuccess: data => {
      queryClient.invalidateQueries('meeting-item');
      queryClient.invalidateQueries('meeting-list');
      enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});   
    },
    onError: () => {
      enqueueSnackbar('Erro ao salvar sessão.', { variant:'error'});
    },
  });

  useImperativeHandle(ref, () => ({
    saveMeeting,
  }));

  const saveMeeting = () => {
      meetingSchema
        .validate( meeting , { abortEarly: false })
        .then(validateMeeting => {          
          const updateMeeting: IMeeting = {
            id_meeting: meeting?.id_meeting,
            date_meeting: new Date(validateMeeting?.date_meeting).toLocaleString('sv-SE',{timeZone:'America/Sao_Paulo',dateStyle:'short', timeStyle: 'short'}),
            duration_meeting: validateMeeting?.duration_meeting,
            subject_meeting: validateMeeting?.subject_meeting,
            relatory_meeting: meeting?.relatory_meeting,
            lessons_meeting: meeting?.lessons_meeting,
            homework_meeting: meeting?.homework_meeting,
            type_meeting: meeting?.type_meeting,
            local_meeting: meeting?.local_meeting,
            id_mentory: meeting?.id_mentory,
            user_update: user?.id,
          };  

          if(user?.id === mentory?.id_user_mentor)
            updateMeeting.annotation_mentor_meeting = meeting?.annotation_mentor_meeting;

          if(user?.id === mentory?.id_user_mentorado)
            updateMeeting.annotation_mentorado_meeting = meeting?.annotation_mentorado_meeting;

          mutate(updateMeeting);
        })
        .catch((errors: yup.ValidationError) => {
            errors.inner.forEach(error => {
                if(error.path){
                    const keyError = error.path;                    
                    setMeetingError(existingValues => ({
                        ...existingValues,
                        [keyError]: error.message
                    }));                        
                }
            });
        });
};

const colorMeeting = ['',
'#37A2DA', '#8378EA', '#7289ab', '#3366E6', '#ff9f7f', '#fb7293',
'#E062AE', '#E690D1', '#d48265', '#ca8622', '#B34D4D', '#c23531',
'#4D8000', '#749f83', '#991AFF', '#B34D4D', '#4D8000', '#c23531',
'#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622',
'#bda29a', '#6e7074', '#546570', '#c4ccd3', '#759aa0', '#e69d87',
'#8dc1a9', '#ea7e53', '#eedd78', '#73a373', '#73b9bc', '#7289ab',
'#91ca8c', '#f49f42'];




  return (
    <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
            <CardHeader
              title='Informações da Sessão'
              titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
            />
            <Divider/>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  {isFetching && (
                    <>
                      <Skeleton variant="rectangular" width="40%"  height="30px" sx={{marginBottom:'5px'}}/>
                      <Skeleton variant="rectangular" width="60%"  height="15px"/>
                    </>
                  )}
                  {!isFetching && (
                    <>
                      <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>Sessão</Typography>
                      <Avatar sx={{ width: 20, height: 20, padding: '8px', bgcolor: colorMeeting[meeting?.ordering_meeting ? parseInt(meeting?.ordering_meeting) : 0] }} alt={meeting?.ordering_meeting}>
                        {meeting?.ordering_meeting}
                      </Avatar>
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <EASInput 
                    isLoading={isFetching}
                    label="Tema da Sessão"
                    value={meeting?.subject_meeting} 
                    onChange={(event) => setMeeting(existingValues => ({
                                                    ...existingValues,
                                                    subject_meeting: event.target.value,
                                                  }))
                            }  
                    onFocus={() => setMeetingError(existingValues => ({
                                                    ...existingValues,
                                                    subject_meeting: '',
                                                }))
                            }
                    error={!!meetingError?.subject_meeting}
                    helperText={meetingError?.subject_meeting}
                  />  
                </Grid>
                <Grid item xs={12} md={6}>
                  <EASDateTimePicker 
                    isLoading={isFetching}
                    onFocus={() => 
                      setMeetingError(existingValues => ({
                        ...existingValues,
                        date_meeting: '',
                        }))
                    }
                    onOpen={() => 
                      setMeetingError(existingValues => ({
                        ...existingValues,
                        date_meeting: '',
                        }))
                    }
                    label="Data"
                    value={meeting?.date_meeting} 
                    error={!!meetingError?.date_meeting}
                    helperText={meetingError?.date_meeting}
                    onChangePiker={(e) => {  
                      if(e instanceof Date) {  
                        setMeeting(existingValues => ({
                          ...existingValues,
                          date_meeting: e.toISOString(),
                        }));
                      }
                    }}
                    onChange={(e) => {    
                      setMeeting(existingValues => ({
                        ...existingValues,
                        date_meeting:e.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <EASSelect 
                      isLoading={isFetching}
                      options={durations}
                      label="Duração"
                      getOptionLabel={(option) => option.text}
                      value={durations.find(item => item.value == meeting?.duration_meeting)}                                  
                      onChangeSelect={(event, value:IList) => {
                          if (value && value.value) {
                              setMeeting(existingValues => ({
                                  ...existingValues,
                                  duration_meeting: value.value,
                              }));
                          }
                      }
                      }  
                      error={!!meetingError?.duration_meeting}
                      helperText={meetingError?.duration_meeting}
                      />
                </Grid>
                <Grid item xs={12} md={4}>
                  <EASSelect 
                      isLoading={isFetching}
                      options={typesMeeting}
                      label="Tipo"
                      getOptionLabel={(option) => option.text}
                      value={typesMeeting.find(item => item.value == meeting?.type_meeting)}                                  
                      onChangeSelect={(event, value:IList) => {
                          if (value && value.value) {
                              setMeeting(existingValues => ({
                                  ...existingValues,
                                  type_meeting: value.value,
                              }));
                          }
                      }
                      }  
                      error={!!meetingError?.type_meeting}
                      helperText={meetingError?.type_meeting}
                      />
                </Grid>
                <Grid item xs={12} md={8}>
                  <EASInput
                    isLoading={isFetching}
                    label={'Local/Link'}
                    value={meeting?.local_meeting} 
                    onChange={(event) => setMeeting(existingValues => ({
                                                    ...existingValues,
                                                    local_meeting: event.target.value,
                                                  }))
                            }  
                    onFocus={() => setMeetingError(existingValues => ({
                                                    ...existingValues,
                                                    local_meeting: '',
                                                }))
                            }
                    error={!!meetingError?.local_meeting}
                    helperText={meetingError?.local_meeting}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
            <CardHeader
              title='Resultados da Sessão'
              titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
            />
            <Divider/>                
            <CardContent>
              <Grid container spacing={2}>
                { user?.id === mentory?.id_user_mentor && (

                    <Grid item xs={12}>
                      <EASInput
                        isLoading={isFetching}
                        label={'Anotações exclusivas do mentor'}
                        multiline
                        value={meeting?.annotation_mentor_meeting} 
                        onChange={(event) => setMeeting(existingValues => ({
                                                        ...existingValues,
                                                        annotation_mentor_meeting: event.target.value,
                                                      }))
                                }  
                        onFocus={() => setMeetingError(existingValues => ({
                                                        ...existingValues,
                                                        annotation_mentor_meeting: '',
                                                    }))
                                }
                        error={!!meetingError?.annotation_mentor_meeting}
                        helperText={meetingError?.annotation_mentor_meeting}
                        rows={3}
                      />
                    </Grid>

                )}
                { user?.id === mentory?.id_user_mentorado && (

                    <Grid item xs={12}>
                      <EASInput
                        isLoading={isFetching}
                        label={'Anotações exclusivas do mentorado'}
                        multiline
                        value={meeting?.annotation_mentorado_meeting} 
                        onChange={(event) => setMeeting(existingValues => ({
                                                        ...existingValues,
                                                        annotation_mentorado_meeting: event.target.value,
                                                      }))
                                }  
                        onFocus={() => setMeetingError(existingValues => ({
                                                        ...existingValues,
                                                        annotation_mentorado_meeting: '',
                                                    }))
                                }
                        error={!!meetingError?.annotation_mentorado_meeting}
                        helperText={meetingError?.annotation_mentorado_meeting}
                        rows={3}
                      />
                    </Grid>

                )}
                <Grid item xs={12}>
                  <EASInput
                    isLoading={isFetching}
                    label={'Resumo da sessão'}
                    multiline
                    value={meeting?.relatory_meeting} 
                    onChange={(event) => setMeeting(existingValues => ({
                                                    ...existingValues,
                                                    relatory_meeting: event.target.value,
                                                  }))
                            }  
                    onFocus={() => setMeetingError(existingValues => ({
                                                    ...existingValues,
                                                    relatory_meeting: '',
                                                }))
                            }
                    error={!!meetingError?.relatory_meeting}
                    helperText={meetingError?.relatory_meeting}
                    rows={5}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EASInput
                    isLoading={isFetching}
                    label={'Lições aprendidas'}
                    multiline
                    value={meeting?.lessons_meeting} 
                    onChange={(event) => setMeeting(existingValues => ({
                                                    ...existingValues,
                                                    lessons_meeting: event.target.value,
                                                  }))
                            }  
                    onFocus={() => setMeetingError(existingValues => ({
                                                    ...existingValues,
                                                    lessons_meeting: '',
                                                }))
                            }
                    error={!!meetingError?.lessons_meeting}
                    helperText={meetingError?.lessons_meeting}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EASInput
                    isLoading={isFetching}
                    label={'Tarefas para o próximo encontro'}
                    multiline
                    value={meeting?.homework_meeting} 
                    onChange={(event) => setMeeting(existingValues => ({
                                                    ...existingValues,
                                                    homework_meeting: event.target.value,
                                                  }))
                            }  
                    onFocus={() => setMeetingError(existingValues => ({
                                                    ...existingValues,
                                                    homework_meeting: '',
                                                }))
                            }
                    error={!!meetingError?.homework_meeting}
                    helperText={meetingError?.homework_meeting}
                    rows={3}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
});

MeetingEdit.displayName = 'MeetingEdit';

export default MeetingEdit;
