import { BrowserRouter } from 'react-router-dom';
import { useAuthContext, DrawerProvider, useMentoringContext, useModulesContext, useHumanResourcesContext, useEASThemeContext} from '../shared/contexts';


import { IsAuthSelectModule } from './isAuthSelectModule';
import { NoAuthRoutes } from './noAuthRoutes';
import { IsAuth1Routes } from './isAuth1Routes';
import { IsAuth2Routes } from './isAuth2Routes';
import { IsAuth3Routes } from './isAuth3Routes';
import { IsAuth4Routes } from './isAuth4Routes';
import { IsAuth5Routes } from './isAuth5Routes';
import { IsAuthOperatorsRoutes } from './isAuthOperatorsRoutes';
import { IsAuthSelectMentoring } from './isAuthSelectMentoring';
import { ResetPassword } from './resetPassword';
import { EvaluationExternal } from './evaluationExternal';
import { IsAuthAuraShoping } from './isAuthAuraShoping';
import { IsAuthSelectHumanResource } from './isAuthSelectHumanResource';

import { createTheme, Theme, ThemeProvider} from '@mui/material';

export const AppRoutes = () => {

  const { themeName } = useEASThemeContext();
  const { isAuthenticated, user } = useAuthContext();
  const { isMentorySelected, mentory } = useMentoringContext();
  const { isHumanResourceSelected } = useHumanResourcesContext();
  const { module, isModuleSelected } = useModulesContext();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const evaluation = params.get('evaluation');


                
              /*(
                isSelected === true 
                  ? <IsAuthRoutes/> 
                  :
                    ( user?.groups && user.groups.length > 0 && mentory === null 
                      ? <IsAuthOperatorsRoutes/> 
                      : <SelectMentoring/>
                    ) 
              )*/
  return (
    <DrawerProvider>
      <BrowserRouter>
        { evaluation && evaluation.toString().length > 0 ?
          <EvaluationExternal/>
          :
          (
            isAuthenticated 
            ? 
            (user?.activation === '1' 
              ?
              (<ResetPassword/>)
              :
              ( 
                isModuleSelected === true ? 
                (
              () => {
                switch(module?.id_module) {
                  case '1': 
                  case '2':
                  case '3':
                    return  isMentorySelected ? 
                              (
                                (
                                  mentory?.color_program ||
                                  mentory?.color_dark_program
                                ) ?
                                (
                                  <ThemeProvider theme={(theme: Theme) =>
                                                          createTheme({
                                                          ...theme,
                                                          palette: {
                                                            ...theme.palette,
                                                            primary: {
                                                              main: ( themeName === 'light' ?
                                                                      ( mentory?.color_program ? mentory.color_program : theme.palette.primary.main)
                                                                      :
                                                                      (mentory?.color_dark_program ? mentory.color_dark_program : theme.palette.primary.main)
                                                                    )
                                                            },
                                                            eas: {
                                                              inputBackground: 'eas.inputBackground',
                                                              backgroundTask: 'eas.backgroundTask',
                                                            },
                                                          },
                                                        })}
                                  > 
                                    <IsAuth1Routes/>
                                  </ThemeProvider>
                                ) : (
                                  () => {
                                    switch(module?.id_module) {
                                      case '1': return <IsAuth1Routes/>;
                                      case '2': return <IsAuth2Routes/>;
                                      case '3': return <IsAuth3Routes/>;
                                      default: return <IsAuthSelectModule/>;
                                    }
                                  }
                                )()
                              ) : (
                                <IsAuthSelectMentoring/>
                              );
                  case '4': return <IsAuth4Routes/>;
                  case '5': return isHumanResourceSelected ? <IsAuth5Routes/> : <IsAuthSelectHumanResource/>;
                  case 'admin': return <IsAuthOperatorsRoutes/>;
                  case 'shop': return <IsAuthAuraShoping/>;
                  default: 
                  return <IsAuthSelectModule/>;
              }})()
              :
              <IsAuthSelectModule/>
            )
            )
            : 
            <NoAuthRoutes/>
          )
        }
      </BrowserRouter>
    </DrawerProvider>
  );
};
