import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid, Stack, Avatar, Tooltip, AvatarGroup } from '@mui/material';

import { useNavigate, useParams} from 'react-router-dom';

import { LayoutDefault } from '../../shared/layouts';

import  MentoryResourcesHumanTabDefault, { IRefMentory } from './MentoryResourcesHumanTabDefault';

import { IMentory } from '../../shared/contexts/MentoringContext/types';
import { MentoringService } from '../../shared/services/api/mentoring/MentoringService';
import { UtilService } from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { Environment } from '../../shared/environment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

export const MentoryResourcesHuman = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    
    const { data:programs, isLoading:loadingPrograms  } = useQuery(['programs-list'],() => UtilService.getPrograms(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Programas.', { variant:'error'});
        },
    });

    const { data:tags, isLoading:loadingTags  } = useQuery(['tags-list'],() => UtilService.getTags(),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a lista de Tags.', { variant:'error'});
        },
    });
    
    const { data:mentory, isLoading, isFetching } = useQuery(['operator-mentory-edit', id],() =>  MentoringService.getMentory(id),  {
        staleTime: Infinity,
        onError: () => {
            enqueueSnackbar('Ocorreu um problema carregar a oprocesso de mentoria.', { variant:'error'});
        },
    });
      
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updateMentory:IMentory) =>MentoringService.updateMentory(updateMentory), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-mentory-edit');
            queryClient.invalidateQueries('operator-mentoring-list');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar processo de mentoria.', { variant:'error'});
        },
    });

    const refDisplay = useRef<IRefMentory>(null);
    
    const storeDisplay = () => {
        if(refDisplay.current !== null)
        refDisplay.current.saveMentory();
    };

    return (
        <LayoutDefault >
            <EASCard 
                titleCard={ <Grid container spacing={2}>
                                <Grid item >
                                    <AvatarGroup max={2}>
                                        <Tooltip title={'Mentorado: ' + mentory?.name_mentorado}>
                                            <Avatar sx={{width:80, height:80}}  alt={mentory?.name_mentorado} src={mentory?.image_mentorado ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + mentory?.image_mentorado  :  ''} />
                                        </Tooltip>
                                        <Tooltip title={'Mentor: ' + mentory?.name_mentor}>
                                            <Avatar sx={{ width: 80, height: 80 }} alt={mentory?.name_mentor} src={mentory?.image_mentor ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + mentory?.image_mentor  :  ''} />
                                        </Tooltip>
                                    </AvatarGroup>
                                </Grid>
                                <Grid item zeroMinWidth sm={true}>
                                    <Grid container spacing={0} >
                                        <Grid item xs={12} sx={{mt:0}}>
                                            <Typography variant="caption" sx={{fontSize: '0.65rem', mt:0}}>Mentorado:</Typography>
                                            <Typography component="h6" variant="subtitle2" sx={{fontWeight:500, lineHeight:'0.7em'}}>{mentory?.name_mentorado}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{mt:0.5}}>
                                            <Typography variant="caption" sx={{fontSize: '0.65rem', lineHeight:'0.5em'}}>Mentor:</Typography>
                                            <Typography component="h6" variant="caption" sx={{lineHeight:'0.8em'}}>{mentory?.name_mentor}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                breadcrumbsCard=""
                actionCard={<Stack direction="row" spacing={1}>
                                <EASButton variant="outlined" onClick={() => navigate('/processos')}>Sair</EASButton>
                            </Stack>} >
                <Box sx={{mt: 2}}>
                    <MentoryResourcesHumanTabDefault mentoryEdit={mentory} tags={tags} programs={programs} isLoading={(isFetching || isLoading || loadingTags || loadingPrograms ||isLoadingStore)} storeUpdate={(mentory) => storeUpdate(mentory)} ref={refDisplay} />
                </Box>
            </EASCard>
        </LayoutDefault> 
    );
};
