import React, { useMemo } from 'react';

import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { Box, Skeleton, Grid, Alert, useTheme, Stack } from '@mui/material';
import { VideosService } from '../../shared/services/api/videos/VideosService';
import { EASCard, PaginationComponent } from '../../shared/components';
import { EASInput } from '../../shared/forms';

import { MentoryVideoCard } from './MentoryVideoCard';
import { useQuery } from 'react-query';
import { useMentoringContext } from '../../shared/contexts';

export const MentoryVideosList:React.FC = (() => {
  
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { mentory } = useMentoringContext();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
      return Number(searchParams.get('page') || '1');
  }, [searchParams]);


  const { data, isLoading } = useQuery(['list-video', [page, search, mentory?.id_mentory]],() =>  VideosService.getVideos(page, search, mentory?.id_mentory),  {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de vídeos.', { variant:'error'});
    },
  });

  return (    
      <EASCard 
        bgcolorContent={theme.palette.background.default} 
        titleCard="Vídeos"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}> 
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                    </Stack>}
      >   
        <Grid container spacing={2} sx={{ mb: 3}}> 
          {isLoading && (
            <Grid item sm={12} md={6} lg={3}  sx={{width:'100%'}}>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </Grid>
          )}
          {!isLoading && ((data?.data && data.data.length) ? (data.data.map((video) => (
            <Grid item key={video.id_video} sm={12} md={6} lg={3} sx={{width:'100%'}}>
              <Box >
                {video.id_video && <MentoryVideoCard video={video} /> }
              </Box>
            </Grid>
          ))) : (
            <Alert severity="warning">Nenhum vídeo encontrado!</Alert>
          ))}
        </Grid>
        {!isLoading  && (
          <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
        )}
      </EASCard>
  );
});

