
import React, { useImperativeHandle, useState, Ref } from 'react';
import { Box, Card,CardContent, CardHeader, Grid, Divider, Skeleton, Avatar, Tabs, Tab, ListItem, ListItemAvatar, ListItemText, Link } from '@mui/material';

import { IProgram, IList } from '../../shared/types';
import { useAuthContext } from '../../shared/contexts';


import { EASInput, EASPicker, EASRadio, EASSelect } from '../../shared/forms';

import * as yup from 'yup';

import { Environment } from '../../shared/environment';
import { PeopleNewPerson } from '../people/PeopleNewPerson';
import { CompaniesNewCompany } from '../companies/CompaniesNewCompany';
import { ProgramInvite } from './ProgramInvite';

export interface IRefProfile {
    saveProgram: () => void;
}

type IProgramProfileProps = {
    programEdit?: IProgram;
    programTypes?: Array<IList>;
    companies?: Array<IList>;
    tags?:Array<IList>;
    users?: Array<IList>;
    isLoading?:boolean;
    storeUpdate:(operator:IProgram) => void;
};


interface TabPanelProps {
    children: React.ReactNode;
    index: number;
    value: number;
}
  
  const ProgramTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  };
  
  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };


const ProgramTabProfile = React.forwardRef<IRefProfile, IProgramProfileProps>(({programEdit, isLoading, programTypes, companies, tags, users, storeUpdate}:IProgramProfileProps, ref:Ref<IRefProfile>) => {

    const { user } = useAuthContext();

    const [openNewPerson, setOpenNewPerson] = useState(false);
    const [openNewCompany, setOpenNewCompany] = useState(false);
    const [people, setPeople] = useState<Array<IList>>([]);
    const [program, setProgram] = useState<IProgram | undefined>(programEdit);
    const [programError, setProgramError] = useState<IProgram | null>(null);


    const [valueTab, setValueTab] = React.useState<number>(0);

    const handleChangeValueTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    useImperativeHandle(ref, () => ({
        saveProgram,
    }));

    const isValidProgram = yup.object({
        name_program: yup.string().required('Este campo é obrigatório'),
        id_module: yup.string().required('Este campo é obrigatório'),
        start_program: yup.string().required('Este campo é obrigatório'),
        close_program: yup.string().required('Este campo é obrigatório')
    });
    
    const saveProgram = () => {
        if (!isLoading) {
            isValidProgram
            .validate( program , { abortEarly: false })
            .then(validateProgram => {
                    //setIsLoading(true); 
                const updateProgram: IProgram = {
                    id_program: program?.id_program,
                    start_program: validateProgram.start_program,
                    close_program: validateProgram.close_program,
                    name_program: validateProgram.name_program,
                    description_program: program?.description_program,
                    id_module: validateProgram.id_module,
                    mentor_program: (program?.mentor_program ? program?.mentor_program : 'NULL'),
                    objective_sample_program: program?.objective_sample_program,
                    id_company: (program?.id_company ? program?.id_company : 'NULL'),
                    min_grade_program: program?.min_grade_program,
                    max_grade_program: program?.max_grade_program,
                    id_user_module_rh: (program?.id_user_module_rh ? program?.id_user_module_rh : 'NULL'),
                    //value_program?: string;
                    //discount_program?: string;
                    //plots_program?: string;
                    //start_enroll_program?: string;
                    //close_enroll_program?: string;
                    //enroll_program?: string;
                    //limit_program?: string;
                    //image_program?: string;
                    //color_program: program?.color_program,
                    tags: program?.tags,
                    users: program?.users,
                    user_register: user?.id,
                    user_update: user?.id,
                };           
                storeUpdate(updateProgram);
            })
            .catch((errors: yup.ValidationError) => {
                errors.inner.forEach(error => {
                    if(error.path){
                        const keyError = error.path;                    
                        setProgramError(existingValues => ({
                                            ...existingValues,
                                            [keyError]: error.message
                                        }));                        
                    }
                });
            });
        }
    };



    return (
        <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
            <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                                <CardHeader
                                    title='Propriedade'
                                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                                />
                                <Divider/>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <EASSelect 
                                                isLoading={isLoading}
                                                options={companies ? companies :[] }
                                                label="Empresa"
                                                getOptionLabel={(option) => option.text}
                                                renderOption={(props, option) => (
                                                        <ListItem {...props}>
                                                            <ListItemAvatar>
                                                                <Avatar variant="square" alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/logo/small_' + option?.image  :  '/'} />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={option.text} />
                                                        </ListItem>
                                                )}
                                                value={companies && companies.find(item => item.value == program?.id_company)}                               
                                                onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setProgram(existingValues => ({
                                                                ...existingValues,
                                                                id_company: value.value,
                                                            }));
                                                        } else {
                                                            if(event.target.nodeName == 'svg') {
                                                                setProgram(existingValues => ({
                                                                    ...existingValues,
                                                                    id_company: '',
                                                                }));
                                                            }

                                                        }
                                                    }
                                                }
                                                error={!!programError?.id_company}
                                                helperText={programError?.id_company}
                                            />
                                            <Box sx={{pt:1}} >
                                                <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewCompany(true)} underline="none">
                                                    Cadastrar nova empresa
                                                </Link>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <EASSelect 
                                                isLoading={isLoading}
                                                options={users ? users : []}
                                                label="Gestor Módulo RH"
                                                getOptionLabel={(option) => option.text}
                                                renderOption={(props, option) => (
                                                        <ListItem {...props}>
                                                            <ListItemAvatar>
                                                                <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                                            </ListItemAvatar>
                                                            <ListItemText primary={option.text} />
                                                        </ListItem>
                                                )}                                        
                                                value={users && users.find(item => item.value == program?.id_user_module_rh)}                                  
                                                onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setProgram(existingValues => ({
                                                                ...existingValues,
                                                                id_user_module_rh: value.value,
                                                            }));
                                                        } else {
                                                            if(event.target.nodeName == 'svg') {
                                                                setProgram(existingValues => ({
                                                                    ...existingValues,
                                                                    id_user_module_rh: '',
                                                                }));
                                                            }

                                                        }
                                                    }
                                                }
                                                error={!!programError?.id_user_module_rh}
                                                helperText={programError?.id_user_module_rh}
                                            />
                                            
                                            <Box sx={{pt:1}} >
                                                <Link sx={{cursor:'pointer'}} onClick={() => setOpenNewPerson(true)} underline="none">
                                                    Cadastrar nova pessoa
                                                </Link>
                                            </Box>
                                        </Grid>  
                                    </Grid>       
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} >
                            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                                <CardHeader
                                    title='Administradores'
                                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                                />
                                <Divider/>
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                        <EASSelect 
                                            isLoading={isLoading}
                                            options={users ? users :[]}
                                            label="Usuários autorizados"
                                            getOptionLabel={(option) => option.text}                                  
                                            value={program?.users && users && program.users.map((tag) => users.find(item => item.value == tag))}                                  
                                            onChangeSelect={(event, values:Array<IList>) => {
                                                    const NewValue:Array<string> = [];
                                                    values.map((value) => NewValue.push(value.value));
                                                    setProgram(existingValues => ({
                                                        ...existingValues,
                                                        users: NewValue,
                                                    }));
                                                    } 
                                                } 
                                            fullWidth
                                            multipleSelect
                                            filterSelectedOptions
                                            disableClearable
                                            />
                                        </Grid>  
                                    </Grid>       
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                    <CardHeader
                            sx={{py:'2.5px'}}
                            title={
                        <Tabs variant="scrollable" value={valueTab} onChange={handleChangeValueTab} allowScrollButtonsMobile  textColor='primary' sx={{'& .MuiTabs-indicator': { backgroundColor: 'none', height: 0}}}>
                            <Tab label="Parâmetros" {...a11yProps(0)} sx={{fontSize: 16, }}/>
                            <Tab label="Convites" {...a11yProps(1)} sx={{fontSize: 16}} />
                            <Tab label="Duplas" {...a11yProps(2)} sx={{fontSize: 16}} />
                        </Tabs>}
                    />
                        <Divider/>
                        <CardContent>
                            <ProgramTabPanel value={valueTab} index={0}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    onFocus={() => 
                                                        setProgramError(existingValues => ({
                                                        ...existingValues,
                                                        name_program: '',
                                                        }))
                                                    }
                                                    label="Nome do Programa"
                                                    type="text"
                                                    value={program?.name_program}
                                                    onChange={(e) => 
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            name_program: e.target.value,
                                                        }))
                                                    }
                                                    
                                                    error={!!programError?.name_program}
                                                    helperText={programError?.name_program}
                                                />
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={6} md={3}>
                                                <EASPicker 
                                                    isLoading={isLoading}
                                                    onFocus={() => 
                                                        setProgramError(existingValues => ({
                                                        ...existingValues,
                                                        start_program: '',
                                                        }))
                                                    }
                                                    onOpen={() => 
                                                        setProgramError(existingValues => ({
                                                        ...existingValues,
                                                        start_program: '',
                                                        }))
                                                    }
                                                    label="Data de Início"
                                                    value={program?.start_program} 
                                                    error={!!programError?.start_program}
                                                    helperText={programError?.start_program}
                                                    onChangePiker={(e) => {  
                                                                        if(e instanceof Date) {         
                                                                        setProgram(existingValues => ({
                                                                                ...existingValues,
                                                                                start_program: e.toISOString().split('T')[0],
                                                                            }));
                                                                        }}
                                                                    }
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <EASPicker 
                                                    isLoading={isLoading}
                                                    onFocus={() => 
                                                        setProgramError(existingValues => ({
                                                        ...existingValues,
                                                        close_program: '',
                                                        }))
                                                    }
                                                    onOpen={() => 
                                                        setProgramError(existingValues => ({
                                                        ...existingValues,
                                                        close_program: '',
                                                        }))
                                                    }
                                                    label="Data de Término"
                                                    value={program?.close_program} 
                                                    error={!!programError?.close_program}
                                                    helperText={programError?.close_program}
                                                    onChangePiker={(e) => {  
                                                                        if(e instanceof Date) {         
                                                                        setProgram(existingValues => ({
                                                                                ...existingValues,
                                                                                close_program: e.toISOString().split('T')[0],
                                                                            }));
                                                                        }}
                                                                    }
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <EASInput 
                                                    isLoading={isLoading}
                                                    type="number"
                                                    min="-10"
                                                    max="100"                                                    
                                                    defaultValue="0"
                                                    label="Nota Mínima"
                                                    value={program?.min_grade_program ? program.min_grade_program : '0'}
                                                    onChange={(e) => 
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            min_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                                        }))      
                                                    }
                                                    error={!!programError?.min_grade_program}
                                                    helperText={programError?.min_grade_program}

                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}  md={2}>
                                                <EASInput 
                                                    isLoading={isLoading}
                                                    type="number"
                                                    min="-10"
                                                    max="100"  
                                                    defaultValue="10"
                                                    label="Nota Máxima"
                                                    value={program?.max_grade_program ? program?.max_grade_program : '10'}
                                                    onChange={(e) => 
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            max_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                                        }))      
                                                    }
                                                    error={!!programError?.max_grade_program}
                                                    helperText={programError?.max_grade_program}

                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <EASInput 
                                                    isLoading={isLoading}
                                                    type="number"
                                                    min="0"
                                                    max="10"
                                                    defaultValue="1"
                                                    label="Incremento"
                                                    value={program?.step_grade_program ? program?.step_grade_program : '1'}
                                                    onChange={(e) => 
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            step_grade_program: (parseFloat(e.target.value) > 10 ? '10' : (parseFloat(e.target.value) < 0 ? '0' : e.target.value)),
                                                        }))      
                                                    }
                                                    error={!!programError?.step_grade_program}
                                                    helperText={programError?.step_grade_program}

                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3}>
                                                <EASRadio
                                                    label="Sugerir Comportamentos"
                                                    isLoading={isLoading}
                                                    options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
                                                    value={program?.objective_sample_program}
                                                    onChange={(e) => 
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            objective_sample_program: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Grid> 
                                            <Grid item xs={12} md={6} lg={3}>
                                                <EASSelect 
                                                    isLoading={isLoading}
                                                    defaultValue={null}
                                                    options={programTypes ? programTypes : []}
                                                    label="Tipo"
                                                    getOptionLabel={(option) => option.text}
                                                    value={programTypes && programTypes.find(item => item.value == program?.id_module)}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setProgram(existingValues => ({
                                                                ...existingValues,
                                                                id_module: value.value,
                                                            }));
                                                        }
                                                        
                                                    }
                                                    }  
                                                    error={!!programError?.id_module}
                                                    helperText={programError?.id_module}
                                                    />
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={6}>
                                                <EASSelect 
                                                    isLoading={isLoading}
                                                    options={users ? users : []}
                                                    label="Pré definir Mentor"
                                                    getOptionLabel={(option) => option.text}
                                                    renderOption={(props, option) => (
                                                            <ListItem {...props}>
                                                                <ListItemAvatar>
                                                                    <Avatar alt={option.text} src={option?.image ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + option?.image  :  ''} />
                                                                </ListItemAvatar>
                                                                <ListItemText primary={option.text} />
                                                            </ListItem>
                                                    )}
                                                    value={users && users.find(item => item.value == program?.mentor_program)}                                
                                                    onChangeSelect={(event, value:IList) => {
                                                            if (value && value.value) {
                                                                setProgram(existingValues => ({
                                                                    ...existingValues,
                                                                    mentor_program: value.value,
                                                                }));
                                                            } else {
                                                                if(event.target.nodeName == 'svg') {
                                                                    setProgram(existingValues => ({
                                                                        ...existingValues,
                                                                        mentor_program: '',
                                                                    }));
                                                                }
    
                                                            }
                                                        }
                                                    }
                                                    error={!!programError?.mentor_program}
                                                    helperText={programError?.mentor_program}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EASSelect 
                                                    isLoading={isLoading}
                                                    options={tags ? tags :[]}
                                                    label="Tags"
                                                    getOptionLabel={(option) => option.text}                                  
                                                    value={program?.tags && tags && program.tags.map((tag) => tags.find(item => item.value == tag))}                                  
                                                    onChangeSelect={(event, values:Array<IList>) => {
                                                            const NewValue:Array<string> = [];
                                                            values.map((value) => NewValue.push(value.value));
                                                            setProgram(existingValues => ({
                                                                ...existingValues,
                                                                tags: NewValue,
                                                            }));
                                                            } 
                                                        } 
                                                    fullWidth
                                                    multipleSelect
                                                    filterSelectedOptions
                                                    disableClearable
                                                />
                                            </Grid>
                                            {/*
                                            <Grid item xs={12} sm={4} md={3}>
                                                <EASRadio
                                                    label="Ativar Inscrições"
                                                    isLoading={isLoading}
                                                    options={[{ text: 'Não',value: '0' },{ text: 'Sim',value: '1' }]}
                                                    value={program?.enroll_program}
                                                    onChange={(e) => 
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            enroll_program: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Grid> 
                                            */}
                                            <Grid item xs={12}> 
                                                <EASInput
                                                    isLoading={isLoading}
                                                    multiline={true}
                                                    rows={6}
                                                    onFocus={() => 
                                                        setProgramError(existingValues => ({
                                                        ...existingValues,
                                                        description_program: '',
                                                        }))
                                                    }
                                                    label="Descrição"
                                                    type='text'
                                                    value={program?.description_program}                                
                                                    onChange={(e) => 
                                                        setProgram(existingValues => ({
                                                            ...existingValues,
                                                            description_program: e.target.value,
                                                        }))
                                                    }
                                                    error={!!programError?.description_program}
                                                    helperText={programError?.description_program}
                                                />
                                            </Grid>
                                        </Grid>    
                                    </Grid>
                                </Grid>
                            </ProgramTabPanel>
                            <ProgramTabPanel value={valueTab} index={1}>
                                <ProgramInvite program={program} />
                            </ProgramTabPanel> 
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <PeopleNewPerson open={openNewPerson} setOpen={(e) => setOpenNewPerson(e)} />
            <CompaniesNewCompany open={openNewCompany} setOpen={(e) => setOpenNewCompany(e)} />
            
        </Box>
        
    );
});

ProgramTabProfile.displayName = 'ProgramTabProfile';

export default ProgramTabProfile;
