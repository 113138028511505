
import React, { useImperativeHandle, useEffect, useState, Ref } from 'react';
import { Box, Card,CardContent, CardHeader, Grid, Divider, Tabs, Tab } from '@mui/material';

import { useSnackbar } from 'notistack';

import { useAuthContext } from '../../shared/contexts';

import { EASInput, EASPicker, EASSelect } from '../../shared/forms';

import * as yup from 'yup';
import { UtilService } from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
import { IRating } from '../../shared/contexts/RatingsContext/types';
import { RatingsService } from '../../shared/services/api/ratings/RatingsService';

export interface IRefRating {
    saveRating: () => void;
}

type IRatingInfoProps = {
    RatingID: string;
};

interface RatingInfoProps {
    children: React.ReactNode;
    index: number;
    value: number;
}
  
const RatingInfoPanel = (props: RatingInfoProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            {children}
            </Box>
        )}
        </div>
    );
};
  
const a11yProps = (index: number) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};

const RatingTabInfo = React.forwardRef<IRefRating, IRatingInfoProps>(({RatingID}:IRatingInfoProps, ref:Ref<IRefRating>) => {

    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const [rating, setRating] = useState<IRating | null>(null);
    const [ratingError, setRatingError] = useState<IRating | null>(null);

    const [interval, setInterval] = useState<Array<IList>>([]);
    const [period, setPeriod] = useState<Array<IList>>([]);
    const [repeat, setRepeat] = useState<Array<IList>>([]);

    const [isLoadingCidade, setIsLoadingCidade] = useState(false);

    const [open, setOpen] = useState(false);

    const [valueTab, setValueTab] = React.useState<number>(0);

    const handleChangeValueTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
        async function getRating() {
            setIsLoading(true);
            const intervalResponse = await UtilService.getInterval();
            setInterval(intervalResponse);
            const periodResponse = await UtilService.getPeriod();
            setPeriod(periodResponse);
            const repeatResponse = await UtilService.getRepeat();
            setRepeat(repeatResponse);

            const RatingResponse = await RatingsService.getRating(RatingID);
            if (RatingResponse instanceof Error) {
                setRating(null);
                setRatingError(null);
                setIsLoading(false);
            } else {
                if(RatingResponse){
                    const myRating = JSON.parse(JSON.stringify(RatingResponse));
                    setRating(myRating);
                    setRatingError(null);
                    setIsLoading(false);
                }
                else{
                    setRating(null);
                    setRatingError(null);
                    setIsLoading(false);
                }
            }
        }
        getRating();
    }, []);

    useImperativeHandle(ref, () => ({
        saveRating,
    }));


    const isValidRating = yup.object({
        name_rating: yup.string().required('Este campo é obrigatório'),
        question_rating: yup.string().required('Este campo é obrigatório'),
        start_rating: yup.string().required('Este campo é obrigatório'),
        period_rating: yup.string().required('Este campo é obrigatório'),
        repeat_rating: yup.string().required('Este campo é obrigatório')
    });
    
    const saveRating = () => {
        if (!isLoading) {
            isValidRating
            .validate( rating , { abortEarly: false })
            .then(validateRating => {
                if(validateRating) {
                    setIsLoading(true); 
                    const updateRating: IRating = {
                        id_rating: rating?.id_rating,
                        name_rating: validateRating.name_rating,
                        question_rating: validateRating.question_rating,
                        start_rating: validateRating?.start_rating,
                        period_rating: validateRating.period_rating,
                        repeat_rating: validateRating?.repeat_rating,
                        interval_rating: rating?.interval_rating,
                        programs: rating?.programs,
                        limit_rating: rating?.limit_rating,
                        user_update: user?.id,
                    };            
                    RatingsService.updateRating(updateRating)
                    .then((RatingResponse) => {
                        if (RatingResponse instanceof Error) {
                            setIsLoading(false);
                            enqueueSnackbar('Erro ao salvar pesquisa.', { variant:'error'});
                        } else {
                            setIsLoading(false);
                            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
                        }
                    }).catch(() => {
                        setIsLoading(false);
                        enqueueSnackbar('Erro ao salvar pesquisa.', { variant:'error'});
                    });
                }
                else{
                    setIsLoading(false);
                    enqueueSnackbar('Erro ao salvar pesquisa.', { variant:'error'});
                }
            })
            .catch((errors: yup.ValidationError) => {
                setIsLoading(false);
                errors.inner.forEach(error => {
                    if(error.path){
                        const keyError = error.path;                    
                        setRatingError(existingValues => ({
                                            ...existingValues,
                                            [keyError]: error.message
                                        }));                        
                    }
                });
            });
        }
    };

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                    <CardHeader
                            sx={{py:'2.5px'}}
                            title={
                        <Tabs variant="scrollable" value={valueTab} onChange={handleChangeValueTab} allowScrollButtonsMobile  textColor='primary' sx={{'& .MuiTabs-indicator': { backgroundColor: 'none', height: 0}}}>
                            <Tab label="Cadastro" {...a11yProps(0)} sx={{fontSize: 16, }}/>
                            <Tab label="Participantes" {...a11yProps(1)} sx={{fontSize: 16}}/>
                            <Tab label="Resultados" {...a11yProps(2)} sx={{fontSize: 16}}/>
                        </Tabs>}
                        //<CardHeader
                        //    title="Informações da Pessoa"
                       //     titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                        />
                        <Divider/>
                        <CardContent>
                            <RatingInfoPanel value={valueTab} index={0}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={8}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label="Nome da Pesquisa"
                                                    type="text"
                                                    value={rating?.name_rating}  
                                                    onChange={(e) => 
                                                        setRating(existingValues => ({
                                                            ...existingValues,
                                                            name_rating: e.target.value,
                                                        }))
                                                    }
                                                    error={!!ratingError?.name_rating}
                                                    helperText={ratingError?.name_rating}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <EASPicker 
                                                    isLoading={isLoading}
                                                    label="Data de Nascimento"
                                                    value={rating?.start_rating} 
                                                    error={!!ratingError?.start_rating}
                                                    helperText={ratingError?.start_rating}
                                                    onChangePiker={(e) => {  
                                                        if(e instanceof Date) {         
                                                            setRating(existingValues => ({
                                                                ...existingValues,
                                                                start_rating: e.toISOString().split('T')[0],
                                                            }));
                                                        }}
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label="Pergunta"
                                                    type='text'
                                                    value={rating?.question_rating}                                
                                                    onChange={(e) => 
                                                        setRating(existingValues => ({
                                                            ...existingValues,
                                                            question_rating: e.target.value,
                                                        }))
                                                    }
                                                    error={!!ratingError?.question_rating}
                                                    helperText={ratingError?.question_rating}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASSelect
                                                    isLoading={isLoading}
                                                    options={period}
                                                    onFocus={() => 
                                                        setRatingError(existingValues => ({
                                                        ...existingValues,
                                                        period_rating: '',
                                                        }))
                                                    }
                                                    label="Escolha um Período" 
                                                    getOptionLabel={(option) => option.text}
                                                    value={period.find(item => item.value == rating?.period_rating)}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setRating(existingValues => ({
                                                                ...existingValues,
                                                                period_rating: value.value,
                                                            }));
                                                        }
                                                    }
                                                    }  
                                                    error={!!ratingError?.period_rating}
                                                    helperText={ratingError?.period_rating}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASSelect
                                                    isLoading={isLoading}
                                                    options={repeat}
                                                    onFocus={() => 
                                                        setRatingError(existingValues => ({
                                                        ...existingValues,
                                                        repeat_rating: '',
                                                        }))
                                                    }
                                                    label="Aplicar essa Pesquisa" 
                                                    getOptionLabel={(option) => option.text}
                                                    value={repeat.find(item => item.value == rating?.repeat_rating)}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setRating(existingValues => ({
                                                                ...existingValues,
                                                                repeat_rating: value.value,
                                                            }));
                                                        }
                                                    }
                                                    }  
                                                    error={!!ratingError?.repeat_rating}
                                                    helperText={ratingError?.repeat_rating}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASSelect
                                                    isLoading={isLoading}
                                                    options={repeat}
                                                    onFocus={() => 
                                                        setRatingError(existingValues => ({
                                                        ...existingValues,
                                                        limit_rating: '',
                                                        }))
                                                    }
                                                    label="Limite de Tentativas" 
                                                    getOptionLabel={(option) => option.text}
                                                    value={repeat.find(item => item.value == rating?.limit_rating)}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setRating(existingValues => ({
                                                                ...existingValues,
                                                                limit_rating: value.value,
                                                            }));
                                                        }
                                                    }
                                                    }  
                                                    error={!!ratingError?.limit_rating}
                                                    helperText={ratingError?.limit_rating}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASSelect
                                                    isLoading={isLoading}
                                                    options={interval}
                                                    onFocus={() => 
                                                        setRatingError(existingValues => ({
                                                        ...existingValues,
                                                        interval_rating: '',
                                                        }))
                                                    }
                                                    label="Escolha um Intervalo" 
                                                    getOptionLabel={(option) => option.text}
                                                    value={interval.find(item => item.value == rating?.interval_rating)}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setRating(existingValues => ({
                                                                ...existingValues,
                                                                interval_rating: value.value,
                                                            }));
                                                        }
                                                    }
                                                    }  
                                                    error={!!ratingError?.interval_rating}
                                                    helperText={ratingError?.interval_rating}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </RatingInfoPanel>
                            {/*
                            <RatingInfoPanel value={valueTab} index={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                                <EASInput
                                                    mask={'maskTel'}
                                                    isLoading={isLoading}
                                                    label="Telefone Movel"
                                                    type='text'
                                                    value={person?.tel_celular_user}                                
                                                    onChange={(e) => 
                                                        setPerson(existingValues => ({
                                                            ...existingValues,
                                                            tel_celular_user: e.target.value,
                                                        }))
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={4}>
                                                <EASInput
                                                        mask={'maskTel'}
                                                        isLoading={isLoading}
                                                        label="Telefone Residencial"
                                                        type='text'
                                                        value={person?.tel_residencial_user}                                
                                                        onChange={(e) => 
                                                            setPerson(existingValues => ({
                                                                ...existingValues,
                                                                tel_residencial_user: e.target.value,
                                                            }))
                                                        }
                                                    />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} lg={3}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    mask={'maskCEP'}
                                                    label='CEP'
                                                    type='text'
                                                    value={person?.cep_user}                                
                                                    onChange={(e) => 
                                                        setPerson(existingValues => ({
                                                            ...existingValues,
                                                            cep_user: e.target.value,
                                                        }))
                                                    }
                                                    error={!!personError?.cep_user}
                                                    helperText={personError?.cep_user}
                                                />
                                            </Grid>
                                            <Hidden lgDown>
                                                <Grid item sm={6} lg={9}>
                                                </Grid>
                                            </Hidden>

                                            <Grid item xs={8} sm={10}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label='Logradouro'
                                                    type='text'
                                                    value={person?.logradouro_user}                                
                                                    onChange={(e) => 
                                                        setPerson(existingValues => ({
                                                            ...existingValues,
                                                            logradouro_user: e.target.value,
                                                        }))
                                                    }
                                                    error={!!personError?.logradouro_user}
                                                    helperText={personError?.logradouro_user}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={2}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label='Número'
                                                    type='text'
                                                    value={person?.numero_user}                                
                                                    onChange={(e) => 
                                                        setPerson(existingValues => ({
                                                            ...existingValues,
                                                            numero_user: e.target.value,
                                                        }))
                                                    }
                                                    error={!!personError?.numero_user}
                                                    helperText={personError?.numero_user}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label='Complemento'
                                                    type='text'
                                                    value={person?.complemento_user}                                
                                                    onChange={(e) => 
                                                        setPerson(existingValues => ({
                                                            ...existingValues,
                                                            complemento_user: e.target.value,
                                                        }))
                                                    }
                                                    error={!!personError?.complemento_user}
                                                    helperText={personError?.complemento_user}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASInput
                                                    isLoading={isLoading}
                                                    label='Bairro'
                                                    type='text'
                                                    value={person?.bairro_user}                                
                                                    onChange={(e) => 
                                                        setPerson(existingValues => ({
                                                            ...existingValues,
                                                            bairro_user: e.target.value,
                                                        }))
                                                    }
                                                    error={!!personError?.bairro_user}
                                                    helperText={personError?.bairro_user}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASSelect 
                                                    isLoading={isLoading}
                                                    options={estados}
                                                    label="Estado"
                                                    getOptionLabel={(option) => option.text}
                                                    value={estados.find(item => item.value == person?.id_estado)}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setPerson(existingValues => ({
                                                                ...existingValues,
                                                                id_estado: value.value,
                                                            }));
                                                        }
                                                    }
                                                    }  
                                                    error={!!personError?.id_estado}
                                                    helperText={personError?.id_estado}
                                                    />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <EASSelect 
                                                    isLoading={isLoading || isLoadingCidade}
                                                    options={cidades}
                                                    label="Cidade"
                                                    getOptionLabel={(option) => option.text}
                                                    value={cidades.find(item => item.value == person?.id_cidade)}                                  
                                                    onChangeSelect={(event, value:IList) => {
                                                        if (value && value.value) {
                                                            setPerson(existingValues => ({
                                                                ...existingValues,
                                                                id_cidade: value.value,
                                                            }));
                                                        }
                                                    }
                                                    }
                                                    error={!!personError?.id_cidade}
                                                    helperText={personError?.id_cidade}
                                                    />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>                               
                            </RatingInfoPanel>
                            */}
                            <RatingInfoPanel value={valueTab} index={2}>
                                ... em contrução
                            </RatingInfoPanel>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
        
    );
});

RatingTabInfo.displayName = 'RatingTabInfo';

export default RatingTabInfo;
