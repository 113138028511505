
import React, { useState , useEffect } from 'react';


import { Draggable } from 'react-beautiful-dnd';
import { Divider, Avatar, ListItemAvatar,  Box, ListItem, ListItemText } from '@mui/material/';

import { IInfluencer } from '../../contexts/InfluencersContext/types';
import { InfluencersService } from '../../services/api/influencers/InfluencersService';

import { useSnackbar } from 'notistack';
import { EASButtonList } from '../../forms';

interface EASToolsInfluencersListItemProps {
  influencer: IInfluencer;
  index: number;
  setInfluencer:(Influencer : IInfluencer | null) => void;
  handleClickDelete:(id_influencer?: string) => void;
  updateInfluencer:(Influencer : IInfluencer) => void;
}  

export const EASToolsInfluencersListItem: React.FC<EASToolsInfluencersListItemProps> = ({ influencer, index, setInfluencer, handleClickDelete, updateInfluencer }) => {

  const { enqueueSnackbar } = useSnackbar();

  const [itemInfluencer, setItemInfluencer] = useState<IInfluencer>();
  const [valueSider, setValueSider] = useState<number>(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadItemInfluencer() {
      if(influencer){
        setItemInfluencer(influencer);
        if(influencer.distance_influencer){
          setValueSider(parseFloat(influencer.distance_influencer));
        }
        setLoading(false);
      }
    }
    loadItemInfluencer();
  }, []);

  const handleClickEdit = (influencer?:IInfluencer) => {
    if(influencer){
      setInfluencer(influencer);
    }
  };

  const handleClickEditDistanceChart = (newValue?:number | number[]) => {
    if (typeof newValue === 'number' && itemInfluencer) {
      setItemInfluencer({distance_influencer: newValue.toString(),});   
    }
  };

  const handleClickEditDistanceStore = (newValue?:number | number[]) => {
    if (typeof newValue === 'number' && influencer) {
      if(newValue.toString() != influencer.distance_influencer) {
        const Newinfluencer: IInfluencer = {
          id_influencer: influencer.id_influencer,
          id_mentory: influencer.id_mentory,
          name_influencer: influencer.name_influencer,
          order_influencer: influencer.order_influencer,
          positive_influencer: influencer.positive_influencer,
          negative_influencer: influencer.negative_influencer,
          group_influencer: influencer.group_influencer,
          distance_influencer: newValue.toString()
        };   
        InfluencersService.updateInfluencers(Newinfluencer)
        .then((influencerResponse) => {
          if (influencerResponse instanceof Error) {
            enqueueSnackbar('Erro ao salvar distanciamento do Influenciador.', { variant:'error'});
          } 
          else{
           updateInfluencer(Newinfluencer);
          }
        }).catch(() => {
          enqueueSnackbar('Erro ao atualizar Influenciador.', { variant:'error'});
        });
      }
    } 
  };

  return (
    <>
      {!loading && ( 
        <Draggable draggableId={(influencer.id_influencer ? influencer.id_influencer : 'id2')} index={index}>
          {provided => (
            <>            
              <ListItem
                sx={{padding: '10px 5px'}}
                key={influencer.id_influencer}
                ref={provided.innerRef}
                {...provided.draggableProps}
                secondaryAction={
                  <Box style={{ display: 'flex', justifyContent:'flex-end', width:'150px'}}>  
                    <EASButtonList 
                      title="Editar Influenciador"
                      onClick={() => handleClickEdit(influencer)}
                      typeIcon="edit"
                    />
                    <EASButtonList 
                      title="Remover Influenciador"
                      onClick={() => handleClickDelete(influencer.id_influencer)} 
                      typeIcon="remove"
                    />
                  </Box>
                }
              >
                  <div {...provided.dragHandleProps}>
                    <EASButtonList 
                      title="Mover Influenciador"
                      typeIcon="move"
                      sx={{cursor:'grab', mr:'10px'}}
                    />
                  </div>
                  <ListItemAvatar>
                    <Avatar sx={{backgroundColor:'#019DAA'}}>{(index+10).toString(36).toUpperCase()}</Avatar>
                  </ListItemAvatar>
                  <ListItemText  primary={influencer.name_influencer} />
              </ListItem>
              <Divider  component="li" />
            </>
          )}
        </Draggable>
      )}
    </>
  );
};

