import React, { useState, useEffect } from 'react';

import { Alert, Avatar, CircularProgress, Paper, Skeleton, Typography } from '@mui/material';
import { PresentationService } from '../../shared/services/api/presentation/PresentationService';
import { useHumanResourcesContext } from '../../shared/contexts';
import { IPresentation } from '../../shared/types';
import { Environment } from '../../shared/environment';
import { PresentationResourcesHumanFinalComparation } from './PresentationResourcesHumanFinalComparation';
import { PresentationResourcesHumanFinalTaskPlan } from './PresentationResourcesHumanFinalTaskPlan';
import { PresentationResourcesHumanFinalMaintencePlanLesson } from './PresentationResourcesHumanFinalMaintencePlanLesson';

import { useQuery, useQueryClient } from 'react-query';

import {
  FlexBox,
  Heading,
  AnimatedProgress,
  Slide,
  Deck,
  Text,
  Grid,
  Box,
  Quote,
} from 'spectacle';
import { ReportService } from '../../shared/services/api/report/ReportService';
import { PresentationResourcesHumanObjectives } from './PresentationResourcesHumanObjectives';

// SPECTACLE_CLI_THEME_START
const theme = {
  fonts: {
    header: '"Open Sans Condensed", Helvetica, Arial, sans-serif',
    text: '"Open Sans Condensed", Helvetica, Arial, sans-serif'
  },
  backdropStyle: {
    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: 'calc(100vh - 200px)'
  },
  sizes: {
    width: '50%',
    height: '7%',
  },
  letterSpacings: {
    letterSpacings: '5',
  },
};

interface IPresentationResourcesHumanFinalProps {
  toPrint: boolean;
}

export const PresentationResourcesHumanFinal: React.FC<IPresentationResourcesHumanFinalProps> = ({ toPrint }) => {

  const { humanResource } = useHumanResourcesContext();

  const queryClient = useQueryClient();
    
  const template = () => (
    <FlexBox
      justifyContent="space-between"
      position="absolute"
      bottom={0}
      width={1}
    >
      {toPrint === false && (
        <Box padding="1em" margin="auto">
          <AnimatedProgress color="#000000" />
        </Box>
      )}
    </FlexBox>
  );


  const { data:report, isLoading } = useQuery(['report', [humanResource?.id_program]],() =>   ReportService.getReport(humanResource?.id_program), {
    /*staleTime: Infinity,*/
   /* onSuccess: data => {
      if(data?.data && data.data.length && data.data[0]?.id_objective)
        setValueTab(parseInt(data.data[0].id_objective));
    },
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de competências.', { variant:'error'});
    },*/
  });


/*
  useEffect(() => {
    async function getResultsPresentation() {
      if (mentory?.id_mentory) {
        setIsLoading(true);
        const resultsPresentationResponse = await PresentationService.getPresentations(mentory?.id_mentory, 0, 0);
        if (resultsPresentationResponse instanceof Error) {
          setAllResultsPresentation(null);
          // setTotalCount(0);
          setIsLoading(false);
        } else {
          if (resultsPresentationResponse.data && resultsPresentationResponse.total) {
            const myResultsPresentation = JSON.parse(JSON.stringify(resultsPresentationResponse.data));
            setAllResultsPresentation(myResultsPresentation);
            setIsLoading(false);
            // if(resultsPresentationResponse.total)
            // setTotalCount(resultsPresentationResponse.total); 

          }
          else {
            setAllResultsPresentation(null);
            //setTotalCount(0);
            setIsLoading(false);
          }
        }
      }
    }
    getResultsPresentation();
  }, []);
*/




  return (
    <>
      {isLoading && (
        <Skeleton variant="rectangular" sx={{ borderRadius: '8px' }} width="100%" height={610} />
      )}
      {!isLoading && (report?.item ? (
        <>
          <Deck theme={theme} template={template} >
            <Slide backgroundImage="url(/slide-resultados.png)" backgroundColor={report?.item?.color_palette?.background ? report?.item?.color_palette.background : '#FFFFFF'}>
              <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'} position="absolute" bottom="0px" right="0px" width="650px" height="350px"></Box>
              <Box backgroundColor="trasnparent" position="absolute" top="280px" left="42%" width="700px">
                <Text fontWeight="900" fontSize="72px" color="#000000">Apresentação de Resultados</Text>
              </Box>
            </Slide>
            <Slide backgroundColor={report?.item?.color_palette?.background ? report?.item?.color_palette?.background : '#FFFFFF'}>
              <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}
                position="absolute"
                top="280px"
                left="0px"
                width="250px"
                height="150px">
              </Box>
              <Box backgroundColor={report?.item?.color_palette?.variant8 ? report?.item?.color_palette?.variant8 : '#FFFFFF'}
                position="absolute"
                top="0px"
                left="40%"
                width="850px"
                height="1000px">
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="275px" left="35px" width="200px">
                <Text fontWeight="900" fontSize="66px" color="#000000">Dados</Text>
              </Box>

              <Box backgroundColor="trasnparent" position="absolute" top="20px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="36px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Programa
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="70px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color="#000000">
                  {report?.item?.name_program}
                </Text>
              </Box>

              <Box backgroundColor="trasnparent" position="absolute" top="130px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Processos (Duplas)
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="130px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color="#000000">
                  {report?.item?.process_program}
                </Text>
              </Box>

              <Box backgroundColor="trasnparent" position="absolute" top="170px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Mentorados
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="170px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color="#000000">
                  {report?.item?.mentorado_program}
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="210px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Mentores
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="210px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color="#000000">
                  {report?.item?.mentor_program}
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="250px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Total de Participantes
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="250px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="28px"
                  color="#000000">
                  {report?.item?.participants_program}
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="310px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="25px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Processos Finalizados
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="310px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="25px"
                  color="#000000">
                  {report?.item?.process_finished_program}
                </Text>
              </Box>

              <Box backgroundColor="trasnparent" position="absolute" top="345px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="25px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Processos Pendentes
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="345px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="25px"
                  color="#000000">
                  {report?.item?.process_pending_program}
                </Text>
              </Box>

              <Box backgroundColor="trasnparent" position="absolute" top="380px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="25px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Processos Cancelados
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="380px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="25px"
                  color="#000000">
                  {report?.item?.process_canceled_program}
                </Text>
              </Box>

              <Box backgroundColor="trasnparent" position="absolute" top="440px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="22px"
                  color={ report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA' }>
                  Competências Eleitas
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="440px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="22px"
                  color="#000000">
                  {report?.item?.total_objective}
                </Text>
              </Box>

              <Box backgroundColor="trasnparent" position="absolute" top="470px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="22px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA' }>
                  Comportamentos Trabalhados
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="470px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="22px"
                  color="#000000">
                  {report?.item?.total_objective_detail}
                </Text>
              </Box>

              <Box backgroundColor="trasnparent" position="absolute" top="520px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA' }>
                  Sessões Cadastradas
                </Text>
              </Box>
              
              <Box backgroundColor="trasnparent" position="absolute" top="520px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color="#000000">
                  {report?.item?.total_meeting}
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="545px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Tarefas Realizadas
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="545px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color="#000000">
                  {report?.item?.total_task_plan}
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="570px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA' }>
                  Autoavaliações
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="570px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color="#000000">
                  {report?.item?.total_auto_evaluation}
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="595px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Avaliações Externas 
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="595px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color="#000000">
                  {report?.item?.total_external_evaluation}
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="640px" left="42%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}>
                  Avaliação Final 
                </Text>
              </Box>
              <Box backgroundColor="trasnparent" position="absolute" top="640px" left="68%" width="700px">
                <Text fontWeight="700"
                  fontSize="20px"
                  color="#000000">
                  {report?.item?.total_final_evaluation}
                </Text>
              </Box>
            </Slide>

            {(report?.item?.objectives && report?.item?.objectives.length ) && report?.item?.objectives.map((Objective, index) => {
              if (report?.item?.objectives &&
                    (
                      (index > 0 && (index + 1) % 6 == 0)
                      ||
                      (index == report?.item?.objectives.length -1) 
                    )
                  ) 
                {
                  return (<PresentationResourcesHumanObjectives indexKey={index} Key={index}
                    Objectives={report?.item?.objectives.slice((index == report?.item?.objectives.length -1 && !(index > 0 && (index + 1) % 6 == 0) ? index - ((report?.item?.objectives.length -1 ) % 6) : index - 5 ), (index + 1))}
                  
                    primary={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#000000'}
                    background={report?.item?.color_palette?.background ? report?.item?.color_palette?.background : '#000000'}
                  />);
                }
              }
            )}

            {(report?.item?.objectives && report?.item?.objectives.length ) && report?.item?.objectives.map((Objective, index) => {
                if (report?.item?.objectives &&
                  (
                    (index > 0 && (index + 1) % 2 == 0)
                    ||
                    (index == report?.item?.objectives.length - 1)
                  )
                ) {
                  return (<PresentationResourcesHumanFinalComparation indexKey={index} Key={index}
                    Objectives={report?.item?.objectives.slice((index == report?.item?.objectives.length - 1 && !(index > 0 && (index + 1) % 2 == 0) ? index : index - 1), (index + 1))}
                    primary={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#000000'}
                    background={report?.item?.color_palette?.background ? report?.item?.color_palette?.background : '#000000'}
                  />);
                }
              }
            )}


            <Slide backgroundColor={report?.item?.color_palette?.background ? report?.item?.color_palette?.background : '#000000'}>
              <Heading fontWeight="600" fontSize="28px" margin="0px" color="#000000">
                COMPARATIVO DA AVALIAÇÃO INICIAL E FINAL
              </Heading>

              <Grid gridTemplateColumns="4fr 3fr 4fr" gridColumnGap={15} border="solid">
                <Box>
                  <Heading fontWeight="600" fontSize="32px" textAlign="right" padding="35px 0 0 0" color="#000000">
                    AVALIAÇÃO INICIAL
                  </Heading>

                  <Box backgroundColor="#e9e9e9"
                    borderRadius="25px 0 0 25px"
                    margin="60px 0 0 100px"
                    width="400px"
                    height="50px">
                  </Box>
                  <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}
                    position="absolute"
                    borderRadius="25px 0 0 25px"
                    top="252px"
                    left={400 - (report?.item?.total_expectation?.total_excede_as_expectativas_inicial ? report?.item?.total_expectation?.total_excede_as_expectativas_inicial : 0) * 4 + 132 + 'px'}
                    width={(report?.item?.total_expectation?.total_excede_as_expectativas_inicial ? report?.item?.total_expectation?.total_excede_as_expectativas_inicial : 0) * 4 + 'px'}
                    height="50px">
                  </Box>
                  <Box backgroundColor="trasnparent"
                    position="absolute"
                    borderRadius="25px 0 0 25px"
                    top="250px"
                    left={400 - (report?.item?.total_expectation?.total_excede_as_expectativas_inicial && report?.item?.total_expectation?.total_excede_as_expectativas_inicial > 24 ? report?.item?.total_expectation?.total_excede_as_expectativas_inicial : 24) * 4 + 132 + 'px'}
                    width={(report?.item?.total_expectation?.total_excede_as_expectativas_inicial && report?.item?.total_expectation?.total_excede_as_expectativas_inicial > 24 ? report?.item?.total_expectation?.total_excede_as_expectativas_inicial : 24) * 4 + 'px'}
                    height="50px">
                    <Text fontWeight="200"
                      fontSize="22px"
                      margin="auto"
                      textAlign="center"
                      minWidth="50px"
                      color="#000000">
                      {report?.item?.total_expectation?.total_excede_as_expectativas_inicial + '%'}
                    </Text>
                  </Box>


                  <Box backgroundColor="#e9e9e9"
                    borderRadius="25px 0 0 25px"
                    margin="40px 0 0 100px"
                    minWidth="50px"
                    width="400px"
                    height="50px">
                  </Box>
                  <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}
                    position="absolute"
                    borderRadius="25px 0 0 25px"
                    top="342px"
                    left={400 - (report?.item?.total_expectation?.total_atende_as_expectativas_inicial ? report?.item?.total_expectation?.total_atende_as_expectativas_inicial : 0) * 4 + 132 + 'px'}
                    width={(report?.item?.total_expectation?.total_atende_as_expectativas_inicial ? report?.item?.total_expectation?.total_atende_as_expectativas_inicial : 0) * 4 + 'px'}
                    height="50px">
                  </Box>
                  <Box backgroundColor="trasnparent"
                    position="absolute"
                    borderRadius="25px 0 0 25px"
                    top="340px"
                    left={400 - (report?.item?.total_expectation?.total_atende_as_expectativas_inicial && report?.item?.total_expectation?.total_atende_as_expectativas_inicial > 24 ? report?.item?.total_expectation?.total_atende_as_expectativas_inicial : 24) * 4 + 132 + 'px'}
                    width={(report?.item?.total_expectation?.total_atende_as_expectativas_inicial && report?.item?.total_expectation?.total_atende_as_expectativas_inicial > 24 ? report?.item?.total_expectation?.total_atende_as_expectativas_inicial : 24) * 4 + 'px'}
                    height="50px">
                    <Text fontWeight="200"
                      fontSize="22px"
                      margin="auto"
                      textAlign="center"
                      minWidth="50px"
                      color="#000000">
                      {report?.item?.total_expectation?.total_atende_as_expectativas_inicial + '%'}
                    </Text>
                  </Box>


                  <Box backgroundColor="#e9e9e9"
                    borderRadius="25px 0 0 25px"
                    margin="40px 0 0 100px"
                    minWidth="50px"
                    width="400px"
                    height="50px"
                  >
                  </Box>
                  <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}
                    position="absolute"
                    borderRadius="25px 0 0 25px"
                    top="432px"
                    left={400 - (report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial ? report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial : 0) * 4 + 132 + 'px'}
                    width={(report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial ? report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial : 0) * 4 + 'px'}
                    height="50px">
                  </Box>
                  <Box backgroundColor="trasnparent"
                    position="absolute"
                    borderRadius="25px 0 0 25px"
                    top="430px"
                    left={400 - (report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial && report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial > 24 ? report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial : 24) * 4 + 132 + 'px'}
                    width={(report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial && report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial > 24 ? report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial : 24) * 4 + 'px'}
                    minWidth="50px"
                    height="50px">
                    <Text fontWeight="200"
                      fontSize="22px"
                      margin="auto"
                      textAlign="center"
                      minWidth="50px"
                      color="#000000">
                      {report?.item?.total_expectation?.total_abaixo_das_expectativas_inicial + '%'}
                    </Text>
                  </Box>
                  <Box backgroundColor="#e9e9e9"
                    borderRadius="25px 0 0 25px"
                    margin="40px 0 0 100px"
                    minWidth="50px"
                    width="400px"
                    height="50px"
                  >
                  </Box>
                  <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}
                    position="absolute"
                    borderRadius="25px 0 0 25px"
                    top="522px"
                    left={400 - (report?.item?.total_expectation?.total_insatisfatorio_inicial ? report?.item?.total_expectation?.total_insatisfatorio_inicial : 0) * 4 + 132 + 'px'}
                    width={(report?.item?.total_expectation?.total_insatisfatorio_inicial ? report?.item?.total_expectation?.total_insatisfatorio_inicial : 0) * 4 + 'px'}
                    height="50px">
                  </Box>
                  <Box backgroundColor="trasnparent"
                    position="absolute"
                    borderRadius="25px 0 0 25px"
                    top="520px"
                    left={400 - (report?.item?.total_expectation?.total_insatisfatorio_inicial && report?.item?.total_expectation?.total_insatisfatorio_inicial > 24 ? report?.item?.total_expectation?.total_insatisfatorio_inicial : 24) * 4 + 132 + 'px'}
                    width={(report?.item?.total_expectation?.total_insatisfatorio_inicial && report?.item?.total_expectation?.total_insatisfatorio_inicial > 24 ? report?.item?.total_expectation?.total_insatisfatorio_inicial : 24) * 4 + 'px'}
                    height="50px">
                    <Text fontWeight="200"
                      fontSize="22px"
                      margin="auto"
                      textAlign="center"
                      minWidth="50px"
                      color="#000000">
                      {report?.item?.total_expectation?.total_insatisfatorio_inicial + '%'}
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Heading fontWeight="600" fontSize="52px" color="#000000">
                    VS
                  </Heading>
                  <Heading fontWeight="700" fontSize="22px" lineHeight="70px" padding="0px" margin="0px" color="#000000">
                    EXCEDE AS EXPECTATIVAS
                  </Heading>
                  <Heading fontWeight="400" fontSize="12px" lineHeight="40px" padding="0px" margin="0px" color="#000000" style={{ marginTop: '-30px', marginBottom: '15px' }}>
                    (maior ou igual a {(((report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0) - (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10)) * 8 + 0 * (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10) - 10 * (report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0)) / (0 - 10)})
                  </Heading>
                  <Heading fontWeight="700" fontSize="22px" lineHeight="60px" padding="0px" margin="0px" color="#000000">
                    ATENDE AS EXPECTATIVAS
                  </Heading>
                  <Heading fontWeight="400" fontSize="12px" lineHeight="40px" padding="0px" margin="0px" color="#000000" style={{ marginTop: '-25px', marginBottom: '15px' }}>
                    (maior ou igual a {(((report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0)- (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10)) * 7 + 0 * (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10) - 10 * (report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0)) / (0 - 10)} e menor que {(((report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0) - (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10)) * 8 + 0 * (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10) - 10 * (report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0)) / (0 - 10)})
                  </Heading>
                  <Heading fontWeight="700" fontSize="22px" lineHeight="60px" padding="0px" margin="0px" color="#000000">
                    ABAIXO DAS EXPECTATIVAS
                  </Heading>
                  <Heading fontWeight="400" fontSize="12px" lineHeight="40px" padding="0px" margin="0px" color="#000000" style={{ marginTop: '-25px', marginBottom: '15px' }}>
                    (maior ou igual a {(((report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0) - (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10)) * 5 + 0 * (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10) - 10 * (report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0)) / (0 - 10)} e menor que {(((report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0) - (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10)) * 7 + 0 * (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10) - 10 * (report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0)) / (0 - 10)})
                  </Heading>
                  <Heading fontWeight="700" fontSize="22px" lineHeight="60px" padding="0px" margin="0px" color="#000000">
                    INSATISFATÓRIO
                  </Heading>
                  <Heading fontWeight="400" fontSize="12px" lineHeight="40px" padding="0px" margin="0px" color="#000000" style={{ marginTop: '-25px', marginBottom: '15px' }}>
                    (menor que {(((report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0) - (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10)) * 5 + 0 * (report?.item?.max_grade_program ? parseFloat(report?.item.max_grade_program) : 10) - 10 * (report?.item?.min_grade_program ? parseFloat(report?.item.min_grade_program) : 0)) / (0 - 10)})
                  </Heading>
                </Box>
                <Box>
                  <Heading fontWeight="600" fontSize="32px" textAlign="left" padding="35px 0 0 0" color="#000000">
                    AVALIAÇÃO FINAL
                  </Heading>
                  <Box backgroundColor="#e9e9e9"
                    borderRadius="0 25px 25px 0"
                    margin="60px 0 0 0"
                    minWidth="50px"
                    width="400px"
                    height="50px"
                  >
                  </Box>
                  <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}
                    position="absolute"
                    borderRadius="0 25px 25px 0"
                    top="252px"
                    left="893px"
                    width={(report?.item?.total_expectation?.total_excede_as_expectativas_final ? report?.item?.total_expectation?.total_excede_as_expectativas_final : 0) * 4 + 'px'}
                    height="50px">
                  </Box>
                  <Box backgroundColor="trasnparent"
                    position="absolute"
                    borderRadius="0 25px 25px 0"
                    top="250px"
                    left="893px"
                    width={(report?.item?.total_expectation?.total_excede_as_expectativas_final ? report?.item?.total_expectation?.total_excede_as_expectativas_final : 15) * 4 + 'px'}
                    height="50px">
                    <Text fontWeight="200"
                      fontSize="22px"
                      margin="auto"
                      textAlign="center"
                      minWidth="50px"
                      color="#000000">
                      {report?.item?.total_expectation?.total_excede_as_expectativas_final + '%'}
                    </Text>
                  </Box>
                  <Box backgroundColor="#e9e9e9"
                    borderRadius="0 25px 25px 0"
                    margin="40px 0 0 0"
                    minWidth="50px"
                    width="400px"
                    height="50px"
                  >
                  </Box>
                  <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}
                    position="absolute"
                    borderRadius="0 25px 25px 0"
                    top="342px"
                    left="893px"
                    width={(report?.item?.total_expectation?.total_atende_as_expectativas_final ? report?.item?.total_expectation?.total_atende_as_expectativas_final : 0) * 4 + 'px'}
                    height="50px">
                  </Box>
                  <Box backgroundColor="trasnparent"
                    position="absolute"
                    borderRadius="0 25px 25px 0"
                    top="340px"
                    left="893px"
                    width={(report?.item?.total_expectation?.total_atende_as_expectativas_final ? report?.item?.total_expectation?.total_atende_as_expectativas_final : 15) * 4 + 'px'}
                    height="50px">
                    <Text fontWeight="200"
                      fontSize="22px"
                      margin="auto"
                      textAlign="center"
                      minWidth="50px"
                      color="#000000">
                      {report?.item?.total_expectation?.total_atende_as_expectativas_final + '%'}
                    </Text>
                  </Box>
                  <Box backgroundColor="#e9e9e9"
                    borderRadius="0 25px 25px 0"
                    margin="40px 0 0 0"
                    minWidth="50px"
                    width="400px"
                    height="50px"
                  >
                  </Box>
                  <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA'}
                    position="absolute"
                    borderRadius="0 25px 25px 0"
                    top="432px"
                    left="893px"
                    width={(report?.item?.total_expectation?.total_abaixo_das_expectativas_final ? report?.item?.total_expectation?.total_abaixo_das_expectativas_final : 0) * 4 + 'px'}
                    height="50px">
                  </Box>
                  <Box backgroundColor="trasnparent"
                    position="absolute"
                    borderRadius="0 25px 25px 0"
                    top="430px"
                    left="893px"
                    width={(report?.item?.total_expectation?.total_abaixo_das_expectativas_final ? report?.item?.total_expectation?.total_abaixo_das_expectativas_final : 15) * 4 + 'px'}
                    height="50px">
                    <Text fontWeight="200"
                      fontSize="22px"
                      margin="auto"
                      textAlign="center"
                      minWidth="50px"
                      color="#000000">
                      {report?.item?.total_expectation?.total_abaixo_das_expectativas_final + '%'}
                    </Text>
                  </Box>
                  <Box backgroundColor="#e9e9e9"
                    borderRadius="0 25px 25px 0"
                    margin="40px 0 0 0"
                    minWidth="50px"
                    width="400px"
                    height="50px"
                  >
                  </Box>
                  <Box backgroundColor={report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA' }
                    position="absolute"
                    borderRadius="0 25px 25px 0"
                    top="522px"
                    left="893px"
                    width={(report?.item?.total_expectation?.total_insatisfatorio_final ? report?.item?.total_expectation?.total_insatisfatorio_final : 0) * 4 + 'px'}
                    height="50px">
                  </Box>
                  <Box backgroundColor="trasnparent"
                    position="absolute"
                    borderRadius="0 25px 25px 0"
                    top="520px"
                    left="893px"
                    width={(report?.item?.total_expectation?.total_insatisfatorio_final ? report?.item?.total_expectation?.total_insatisfatorio_final : 15) * 4 + 'px'}
                    height="50px">
                    <Text fontWeight="200"
                      fontSize="22px"
                      margin="auto"
                      textAlign="center"
                      minWidth="50px"
                      color="#000000">
                      {report?.item?.total_expectation?.total_insatisfatorio_final + '%'}
                    </Text>
                  </Box>
                </Box>
              </Grid>
            </Slide>
            <Slide backgroundColor={(report?.item?.color_palette?.background ? report?.item?.color_palette?.background : '#000000')}>
              <Heading fontWeight="600" fontSize="34px" margin="0px" color="#000000">
                MÉDIA GERAL DE EVOLUÇÃO
              </Heading>
              <Heading fontWeight="400" fontSize="22px" lineHeight="15px" padding="0px" margin="0px" color="#000000">
                MÉDIA	DAS	NOTAS	ATRIBUÍDAS EM CADA COMPETÊNCIA NO INÍCIO E AO FINAL DO PROCESSO
              </Heading>
              <Box backgroundColor="trasnparent"
                position="absolute"
                top="150px"
                left="250px"
                width="900px">
                <Grid gridTemplateColumns="1fr 1fr" gridColumnGap={15}>
                  <Box>
                    <Text fontWeight="600"
                      fontSize="24px"
                      textAlign="center"
                      color="#000000" >NOTA MÉDIA INICIAL</Text>
                    <CircularProgress sx={{ position: 'absolute', left: '70px', color: 'grey.300' }}
                      variant="determinate"
                      value={100}
                      size={300}
                      thickness={6} />
                    <CircularProgress sx={{ position: 'absolute', left: '70px', color: (report?.item?.color_palette?.variant1 ? report?.item?.color_palette?.variant1 : '#019DAA') }}
                      variant="determinate"
                      value={report?.item?.total_expectation?.nota_media_inicial_percent ? report?.item?.total_expectation.nota_media_inicial_percent * 10 : 0}
                      size={300}
                      thickness={6} />
                    <Box backgroundColor="trasnparent"
                      position="absolute"
                      top="180px"
                      left="110px"
                      width="215px">
                      <Text fontWeight="800"
                        fontSize="48px"
                        textAlign="center"
                        color="#000000">
                        {report?.item?.total_expectation?.nota_media_inicial}
                      </Text>
                    </Box>
                  </Box>
                  <Box>
                    <Text fontWeight="600"
                      fontSize="24px"
                      textAlign="center"
                      color="#000000" >NOTA MÉDIA FINAL</Text>
                    <CircularProgress sx={{ position: 'absolute', left: '530px', color: 'grey.300' }}
                      variant="determinate"
                      value={100}
                      size={300}
                      thickness={6} />
                    <CircularProgress sx={{ position: 'absolute', left: '530px', color: (report?.item?.color_palette?.variant1 ? report.item.color_palette?.variant1 : '#019DAA') }}
                      variant="determinate"
                      value={report?.item?.total_expectation?.nota_media_final_percent ? report.item.total_expectation.nota_media_final_percent * 10 : 0}
                      size={300}
                      thickness={6} />
                    <Box backgroundColor="trasnparent"
                      position="absolute"
                      top="180px"
                      left="570px"
                      width="215px">
                      <Text fontWeight="800"
                        fontSize="48px"
                        textAlign="center"
                        color="#000000">
                        {report?.item?.total_expectation?.nota_media_final}
                      </Text>
                    </Box>
                  </Box>
                </Grid>
                </Box>
              </Slide>
          </Deck>
        </>
      ) : (
        <Alert severity="warning">Não há itens suficiente em seu programa para iniciar uma apresentação.</Alert>
      )
      )}
    </>
  );
};

/*

        <Slide
          transition={{
            from: {
              transform: 'scale(0.5) rotate(45deg)',
              opacity: 0
            },
            enter: {
              transform: 'scale(1) rotate(0)',
              opacity: 1
            },
            leave: {
              transform: 'scale(0.2) rotate(315deg)',
              opacity: 0
            }
          }}
          backgroundColor="tertiary"
          backgroundImage="url(slide-resultados.png)"
          backgroundOpacity={0.5}
        >
          <Heading>Custom Backgrounds</Heading>
          <UnorderedList>
            <ListItem>
              <CodeSpan>backgroundColor</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundImage</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundOpacity</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundSize</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundPosition</CodeSpan>
            </ListItem>
            <ListItem>
              <CodeSpan>backgroundRepeat</CodeSpan>
            </ListItem>
          </UnorderedList>
        </Slide>
        <Slide>
          <Heading>Animated Elements</Heading>
          <OrderedList>
            <Appear>
              <ListItem>Elements can animate in!</ListItem>
            </Appear>
            <Appear>
              <ListItem>Out of order</ListItem>
            </Appear>
            <Appear priority={0}>
              <ListItem>
                Just identify the order with the prop <CodeSpan>priority</CodeSpan>!
              </ListItem>
            </Appear>
          </OrderedList>
        </Slide>
        <Slide>
          <FlexBox>
            <Text>These</Text>
            <Text>Text</Text>
            <Text color="secondary">Items</Text>
            <Text fontWeight="bold">Flex</Text>
          </FlexBox>
          <Grid gridTemplateColumns="1fr 2fr" gridColumnGap={15}>
            <Box backgroundColor="primary">
              <Text color="secondary">Single-size Grid Item</Text>
            </Box>
            <Box backgroundColor="secondary">
              <Text>Double-size Grid Item</Text>
            </Box>
          </Grid>
          <Grid
            gridTemplateColumns="1fr 1fr 1fr"
            gridTemplateRows="1fr 1fr 1fr"
            gridRowGap={1}
          >
            {Array(9)
              .fill('')
              .map((_, index) => (
                <FlexBox paddingTop={0} key={`formidable-logo-${index}`} flex={1}>
                  <Image src={formidableLogo} width={100} />
                </FlexBox>
              ))}
          </Grid>
        </Slide>
        <SlideFragments />
        <Slide>
          <CodePane language="jsx">{`
            import { createClient, Provider } from 'urql';
            const client = createClient({ url: 'https://0ufyz.sse.codesandbox.io' });
            const App = () => (
              <Provider value={client}>
                <Todos />
              </Provider>
            );
            `}</CodePane>
          <Box height={20} />
          <CodePane language="java" showLineNumbers={false}>{`
            public class NoLineNumbers {
              public static void main(String[] args) {
                System.out.println("Hello");
              }
            }
            `}</CodePane>
        </Slide>
        <div>
          <Slide>
            <Heading>This is a slide embedded in a div</Heading>
          </Slide>
        </div>
        <MarkdownSlide componentProps={{ color: 'yellow' }}>
          {`
            # This is a Markdown Slide
            - You can pass props down to all elements on the slide.
            - Just use the \`componentProps\` prop.
            `}
        </MarkdownSlide>
        <MarkdownSlide animateListItems>
          {`
          # This is also a Markdown Slide
          It uses the \`animateListItems\` prop.
          - Its list items...
          - ...will appear...
          - ...one at a time.
          `}
        </MarkdownSlide>
        <Slide>
          <Grid
            gridTemplateColumns="50% 50%"
            gridTemplateRows="50% 50%"
            height="100%"
          >
            <FlexBox alignItems="center" justifyContent="center">
              <Heading>This is a 4x4 Grid</Heading>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center">
              <Text textAlign="center">
                With all the content aligned and justified center.
              </Text>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center">
              <Text textAlign="center">
                It uses Spectacle <CodeSpan>{'<Grid />'}</CodeSpan> and{' '}
                <CodeSpan>{'<FlexBox />'}</CodeSpan> components.
              </Text>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent="center">
              <Box width={200} height={200} backgroundColor="secondary" />
            </FlexBox>
          </Grid>
        </Slide>
        <MarkdownSlideSet>
          {`
            # This is the first slide of a Markdown Slide Set
            ---
            # This is the second slide of a Markdown Slide Set
            `}
        </MarkdownSlideSet>
        <SlideLayout.List
          title="Slide layouts!"
          items={['Two-column', 'Lists', 'And more!']}
          animateListItems
        />

*/
