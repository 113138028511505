import { useState, MouseEvent } from 'react';
import { MenuItem, Menu, Typography,  Avatar, Box, useTheme, IconButton, Card, List, ListItem, ListItemAvatar, ListItemText, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAuthContext, useMentoringContext  } from '../../contexts';

import { Environment } from '../../environment';
import { useNavigate } from 'react-router-dom';
import { IMentory } from '../../contexts/MentoringContext/types';






export const SelectMentory = () => {

  const theme = useTheme();
  const navigate = useNavigate();
  const { mentory, mentoring, markMentory } = useMentoringContext();
  const { user } = useAuthContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleTeamSelect = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMentory = (module:IMentory) => {
    markMentory(module);
    setAnchorEl(null);
    navigate('/pagina-inicial');
  };
  const handleViewAll = () => {
    markMentory(null);
    navigate('/meus-processos');
  };

  return (


    <Card variant="outlined" sx={{ml:'15px', mr:'5px', borderRadius:'12px' }}>
      <List sx={{width: '100%', bgcolor: 'background.paper' }}>
        <ListItem alignItems="flex-start" sx={{ p:0, px:1 }}>
          <ListItemAvatar>
            <Avatar sx={{ border:'3px solid', borderColor: theme.palette.primary.main, width:50, height:50, mr:1.5}}  alt={mentory?.name_mentorado} src={(Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + (mentory?.id_user_mentor == user?.id ? mentory?.image_mentorado : mentory?.image_mentor)} />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography sx={{mt:'3px', fontSize:'14px', fontWeight:600}}> {mentory?.id_user_mentor == user?.id ? 'MENTORADO' : 'MENTOR'}</Typography>}
            secondary={<Typography sx={{fontSize:'12px'}}>{mentory?.id_user_mentor == user?.id ? mentory?.name_mentorado : mentory?.name_mentor}</Typography>}
          />
        </ListItem>
      </List>
      <Box sx={{ position:'absolute', right:'16px' , top:'5px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
            onClick={handleTeamSelect}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
        {(mentoring && mentoring.map((mentorySelect, index) => (
          mentory?.id_mentory != mentorySelect.id_mentory && (
          <Box key={mentorySelect.id_mentory} >
            <MenuItem onClick={() => handleSelectMentory(mentorySelect)}>
              <Avatar sx={{ border:'3px solid', borderColor: theme.palette.primary.main,  height: theme.spacing(9), width: theme.spacing(9) }} 
                    alt={mentorySelect?.id_user_mentor == user?.id ? mentorySelect?.name_mentorado : mentorySelect?.name_mentor} 
                    src={(Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/medium_' + (mentorySelect?.id_user_mentor == user?.id ? mentorySelect?.image_mentorado : mentorySelect?.image_mentor)} />
              <Box>
                <Typography  sx={{fontSize:'12px',fontWeight:500}} >
                    {mentorySelect?.id_user_mentor == user?.id ? 'MENTORADO' : 'MENTOR'}
                </Typography>
                <Typography sx={{fontSize:'10px'}} >
                  {mentorySelect?.id_user_mentor == user?.id ? mentorySelect?.name_mentorado : mentorySelect?.name_mentor}
                </Typography>  
              </Box>
            </MenuItem>
            <Divider sx={{width:'80%', ml:'10%'}}/>
          </Box>
          ))))}
          <MenuItem onClick={handleViewAll}>
              <Box sx={{textAlign:'center',width: '100%'}}>
                <Typography color="primary" sx={{fontSize:'12px', fontWeight:500}} >Ver Todos</Typography>
              </Box>
          </MenuItem>
        </Menu>
      </Box>
    </Card>


    /*
    <SBDCBoxSidebar width={theme.spacing(40)} >

      {team.allTeams != null && team.allTeams.length > 1 && (
      <Box sx={{ position:'absolute', right:'16px' , marginTop:'-15px', display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleTeamSelect}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
        {(team.allTeams.map((teamSelect) => (
          teamSelect.id_equipe != team.id_equipe && (
          <MenuItem key={teamSelect.id_inscricao} onClick={() => team.accessTeam(teamSelect)}>
            <Avatar sx={{ border:'3px solid #EA5B0C', height: theme.spacing(9), width: theme.spacing(9) }} 
                  alt={teamSelect.name} 
                  src={ teamSelect.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + teamSelect.avatar : ''} />
            <Box>
              <Typography  sx={{fontSize:'12px'}} >
                <strong>{teamSelect.name}</strong>
              </Typography>
              <Typography  sx={{fontSize:'8px'}} >
                  {teamSelect.type_tean === '1' ? 'MENTORADO' : 'MENTOR'}
              </Typography>  
            </Box>
          </MenuItem>
          ))))}
        </Menu>
      </Box>
      )}
      <Grid container spacing={2}>
        <Grid item xs={4.5}>
          <Avatar sx={{ border:'3px solid #EA5B0C', height: theme.spacing(9), width: theme.spacing(9) }} 
                  alt={team.name} 
                  src={team.avatar ? 'https://online.sbdc.com.br/media/images/avatar/' + team.avatar : ''} />
        </Grid>
        <Grid item xs={7.5}>
          <Box>
            <Typography  sx={{mt:'3px', fontSize:'14px'}} >
              <strong>{team.type_tean === '1' ? 'MENTORADO' : 'MENTOR'}</strong>
            </Typography> 
          </Box>
          <Box>
            <Typography  sx={{fontSize:'12px'}} >
              {team.name}
            </Typography> 
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={1.5} sx={{mt:'3px'}}>
                <WhatsAppIcon sx={{ fontSize:'18px', color:'#EA5B0C'}} />
              </Grid>
              <Grid item xs={8}>
                <Typography sx={{ mt:'3px',fontSize:'14px'}}>
                {team.phone}
                </Typography> 
              </Grid>
            </Grid>
          </Box>
          <Box>
            <SBDCButtonChat onClick={handleClick}>
              Iniciar Chat
            </SBDCButtonChat>
          </Box>
        </Grid> 
      </Grid>
    </SBDCBoxSidebar>*/
  );
};
   

