import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Divider, List, ListItem, ListItemText, ListItemIcon, Theme, useMediaQuery, Skeleton, useTheme} from '@mui/material/';

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

import { useEASThemeContext } from '../../contexts';
import { ITip } from '../../contexts/TipsContext/types';

interface IEASDashboardTipsProps {
  Tips?: Array<ITip>;
  isLoading: boolean;
}  


export const EASDashboardTips: React.FC<IEASDashboardTipsProps> = ({Tips, isLoading}) => {

  const { themeName } = useEASThemeContext();
  const theme = useTheme();
  
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  
  const navigate = useNavigate();


  return (  <>
            {isLoading && (
                <Skeleton animation="wave" variant="rectangular" height={'360px'} width={'100%'} />
            )}
            {!isLoading && (
                <Box sx={{ display:'flex', backgroundColor: theme.palette.primary.main + '14', borderRadius:'12px'}}>
                  <Box sx={{ textAlign:{ xs: 'center', sm: 'left'},  mx: { xs: 2, sm: 4 }, my: { xs: 2, sm: 4 }}}>
                    <Typography sx={{ fontWeight: 500,}} variant="h5">
                      Algumas Dicas!
                    </Typography> 
                    <List sx={{ width: '100%'}}>
                      { Tips && ( Tips.map((Tip, index) => (
                      <Box key={index}>
                        <ListItem alignItems="flex-start">
                          {!xsDown && !smDown && !mdDown && (
                          <ListItemIcon>
                            <TipsAndUpdatesIcon />
                          </ListItemIcon>
                          )}
                          <ListItemText
                            primary={Tip.text_tips}                            
                          />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                      </Box>
                      )))}
                    </List>
                    {/*
                    <Grid key={index} item xs={12} sm={12} md={4} lg={3} sx={{width:'100%', display:{ sm: (index>5 ? 'none' : 'block'), md:(index>5 ? 'none' : 'block'), lg:'block'}}}>
                      <Typography variant="subtitle1" sx={{mt:'15px'}}>
                        {Tip.text_tips}
                      </Typography>
                    <Divider variant="inset" component="li" />
                    </Grid>*/}
                    <Button sx={{mt:'30px', verticalAlign:'bottom'}} variant="contained" color="primary" onClick={() => navigate('/dicas')}>Conheça mais dicas</Button>
                  </Box>
                </Box>
              )}
            </>
            
            
            

          );
};

