import React, { useState, useRef } from 'react';
import { Tab, Tabs, Box, Stack } from '@mui/material';




import { useParams, useNavigate} from 'react-router-dom';

import { LayoutDefault } from '../../shared/layouts';

import OperatorTab, { IRefOperator } from './OperatorTab';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';



import { IOperator } from '../../shared/contexts/OperatorsContext/types';
import { OperatorsService } from '../../shared/services/api/operators/OperatorsService';
import { UtilService } from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
export const Operator = () => {

    const { id = ''} = useParams<'id'>();
   /* const [value, setValue] = useState<string>('1');*/
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const storeDisplay = () => {
        if(refOperator.current !== null)
            refOperator.current.saveOperator();
    };

    const refOperator = useRef<IRefOperator>(null);


    const returnList = () => {
        queryClient.invalidateQueries('operator-operator-edit');
        navigate('/operadores');
    };

    const { data:userGroups, isLoading:loadingGroups  } = useQuery(['operator-groups-list'],() => UtilService.getUserGroups(),  {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista de tipos de operadores.', { variant:'error'});
      },
    });
  
    const { data:operator, isLoading, isFetching } = useQuery(['operator-operator-edit', id],() =>   OperatorsService.getOperator(id),  {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar o operador.', { variant:'error'});
      },
    });
    
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updateOperator:IOperator) =>OperatorsService.updateOperator(updateOperator), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-operator-edit');
            queryClient.invalidateQueries('operator-operator-list');
            queryClient.invalidateQueries('operator-operators-list');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar file.', { variant:'error'});
        },
    });

    return (

        <LayoutDefault >
            <EASCard 
                titleCard={'Editar Arquivo'}
                breadcrumbsCard=""
                actionCard={<Stack direction="row" spacing={1}>
                                <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                <EASButton variant="outlined" onClick={() => returnList()}>Sair</EASButton>
                            </Stack>} >
                <Box sx={{mt: 2}}>
                    <OperatorTab ref={refOperator} userGroups={userGroups} operatorEdit={operator} isLoading={(isFetching || isLoading || loadingGroups || isLoadingStore)} storeUpdate={(operator) => storeUpdate(operator)} />
                </Box>
            </EASCard>
        </LayoutDefault>

    );
};
