import React from 'react';
import { Grid } from '@mui/material';
import { IVideo} from '../../shared/types';
import { MentoryVideoCard } from './MentoryVideoCard';

interface IMentoryVideosColumProps {
  direction?: 'column' | 'row';
  videos?: Array<IVideo> | null;
}  

export const MentoryVideosColum: React.FC<IMentoryVideosColumProps> = ({ videos, direction}) => {

  return (

    <Grid container spacing={1}> 
      {(videos && videos.length>0 && (videos.map((video, index) => (
        <Grid item xs={12} md={4} lg={12} key={index}>
          {video.id_video && <MentoryVideoCard video={video} /> }
        </Grid>
      ))))}
    </Grid>
  );
};
