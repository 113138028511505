import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import { EASButton, EASInput, EASSelect } from '../../shared/forms';
import { UtilService } from '../../shared/services/api/util/UtilService';
import { IList } from '../../shared/types';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../shared/contexts';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { IObjectivesSample } from '../../shared/contexts/ObjectivesSampleContext/types';
import { ObjectivesSamplesService } from '../../shared/services/api/objectives-sample/ObjectivesSampleService';

type IObjectivesSampleNewObjectiveSample = {
  open: boolean;
  setOpen: (open:boolean) => void;
  setUpdateList:() => void;
};

export const ObjectivesSampleNewObjectiveSample:React.FC<IObjectivesSampleNewObjectiveSample> =  (({open, setOpen, setUpdateList}) => { 

    
  const [saveLoading, setSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [objectiveList, setObjectiveList] = useState<Array<IList>>([]);
  const [tags, setTags] = useState<Array<IList>>([]);
  const [objectiveSample, setObjectiveSample] = useState<IObjectivesSample | null>(null);
  const [objectiveSampleError, setObjectiveSampleError] = useState<IObjectivesSample | null>(null);

  const { user } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
      async function getObjectiveList() {
        setIsLoading(true);
        const ObjectiveSampleResponse = await UtilService.getObjectivesSample();
        if (ObjectiveSampleResponse instanceof Error) {
          setObjectiveList([]);    
        } else {
          if(ObjectiveSampleResponse){
              const newObjectiveSample = JSON.parse(JSON.stringify(ObjectiveSampleResponse));
              setObjectiveList(ObjectiveSampleResponse);
          }
          else{
            setObjectiveList([]);
          }
        }
      }
      async function getTags() {
        const tagsResponse = await UtilService.getTags();
        if (tagsResponse instanceof Error) {
            setTags([]);    
        } else {
          if(tagsResponse){
              const newTags = JSON.parse(JSON.stringify(tagsResponse));
              setTags(newTags);
          }
          else{
              setTags([]);
          }
        }
      }
      async function getObjectiveSample() {
        //setIsLoading(true);
        const ObjectiveSampleResponse = await ObjectivesSamplesService.getObjectiveSample('');
        if (ObjectiveSampleResponse instanceof Error) {
          setObjectiveSample(null);
          setObjectiveSampleError(null);
          setIsLoading(false);
        } else {
          if(ObjectiveSampleResponse){
              const myObjectiveSample = JSON.parse(JSON.stringify(ObjectiveSampleResponse));
              setObjectiveSample(myObjectiveSample);
              setObjectiveSampleError(null);
              setIsLoading(false);
          }
          else{
              setObjectiveSample(null);
              setObjectiveSampleError(null);
              setIsLoading(false);
          }
        }
      }
      getObjectiveList().then(() => {
        getTags().then(() => {
          getObjectiveSample();
        });
      });
    }, [open]);

    const isValidObjectivesSample = yup.object({
        name_objective_sample: yup.string().required('Este campo é obrigatório'),
        text_objective_sample: yup.string().required('Este campo é obrigatório'),
     });
   
    const saveObjectiveSample = () => {
      isValidObjectivesSample
      .validate( objectiveSample , { abortEarly: false })
      .then(validateObjectiveSample => { 
        setSaveLoading(true);
          if(validateObjectiveSample) {
            const newObjectiveSample: IObjectivesSample = {
                status_objective_sample: '1',
                name_objective_sample: validateObjectiveSample.name_objective_sample,
                text_objective_sample: validateObjectiveSample.text_objective_sample,
                tags: objectiveSample?.tags,
                user_register: user?.id,
            };            
            ObjectivesSamplesService.createObjectiveSample(newObjectiveSample)
              .then((ObjectivesSamplesService) => {
                  if (ObjectivesSamplesService instanceof Error) {
                      setSaveLoading(false);
                      enqueueSnackbar('Erro ao cadastrar nova ação sugerida.', { variant:'error'});
                  } else {
                      enqueueSnackbar('Ação sugerida cadastrado com sucesso!', { variant:'success'});
                      setOpen(false);
                      setUpdateList();
                      setSaveLoading(false);
                  }
              }).catch(() => {
                  setSaveLoading(false);
                  enqueueSnackbar('Erro ao cadastrar nova ação sugerida.', { variant:'error'});
              });
            }
            else{
              setSaveLoading(false);
              enqueueSnackbar('Erro ao cadastrar nova ação sugerida.', { variant:'error'});
          }
        })
        .catch((errors: yup.ValidationError) => {
          errors.inner.forEach(error => {
              if(error.path){
                const keyError = error.path;                    
                setObjectiveSampleError(existingValues => ({
                                    ...existingValues,
                                    [keyError]: error.message
                                }));                        
              }
            });
            setIsLoading(false);
        });
    };

    const handleClose = () => {
      setObjectiveSample({});
      setObjectiveSampleError(null);
      setTags([]);
      setOpen(false);
    };
  

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>Cadastrar Nova Ação Sugerida</DialogTitle>
      <Divider/>
        <DialogContent>
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {saveLoading && (                   
              <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress variant='indeterminate' />
              </Box>
            )}
            {!saveLoading && ( 
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <EASInput
                    isLoading={isLoading}
                    label="Ação Sugerida"
                    type="text"
                    multiline
                    rows={2}
                    value={objectiveSample?.text_objective_sample}
                    onFocus={() => 
                    setObjectiveSampleError(existingValues => ({
                      ...existingValues,
                      text_objective_sample: '',
                      }))
                    }
                    onChange={(e) => 
                    setObjectiveSample(existingValues => ({
                          ...existingValues,
                          text_objective_sample: e.target.value,
                      }))
                    }
                    error={!!objectiveSampleError?.text_objective_sample}
                    helperText={objectiveSampleError?.text_objective_sample}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EASSelect
                      isLoading={isLoading}
                      options={objectiveList}
                      getOptionLabel={(option) => option.text}
                      value={objectiveList.find(item => item.value == objectiveSample?.name_objective_sample)}                                  
                      onChangeSelect={(event, value:IList) => {
                        if (value && value.value) {                            
                          setObjectiveSample(existingValues => ({
                              ...existingValues,
                              name_objective_sample: value.value,
                          })); 
                        }
                      }}
                      label="Competência"
                      onChangeTextField={(event) => {
                        setObjectiveSample(existingValues => ({
                          ...existingValues,
                          name_objective_sample: event.target.value,
                        })); 
                      }}
                      helperText={objectiveSampleError?.name_objective_sample}
                      error={!!objectiveSampleError?.name_objective_sample}
                      onFocus={() => 
                        setObjectiveSampleError(existingValues => ({
                          ...existingValues,
                          name_objective_sample: '',
                        }))
                      }
                      fullWidth={true}
                      freeSolo={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                  <EASSelect 
                    isLoading={isLoading}
                    options={tags}
                    label="Tags da Ação Sugerida"
                    getOptionLabel={(option) => option.text}                                  
                    value={objectiveSample?.tags && objectiveSample?.tags.map((tag) => tags.find(item => item.value == tag))}                                  
                      onChangeSelect={(event, values:Array<IList>) => {
                      const NewValue:Array<string> = [];
                      values.map((value) => NewValue.push(value.value));
                        setObjectiveSample(existingValues => ({
                            ...existingValues,
                            tags: NewValue,
                        }));
                      } 
                    } 
                    fullWidth
                    multipleSelect
                    filterSelectedOptions
                    disableClearable
                    />
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <EASButton variant="outlined" onClick={handleClose} disabled={saveLoading}>Cancelar</EASButton>
          <EASButton variant="contained" onClick={saveObjectiveSample} disabled={saveLoading}>Confirmar</EASButton>
        </DialogActions>
    </Dialog>    
  );
});
