import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { styled, Box, Container, CircularProgress, Typography, useTheme, Divider, Link, Alert, TextField, Grid } from '@mui/material';
import { useAuthContext, useEASThemeContext } from '../../shared/contexts';

import * as yup from 'yup';
import { EASInput, EASButton } from '../../shared/forms';
import { EASCard } from '../../shared/components';
import { Environment } from '../../shared/environment';
import { Stack, textAlign } from '@mui/system';
import { useMutation } from 'react-query';
import { IRecoverPassword } from '../../shared/types';
import { RecoverPasswordService } from '../../shared/services/api/recoverpassword/RecoverPasswordService';
import { useSnackbar } from 'notistack';
import Countdown from 'react-countdown';

const ContentStyleLogin = styled('div')(() => ({
    maxWidth: 400,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
}));


export const RecoverPassword = () => {

    const theme = useTheme();
    const { login } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const { themeName } = useEASThemeContext();
    const [emailError, setEmailError] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recoverPassword, setRecoverPassword] = useState<IRecoverPassword>({});
    const [recoverPasswordError, setRecoverPasswordError] = useState<IRecoverPassword | null>(null);

    const isValidEmail = yup.object().shape({
        email: yup.string().required('O campo e-mail é obrigatório.').email('Informe um e-mail válido'),
    });

    const { mutateAsync: sendEmailRecoverPassword, } = useMutation((email: string) => RecoverPasswordService.recoverPassword(email), {
        onSuccess: (data) => {
            if (data.status === 'success') {
                enqueueSnackbar('E-mail enviado!', { variant: 'success' });
                setIsLoading(false);
                if (data.item)
                    setRecoverPassword(data.item);
            } else {
                enqueueSnackbar('E-mail não localizado.', { variant: 'error' });
                setIsLoading(false);
            }
        },
        onError: () => {
            enqueueSnackbar('Erro ao enviar e-mail.', { variant: 'error' });
            setIsLoading(false);
        },
    });

    const handleSubmit = () => {
        isValidEmail
            .validate({ email }, { abortEarly: false })
            .then(validateEmail => {
                setIsLoading(true);
                sendEmailRecoverPassword(validateEmail.email);
            })
            .catch((errors: yup.ValidationError) => {
                setIsLoading(false);
                errors.inner.forEach(error => {
                    if (error.path) {
                        const keyError = error.path;
                        //if (keyError == 'email')
                        setEmailError(error.message);
                    }
                });
            });
    };


    const isValidCode = yup.object({
        code: yup.string().required('Este campo é obrigatório').min(6, 'Este campo deve ter no mínimo 6 caracteres'),
    });

    const { mutateAsync: sendCodeRecoverPassword, } = useMutation((recoverPassword: IRecoverPassword) => RecoverPasswordService.codeRecoverPassword(recoverPassword), {
        onSuccess: (data) => {
            setIsLoading(false);
            if (data.status === 'success') {
                //enqueueSnackbar('E-mail enviado!', { variant: 'success' });
                if (recoverPassword?.email && recoverPassword.code) {
                    login(recoverPassword?.email, recoverPassword.code);
                    setRecoverPassword({});
                }
            } else {
                if (data.item?.attempts && data.item.attempts !== '0') {
                    enqueueSnackbar('Código inválido. Tentativas restantes: ' + data.item.attempts, { variant: 'error' });
                    setRecoverPassword(existingValues => ({
                        ...existingValues,
                        attempts: data.item?.attempts,
                    }));
                } else {
                    enqueueSnackbar('Número de tentativas excedidas. Reinicie o processo.', { variant: 'error' });
                    //Tentativas excedidas
                    setRecoverPassword({});
                }
                setIsLoading(false);
            }
        },
        onError: () => {
            enqueueSnackbar('Erro ao enviar código.', { variant: 'error' });
            setIsLoading(false);
        },
    });

    const handleTimeExpired = () => {
        enqueueSnackbar('Tempo expirado.', { variant: 'error' });
        setRecoverPassword({});
    };

    const handleSubmitCode = () => {
        isValidCode
            .validate(recoverPassword, { abortEarly: false })
            .then(validateCode => {
                setIsLoading(true);
                setRecoverPassword(validateCode);
                sendCodeRecoverPassword(recoverPassword);
            })
            .catch((errors: yup.ValidationError) => {
                setIsLoading(false);
                errors.inner.forEach(error => {
                    if (error.path) {
                        const keyError = error.path;
                        setRecoverPasswordError(existingValues => ({
                            ...existingValues,
                            [keyError]: error.message
                        }));
                    }
                });
            });
    };



    return (
        <Box width='100vw' display='flex' alignItems='center' justifyContent='center' sx={{ overflowX: 'hidden' }} bgcolor={theme.palette.background.default}>
            <Container>
                <ContentStyleLogin>
                    <EASCard>
                        <Box display='flex' flexDirection='column' alignItems='left' gap={2} sx={{ p: '40px 30px 10px 30px' }}>
                            <Box
                                display='flex'
                                flexDirection='column'
                                component="img"
                                alt={Environment.CLIENT_NAME}
                                alignItems='center'
                                height={'auto'}
                                width={'150px'}
                                src={'/logo_login' + (themeName === 'light' ? '' : '_d') + '.png'}
                                sx={{ alignContent: 'center', m: 'auto' }}
                            />
                            <Typography variant="h6" sx={{ fontWeight: 600 }} color="primary">Recuperar acesso</Typography>
                            {(!recoverPassword?.id) && (
                                <>
                                    <Alert severity="info" sx={{ textAlign: 'left', fontSize: 12 }}>Informe o e-mail cadastrado para que possamos enviar um código de recuperação de senha!</Alert>
                                    <Box display='flex' flexDirection='column' alignItems='left' gap={2} width='100%'>
                                        <form>
                                            <Stack spacing={2}>
                                                <EASInput
                                                    nameIconLeft='alternate_email'
                                                    label='E-mail'
                                                    type='email'
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    helperText={emailError}
                                                    disabled={isLoading}
                                                    error={!!emailError}
                                                />
                                                <Box></Box>
                                            </Stack>
                                        </form>
                                        <EASButton
                                            variant='contained'
                                            disabled={isLoading}
                                            onClick={handleSubmit}
                                            endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                                        >
                                            Enviar
                                        </EASButton>
                                    </Box>
                                </>
                            )}
                            {(recoverPassword?.id && recoverPassword.id !== '') && (
                                <>
                                    <Alert severity="info" sx={{ textAlign: 'left', fontSize: 12 }}>Digite o código recebido em seu e-mail para recuperar seu acesso!</Alert>
                                    <Box display='flex' flexDirection='column' alignItems='left' gap={2} width='100%'>
                                        <form>
                                            <Stack spacing={2} >
                                                <TextField
                                                    type='numeric'
                                                    label='Código'
                                                    onChange={(e) => {
                                                        // Permitir apenas números e limitar a entrada a 6 dígitos
                                                        const newValue = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere não numérico
                                                        if (newValue.length <= 6) {
                                                            setRecoverPassword((existingValues) => ({
                                                                ...existingValues,
                                                                code: newValue,
                                                            }));
                                                        }
                                                    }}
                                                    value={recoverPassword?.code}
                                                    helperText={recoverPasswordError?.code}
                                                    disabled={isLoading}
                                                    error={!!recoverPasswordError?.code}
                                                    InputLabelProps={{ shrink: true }}
                                                    sx={{
                                                        borderRadius: 2,
                                                        backgroundColor: 'eas.inputBackground',
                                                        '.MuiInputBase-input': { fontSize: '2rem', textAlign: 'center', letterSpacing: 25, mr: -3 }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 6,
                                                        pattern: '[0-9]*', // Garantir que só números sejam permitidos no teclado virtual (mobile)
                                                        inputMode: 'numeric' // Alternativa para suportar numérico em dispositivos móveis
                                                    }} />
                                            </Stack>
                                        </form>
                                        <Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                                                <Typography>Tentativas restantes:</Typography>
                                                <Typography sx={{ fontWeight: 'bold' }}> 0{recoverPassword?.attempts}</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                                                <Typography>Tempo restante:</Typography>
                                                <Typography sx={{ fontWeight: 'bold' }}>                                                {recoverPassword?.expiration && (
                                                    <Countdown daysInHours renderer={props => <div>{('0' + props.minutes).slice(-2) + ':' + ('0' + props.seconds).slice(-2)}</div>} date={new Date(recoverPassword.expiration)} onComplete={handleTimeExpired} />)
                                                }</Typography>
                                            </Box>
                                        </Box>
                                        <EASButton
                                            variant='contained'
                                            disabled={isLoading}
                                            onClick={handleSubmitCode}
                                            endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
                                        >
                                            Enviar
                                        </EASButton>
                                    </Box>
                                </>
                            )}
                            <Divider />
                        </Box>
                        <Box display="flex" flexDirection='column' alignItems='center' sx={{ alignContent: 'center', m: 'auto', alignItems: 'center' }}>
                            <Link
                                underline="none"
                                variant="subtitle2"
                                color="textSecondary"
                                onClick={() => navigate('/acessar')}
                                sx={{ pb: '10px', cursor: 'pointer' }}
                            >
                                Quer voltar para o login?
                            </Link>
                            <Link
                                underline="none"
                                variant="subtitle2"
                                color="textSecondary"
                                onClick={() => navigate('/cadastre-se')}
                                sx={{ pb: '30px', cursor: 'pointer' }}
                            >
                                Quer se cadastrar?
                            </Link>
                            <Box display="flex" sx={{ mb: '18px' }}>
                                <Typography display="flex" variant="caption" sx={{ fontSize: '18px' }}>©</Typography>
                                <Box
                                    display="flex"
                                    component="img"
                                    alt={Environment.CLIENT_NAME}
                                    alignItems='center'
                                    height={'20px'}
                                    width={'20px'}
                                    src={'/easistemas.png'}
                                    sx={{ mx: '3px', mt: '3px' }}

                                />
                                <Typography display="flex" variant="caption" sx={{ mt: '4px' }}>EASistemas</Typography>
                            </Box>
                        </Box>
                    </EASCard>
                </ContentStyleLogin>
            </Container>
        </Box>
    );
};
