import React, { useState, Ref, useImperativeHandle } from 'react';
import { Box, Card, CardContent, CardHeader, Grid, Divider, Avatar, Tabs, Tab,Skeleton, Typography } from '@mui/material';
 
 
import * as yup from 'yup';

import { IMentory } from '../../shared/contexts/MentoringContext/types';

import { Environment } from '../../shared/environment';
import { useAuthContext } from '../../shared/contexts';

import { EASButtonList } from '../../shared/forms';
import { IList } from '../../shared/types';


import { MentoryResourcesHumanTabDetailing } from './MentoryResourcesHumanTabDetailing';

import { EASProgressList } from '../../shared/components';
import { MentoryResourcesHumanTabDashboard } from './MentoryResourcesHumanTabDashboard';
import { MentoryResourcesHumanTabMeetings } from './MentoryResourcesHumanTabMeetings';
import { MentoryResourcesHumanTabPresentations } from './MentoryResourcesHumanTabPresentations';


export interface IRefMentory {
    saveMentory: () => void;
}

interface TabMentoryProps {
    children: React.ReactNode;
    index: number;
    value: number;
}
  

const MentoryTabPanel = (props: TabMentoryProps) => {
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  };
  
  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  interface IMentoryResourcesHumanTabDefaultProps {
    mentoryEdit?: IMentory;
    programs?:Array<IList>;
    tags?:Array<IList>;
    isLoading?:boolean;
    storeUpdate:(mentory:IMentory) => void;
  }

const MentoryResourcesHumanTabDefault = React.forwardRef<IRefMentory, IMentoryResourcesHumanTabDefaultProps>(({mentoryEdit, tags, programs, isLoading, storeUpdate}:IMentoryResourcesHumanTabDefaultProps, ref:Ref<IRefMentory>) => {

    const { user } = useAuthContext();
    const [mentoryError, setMentoryError] = useState<IMentory | null>(null);
    const [mentory, setMentory] = useState<IMentory | undefined>(mentoryEdit);
    const [openUpdateTeam, setOpenUpdateTeam] = useState(false);
    const [typeUpdateTeam, setTypeUpdateTeam] = useState<string>('');

    const [valueTab, setValueTab] = React.useState<number>(0);



    const handleChangeValueTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };



    //const [programs, setPrograms] = useState<Array<IList>>([]);
    //const [tags, setTags] = useState<Array<IList>>([]);

    const handleClickUpdate = (type?:string) => {
        if(type){
            setTypeUpdateTeam(type);
            setOpenUpdateTeam(true); 
        }
    }; 
    

    useImperativeHandle(ref, () => ({
        saveMentory,
    }));

    const isValidMentory = yup.object({
        name_mentory: yup.string().required('Este campo é obrigatório'),
        start_mentory: yup.string().required('Este campo é obrigatório'),
        end_mentory: yup.string().required('Este campo é obrigatório'),
    });

    const saveMentory = () => {
        isValidMentory
            .validate( mentory , { abortEarly: false })
            .then(validateMentory => {
                const updateMentory: IMentory = {
                    id_mentory: mentory?.id_mentory,
                    name_mentory: validateMentory.name_mentory,
                    start_mentory: validateMentory.start_mentory,
                    end_mentory: validateMentory.end_mentory,
                    description_mentory: mentory?.description_mentory,
                    objective_sample_mentory: mentory?.objective_sample_mentory,
                    id_program: mentory?.id_program,
                    tags: mentory?.tags,
                    user_update: user?.id,
                };     
              storeUpdate(updateMentory);
          })
          .catch((errors: yup.ValidationError) => {
              errors.inner.forEach(error => {
                  if(error.path){
                      const keyError = error.path;                    
                      setMentoryError(existingValues => ({
                                          ...existingValues,
                                          [keyError]: error.message
                                      }));                        
                  }
              });
          });
    };

    return (
        <Box sx={{ flexGrow: 1, display: 'flex', px: 2, py: 2}} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                                <CardHeader
                                    title="Participantes"
                                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                                />
                                <Divider/>
                                <CardContent>
                                    {(isLoading )  && (
                                    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item >
                                                        <Skeleton variant="circular" width={55} height={55} /> 
                                                    </Grid>
                                                    <Grid item zeroMinWidth sm={true}>
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={20} height={11} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={13} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={12} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{mt:'10px'}} >
                                                        <Skeleton variant="circular" width={10} height={10} /> 
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{mt:'10px'}}/>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item >
                                                        <Skeleton variant="circular" width={55} height={55} /> 
                                                    </Grid>
                                                    <Grid item zeroMinWidth sm={true}>
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={20} height={11} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={13} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Skeleton variant="rectangular" width={150} height={12} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sx={{mt:'10px'}} >
                                                        <Skeleton variant="circular" width={10} height={10} /> 
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{mt:'10px'}}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    )}
                                    {(!(isLoading ) ) && (
                                    <Box>
                                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} >
                                                    <Grid container spacing={2}>
                                                        <Grid item >
                                                            <Avatar sx={{width:55, height:55}}  alt={mentory?.name_mentorado} src={mentory?.image_mentorado ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + mentory?.image_mentorado  :  ''} />
                                                        </Grid>
                                                        <Grid item zeroMinWidth sm={true}>
                                                            <Grid container spacing={1} >
                                                                <Grid item xs={12}>
                                                                    <Typography component="h6" variant="caption" sx={{fontSize:11}}>Mentorado</Typography>
                                                                    <Typography component="h4" variant="subtitle2" sx={{fontWeight:500}}>{mentory?.name_mentorado}</Typography>
                                                                    <Typography component="h5" variant="caption">{mentory?.email_mentorado}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sx={{mt:'10px'}} >
                                                            <EASButtonList 
                                                                title="Alterar Menorado"
                                                                onClick={() => handleClickUpdate('Mentorado')} 
                                                                typeIcon="edit"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider sx={{mt:'10px'}}/>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Grid container spacing={2}>
                                                        <Grid item >
                                                            <Avatar sx={{width:55, height:55}}  alt={mentory?.name_mentor} src={mentory?.image_mentor ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + mentory?.image_mentor  :  ''} />
                                                        </Grid>
                                                        <Grid item zeroMinWidth sm={true}>
                                                            <Grid container spacing={1} >
                                                                <Grid item xs={12}>
                                                                    <Typography component="h6" variant="caption" sx={{fontSize:11}}>Mentor</Typography>
                                                                    <Typography component="h4" variant="subtitle2" sx={{fontWeight:500}}>{mentory?.name_mentor}</Typography>
                                                                    <Typography component="h5" variant="caption">{mentory?.email_mentor}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sx={{mt:'10px'}} >
                                                            <EASButtonList 
                                                                title="Alterar Mentor"
                                                                onClick={() => handleClickUpdate('Mentor')} 
                                                                typeIcon="edit"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                                <CardHeader
                                    title="Progresso"
                                    titleTypographyProps={{fontSize: 16, textTransform: 'uppercase', fontWeight: 'bold'}}
                                />
                                <Divider/>
                                <CardContent>
                                    {(isLoading )  && (
                                    <Box></Box>
                                    )}
                                    {(!(isLoading ) ) && (
                                    <Box>
                                        <EASProgressList
                                            dateInit={mentory?.start_mentory}
                                            dateFinish={mentory?.end_mentory}
                                            progress={mentory?.progress_mentory}
                                            textProgress={mentory?.name_progress_mentory}
                                        />
                                    </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Card sx={{borderRadius : 2, bgcolor: 'transparent'}} variant='outlined' elevation={0}>
                    <CardHeader
                            sx={{py:'2.5px'}}
                            title={
                        <Tabs variant="scrollable" value={valueTab} onChange={handleChangeValueTab} allowScrollButtonsMobile  textColor='primary' sx={{'& .MuiTabs-indicator': { backgroundColor: 'none', height: 0}}}>
                            <Tab label="Visão Geral" {...a11yProps(0)} sx={{fontSize: 16, }}/>
                            <Tab label="Competências" {...a11yProps(1)} sx={{fontSize: 16}}/>
                            <Tab label="Sessões" {...a11yProps(1)} sx={{fontSize: 16}}/>
                          {/*  <Tab label="Tarefas" {...a11yProps(2)} sx={{fontSize: 16}}/>
                            <Tab label="Diário" {...a11yProps(3)} sx={{fontSize: 16}}/>
                            <Tab label="Avaliações" {...a11yProps(4)} sx={{fontSize: 16}}/>*/}
                            <Tab label="Apresentação Final" {...a11yProps(5)} sx={{fontSize: 16}}/>
                        </Tabs>}
                    />
                        <Divider/>
                        <CardContent>
                            <MentoryTabPanel value={valueTab} index={0}>
                                <MentoryResourcesHumanTabDashboard mentory={mentory} />
                            </MentoryTabPanel>
                            <MentoryTabPanel value={valueTab} index={1}>
                                <MentoryResourcesHumanTabDetailing mentory={mentory} />
                            </MentoryTabPanel>
                            <MentoryTabPanel value={valueTab} index={2}>
                                <MentoryResourcesHumanTabMeetings mentory={mentory} />
                            </MentoryTabPanel>
                            <MentoryTabPanel value={valueTab} index={3}>
                                <MentoryResourcesHumanTabPresentations mentory={mentory} />
                            </MentoryTabPanel>


                            
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
});

MentoryResourcesHumanTabDefault.displayName = 'MentoryResourcesHumanTabDefault';

export default MentoryResourcesHumanTabDefault;
