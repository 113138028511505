
import { IUser } from '../../types';

export function setUserLocalStorange(user: IUser | null) {
    if(user)
        localStorage.setItem('easui', JSON.stringify(user));
    else
        localStorage.removeItem('easui');
}

export function getUserLocalStorange() {
    const json = localStorage.getItem('easui');
    if(!json){
        return null;
    }
    const user = JSON.parse(json);
    return user ?? null;
}





