import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutDefault } from '../../shared/layouts';
import { PresentationResourcesHumanFinal } from './PresentationResourcesHumanFinal';
import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { Box } from '@mui/material';

export const PresentationsResourcesHuman: React.FC = () => {
  const navigate = useNavigate();
  return (
    <LayoutDefault >
      <EASCard
        titleCard={'Apresentação de Resultados'}
        breadcrumbsCard=''
        actionCard={<EASButton variant='contained' onClick={() => navigate('/relatorio-final/imprimir?exportMode=true')} >Imprimir</EASButton>}
        sx={{ maxHeight: 'calc(100vh - 200px)' }}>
        <Box>
          <PresentationResourcesHumanFinal toPrint={false} />
        </Box>
      </EASCard>
    </LayoutDefault>
  );
};
