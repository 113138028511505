import { createTheme } from '@mui/material';
import { grey, orange } from '@mui/material/colors';

// Augment the palette to include an ochre color


export const LightTheme = createTheme({
  palette: {
    primary: {
      main: orange[800],
      dark: orange[900],
      light: orange[700],
      contrastText: '#ffffff',
    },
    secondary: {
      main: grey[200],
      dark: grey[300],
      light: grey[100],
      contrastText: '#ffffff',
    },
    background: {
      paper: '#FFFFFF',
      default: '#eef2f6',
    },
    text: {
      primary: '#333333',
      secondary: '#8e8b8b',
      disabled: '#8e8b8b',
    },
    eas: {
      inputBackground: '#f5f9fb',
      backgroundTask: '#ffe9d6',
    },
  },
});

