import { useRef } from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import { useSnackbar } from 'notistack';

import { UtilService } from '../../shared/services/api/util/UtilService';
import { VideosService } from '../../shared/services/api/videos/VideosService';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LayoutDefault } from '../../shared/layouts';

import VideoTab, { IRefVideo } from './VideoTab';

import { EASCard } from '../../shared/components';
import { EASButton } from '../../shared/forms';
import { IVideo } from '../../shared/types';


export const Video = () => {

    const { id = ''} = useParams<'id'>();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    
    const refVideo = useRef<IRefVideo>(null);

    const storeDisplay = () => {
        if(refVideo.current)
            refVideo.current.saveVideo();
    };

    const returnList = () => {
        queryClient.invalidateQueries('operator-video-edit');
        navigate('/videos');
    };

    const { data:tags, isLoading:loadingTag  } = useQuery(['tags-list'],() =>  UtilService.getTags(),  {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista Tags.', { variant:'error'});
      },
    });
  
    const { data:video, isLoading, isFetching } = useQuery(['operator-video-edit', id],() =>  VideosService.getVideo(id),  {
      staleTime: Infinity,
      onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista Tags.', { variant:'error'});
      },
    });
    
    const { mutateAsync:storeUpdate, isLoading: isLoadingStore } = useMutation((updateVideo:IVideo) =>VideosService.updateVideo(updateVideo), {
        onSuccess: () => {
            queryClient.invalidateQueries('operator-video-edit');
            queryClient.invalidateQueries('operator-video-list');
            enqueueSnackbar('Alterações salvas com sucesso!', { variant:'success'});
        },
        onError: () => {
            enqueueSnackbar('Erro ao salvar video.', { variant:'error'});
        },
    });



    return (
        <LayoutDefault >
            <EASCard 
                titleCard={'Editar Vídeo'}
                breadcrumbsCard=""
                actionCard={<Stack direction="row" spacing={1}>
                                <EASButton variant="contained" onClick={storeDisplay}>Salvar Alterações</EASButton>
                                <EASButton variant="outlined" onClick={() => returnList()}>Sair</EASButton>
                            </Stack>} >
                <Box sx={{mt: 2}}>
                    <VideoTab ref={refVideo} tags={tags} videoEdit={video} isLoading={(isFetching || isLoading || loadingTag || isLoadingStore)} storeUpdate={(video) => storeUpdate(video)} />
                </Box>
            </EASCard>
        </LayoutDefault>
    );
};
