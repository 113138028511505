import React from 'react';
import {Box, Typography, IconButton, Icon, Theme, useMediaQuery, useTheme, Hidden, Avatar} from '@mui/material';

import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightIcon from '@mui/icons-material/Nightlight';

import { useDrawerContext, useEASThemeContext, useMentoringContext, useModulesContext } from '../../contexts';
import { AccountPopover } from './accountPopover';
import { NotificationsPopover } from './notificationsPopover';
import { SelectModulePopover } from './selectModulePopover';
import { Environment } from '../../environment';

interface IEASNavbarProps {
  titulo?: string;
  iconTitle?: string;
  hideSidebar?: boolean;
}

export const Header: React.FC<IEASNavbarProps> = ({ 
  titulo, 
  iconTitle,
  hideSidebar,
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));


  const { toggleDrawerOpen } = useDrawerContext();

  const { themeName, toggleTheme } = useEASThemeContext();
  const { isMentorySelected, mentory } = useMentoringContext();
  const { module } = useModulesContext();

  const imageLogo:string = (() => {
                        switch(module?.id_module) {
                          case '1': 
                          case '2':
                          case '3':
                            return (
                                      themeName === 'light'  && isMentorySelected &&  mentory?.image_program 
                                      ? (
                                          (
                                            Environment.AMBIENT == '1' 
                                            ? Environment.URL_CDN 
                                            : Environment.URL_CDN_SANDBOX
                                          ) + 'images/stories/program/medium_' + mentory?.image_program
                                        ) 
                                      : ( 
                                          themeName !== 'light'  && isMentorySelected &&  mentory?.image_dark_program
                                          ? (
                                              (
                                                Environment.AMBIENT == '1' 
                                                ? Environment.URL_CDN 
                                                : Environment.URL_CDN_SANDBOX
                                              ) + 'images/stories/program/medium_' + mentory?.image_dark_program
                                            ) 
                                          : 
                                            (
                                              '/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'
                                            )
                                        )
                                    );
                                    return '/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png';
                          default: 
                          return '/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png';
                      }
                      })();

  return (
      <>
      {!hideSidebar && (
        <>
          <Hidden mdDown>
            <Box width={theme.spacing(17)} display="flex" flexDirection="column" alignItems="flex-end" alignContent="flex-end" p={0} mr={2}>
              <Box
                component="img"
                src={imageLogo}
                sx={{ mt: 1, width: 100, height: 'auto'}}
              />
            </Box>
          </Hidden>
          <Box sx={{height:'60px', display:'flex',flexDirection:'row',alignItems:'center'}}>
            <Avatar sx={{mr:'20px', borderRadius:'8px', backgroundColor: theme.palette.primary.main + '14 !important',  width:'28px', height:'28px'}}>
              <IconButton onClick={toggleDrawerOpen}> 
                <Icon sx={{fontSize:'1.2rem'}}>menu</Icon>
              </IconButton>
            </Avatar>
          </Box>
        </>
        )}
        {/*hideSidebar && (
          <Box width={theme.spacing(17)} display="flex" p={0} mr={5}>
            <Box
              component="img"
              src={'/logo_login' + (themeName === 'light'   ? '' : '_d') + '.png'}
              sx={{ mx:'auto', mt: 1, width: 100, height: 'auto'}}
            />
          </Box>
        )*/}
        {(titulo && iconTitle) && (
        <Box sx={{ml:{xl:3, lg:3, md:3}, height:'60px', display:'flex',flexDirection:'row',alignItems:'center'}}>
          <Typography
            overflow="hidden"
            color="primary"
            whiteSpace="nowrap"
            textOverflow="ellipses"
            variant={'h6'}
            sx={{ pr:1, fontSize:'1.25rem'}}
          >
            {titulo}
          </Typography>

          {!xsDown && !smDown && (
          <Icon sx={{fontSize: 28,color:theme.palette.text.primary}}>{iconTitle}</Icon>
          )} 

        </Box>
        )}
 

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{height:'60px', display:'flex',flexDirection:'row',alignItems:'center'}}>
        
          <SelectModulePopover />
          
          <IconButton size={(xsDown || smDown ? 'small' : 'large')} onClick={toggleTheme}>
              {themeName == 'light' ? <NightlightIcon /> : <LightModeIcon />}
          </IconButton>
          
          
       
          <NotificationsPopover />
        
          <AccountPopover />
        </Box>
      </>
  );
};
