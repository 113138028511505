import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Table,
  Stack,
  Avatar,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  Chip,
  Box,
  Skeleton,
  Pagination,
  Grid,
} from '@mui/material';

import { IPerson } from '../../shared/types';
import { PeopleService } from '../../shared/services/api/people/PeopleService';
import { Environment } from '../../shared/environment';
import { PeopleNewPerson } from './PeopleNewPerson';
import { EASCard, EASDialogAlert, PaginationComponent} from '../../shared/components';
import { EASButton, EASButtonList, EASInput } from '../../shared/forms';
import { useDebounce } from '../../shared/hooks';
import { useMutation, useQuery, useQueryClient } from 'react-query';


export const PeopleList:React.FC = (() => {



  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [blockText, setBlockTest] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [open, setOpen] = useState(false);
  //const [randomString, setRandomString] = useState<string | null>(null);

  const [loadingDialog, setLoadingDialog] = useState(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState<string | null>('');
  const [textDialog, setTextDialog] = useState<ReactElement | null>(null);
  const [executeDialog, setExecuteDialog] = useState<'remove' | 'block' | 'unblock' | 'reset' | null>(null);
  const [idPerson, setIdPerson] = useState<string | null>(null);

  const randomString = useRef<string | null>(null);
  //const newRandomstring = [randomString];

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['operator-people-list', [search, page]],() =>  PeopleService.getPeople(page, search), {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de Pessoas.', { variant:'error'});
    },
  });

  const { mutateAsync:storeBlockPerson } = useMutation((blockPerson:IPerson) =>  PeopleService.updatePerson(blockPerson), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-people-list');
      enqueueSnackbar('Pessoa ' + (blockText ? 'desbloqueada' : 'bloqueada') + ' com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar ' + (blockText ? 'desbloquear' : 'bloquear') + ' a pessoa!', { variant:'error'});
    },
  });

  const { mutateAsync:storeRemovePerson } = useMutation((idPerson:string) => PeopleService.deletePerson(idPerson), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-people-list');
      enqueueSnackbar('Pessoa removida com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();      
    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar remover a pessoa!', { variant:'error'});
      setLoadingDialog(false);
    },
  });

  const { mutateAsync:storeResetPassword }  = useMutation((resetPassPerson:IPerson) =>  PeopleService.updatePerson(resetPassPerson), {
    onSuccess: () => {
      queryClient.invalidateQueries('operator-people-list');
      enqueueSnackbar('Senha Redefinida com sucesso!', { variant:'success'});
      setLoadingDialog(false);
      handleCancelDialog();
      setTitleDialog('Senha Redefinida com sucesso');
      setTextDialog(<>
                      <Typography component="span" sx={{display:'flex'}}>A nova senha desta pessoa é:<strong>{randomString.current}</strong>.</Typography>
                      <Typography component="span">Quando for realizado o próximo login, será solicitado que seja registrado uma nova senha.</Typography>
                    </>
                    );
      setOpenDialogSuccess(true);
      randomString.current = null;

    },
    onError: () => {
      enqueueSnackbar('Erro ao tentar redefinir a senha desta pessoa', { variant:'error'});
    },
  });

  const handleClickRemovePerson = (id?:string) => {
    if(id){
      setIdPerson(id);
      setTitleDialog('Excluir Pessoa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja excluir definitivamene esta pessoa? A exclusão removerá todo histórico vinculado a esta pessoa.</Typography>);
      setExecuteDialog('remove');
      setOpenDialog(true);
    }
  }; 

  const handleClickBlockPerson = (id?:string) => {
    if(id){
      setIdPerson(id);
      setTitleDialog('Bloquear Pessoa');
      setTextDialog(<Typography component="span">Você tem certeza que deseja bloquear o acesso desta pessoa?</Typography>);
      setExecuteDialog('block');
      setOpenDialog(true);
    }
  }; 

  const handleClickUmBlockPerson = (id?:string) => {
    if(id){
      setIdPerson(id);
      setTitleDialog('Desbloquear Pessoa');
      setTextDialog(<Typography component="span">Você deseja desbloquear o acesso desta pessoa?</Typography>);
      setExecuteDialog('unblock');
      setOpenDialog(true);
    }
  }; 

  const handleClickResetPerson = (id?:string) => {
    if(id){
      setIdPerson(id);
      setTitleDialog('Redefinir Senha');
      setTextDialog(<Typography component="span">Você tem certeza que deseja redefinir a senha desta pessoa? Ao confirmar, será gerado uma nova senha aleatória para esta pessoa.</Typography>);
      setExecuteDialog('reset');
      setOpenDialog(true);
    }
  }; 
  
  const handleCancelDialog = () => {
    setIdPerson(null);
    setTitleDialog(null);
    setTextDialog(null);
    setExecuteDialog(null);
    setOpenDialog(false);
    setOpenDialogSuccess(false);
  };


  const handleConfirmeDialog = () => {
    switch(executeDialog){
      case 'remove':
        removePerson();
      break;
      case 'block':
        blockPerson(false);
      break;
      case 'unblock':
        blockPerson(true);
      break;
      case 'reset':
        resetPassword();
      break;
    }
  };

  const removePerson = () => {
    setLoadingDialog(true);
    if(idPerson){
      storeRemovePerson(idPerson);
    }
  };

  const blockPerson = (block:boolean) => {
    setLoadingDialog(true);
    if(idPerson){
      setBlockTest(block);
      const newBlockPerson:IPerson = {
        id_user:idPerson,
        status_user:(block ? '1' : '0')
      };
      storeBlockPerson(newBlockPerson);
    }
  };

  const resetPassword= () => {
    setLoadingDialog(true);
    if(idPerson){
      const newRandomstring = Math.random().toString(36).slice(-8);
      randomString.current = newRandomstring;
      const resetPerson:IPerson = {
        id_user:idPerson,
        password_user:newRandomstring,
        activation_user:'1',
      };
      storeResetPassword(resetPerson);
    }
  };

  return (
    <>          
      <EASCard 
        titleCard="Pessoas"
        actionCard={<Stack minWidth='500px' direction='row' sx={{display: 'flex', justifyContent:'flex-end', alignItems: 'center'}}>  
                      <EASInput label="Pesquisar" type='search' value={search} onChange={(textSearch) => setSearchParams({ search: textSearch.target.value }, {replace: true})} nameIconLeft='search' />
                      <EASButton sx={{marginLeft: 2}} variant='contained' onClick={() => setOpen(true)}>Novo</EASButton>
                    </Stack>
                    }>
                    
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  Id
                </TableCell>
                <TableCell component="th" scope="row">
                  Nome
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Status
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Cidade/UF
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading && (
            <>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            </>
            )}
            {!isLoading && ( (data?.data && data.data.length) ? (data.data.map((person) => (
              <TableRow 
                  hover 
                  key={person.id_user} 
                  tabIndex={-1} 
                  role="checkbox"
              >
                <TableCell align="center">
                  {person?.id_user}
                </TableCell>
                <TableCell scope="row" >
                  <Grid container spacing={2}>
                    <Grid item>
                      <Avatar alt={person?.name_user} src={person?.image_user ? (Environment.AMBIENT == '1' ? Environment.URL_CDN : Environment.URL_CDN_SANDBOX) + 'images/stories/avatar/small_' + person?.image_user  :  ''} />
                    </Grid>
                    <Grid item zeroMinWidth sm={true}>
                      <Typography variant="subtitle2">
                        {person?.name_user}
                      </Typography>
                      <Typography variant="caption" component="h6" sx={{ overflow: 'hidde', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        {person?.email_user}
                      </Typography>
                    </Grid>
                  </Grid> 
                </TableCell>
                <TableCell align="center">
                  <Chip label={person?.status_user == '1'? 'Ativo' : 'Bloqueado'} color={person?.status_user == '1' ? 'success' : 'error'} />
                </TableCell>
                <TableCell align="center">
                    {person?.name_cidade_estado}
                </TableCell>
                <TableCell align="center">
                  <EASButtonList 
                      title="Editar Pessoa"
                      onClick={() => navigate(`/pessoas/detalhes/${person.id_user}`)} 
                      typeIcon="edit"
                  />
                  <EASButtonList 
                      title="Redefinir Senha"
                      onClick={() => handleClickResetPerson(person.id_user)} 
                      typeIcon="reset"
                  />
                  {person?.status_user == '0' ? (
                  <EASButtonList 
                      title="Desbloquear Pessoa"
                      onClick={() => handleClickUmBlockPerson(person.id_user)} 
                      typeIcon="unblock"
                  />
                  ) : (
                  <EASButtonList 
                    title="Bloquear Pessoa"
                    onClick={() => handleClickBlockPerson(person.id_user)} 
                    typeIcon="block"
                  />
                  )}
                  <EASButtonList 
                    title="Remover Pessoa"
                    onClick={() => handleClickRemovePerson(person.id_user)} 
                    typeIcon="remove"
                  />
                </TableCell> 
              </TableRow>
             ))) : (
              <TableRow hover tabIndex={-1} role="checkbox" >
                <TableCell colSpan={6} scope="row" >
                  Nenhum item encontrado 
                </TableCell>
              </TableRow>
             ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!isLoading  && (
          <PaginationComponent page={page} total={data?.total} setSearchParams={(newPage) => setSearchParams({ search, page: newPage.toString() }, { replace: true })} />
        )}
      </EASCard>
      <PeopleNewPerson open={open} setOpen={(e) => setOpen(e)} />
      <EASDialogAlert 
        type="warning"
        title={titleDialog}
        statusOpen={openDialog}
        actionCancel={handleCancelDialog}
        actionConfirme={handleConfirmeDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
      <EASDialogAlert 
        type="success"
        title={titleDialog}
        statusOpen={openDialogSuccess}
        actionCancel={handleCancelDialog}
        loading={loadingDialog}
      >
        {textDialog}
      </EASDialogAlert>
  </>
  );
});

