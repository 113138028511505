import React, { useState, useRef } from 'react';
import { Grid, styled, Box, Typography, Stepper, Step, StepLabel }from '@mui/material';

import { useSnackbar } from 'notistack';

import { EASButton } from '../../shared/forms';
import { useQuery } from 'react-query';

import { LayoutDefault } from '../../shared/layouts';
import { EASCard } from '../../shared/components';
import { IHumanResource } from '../../shared/contexts/HumanResourcesContext/types';
import { UtilService } from '../../shared/services/api/util/UtilService';

import  HumanResourceInfo, { IRefHumanResourceInfo} from './HumanResourceInfo';

const ContentStyleNew = styled('div')(() => ({
  maxWidth: 720,
  margin: '0 auto',
  /*display: 'flex',
  textAlign: 'center', 
  alignItems: 'center',*/
}));

export const SelectHumanResourceNew = () => {
  
  const { enqueueSnackbar } = useSnackbar();

  //const { user } = useAuthContext();

  const [humanresource, setHumanResource] = useState<IHumanResource>({});
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

    
  const steps = [['Parâmetros', 'Informações Básicas'], ['Participantes','Defina mentor e Mentorado'], ['Finalizar', 'Confirmar dados e salvar']];
  
  const refHumanResourceInfo = useRef<IRefHumanResourceInfo>(null);

  const handleNextInfo = () => {
      if(refHumanResourceInfo.current)
        refHumanResourceInfo.current.handleNextInfo();
  };


  function _renderStepContent(step:number) {
    switch (step) {
      case 0:
        return <HumanResourceInfo HumanResource={humanresource} tags={tags} isLoading={isLoading} ref={refHumanResourceInfo} setHumanResourceUpdate={(e) => setHumanResource(e)} setActiveStep={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}/>;
      case 1:
        console.log(humanresource);
        return <Box>2</Box>;
      case 2:
        return  <Box>3</Box>;
      default:
        return <div>Not Found</div>;
    }
  }
  


  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    switch(activeStep){
      case 0:
        handleNextInfo();
      break;
        default:
          alert(activeStep);
        break;
    }  
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.

      enqueueSnackbar('Erro ', { variant:'error'});
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { data:tags, isLoading  } = useQuery(['tags-list'],() => UtilService.getTags(),  {
    staleTime: Infinity,
    onError: () => {
        enqueueSnackbar('Ocorreu um problema carregar a lista de Tags.', { variant:'error'});
    },
});

  // const [codeProgramError, setCodeProgramError] = useState<string | null>(null);


 // 686661961571206656
/*
  const { isFetching, refetch } = useQuery('select-pogram-new', 
                                      () => ProgramsService.getProgramAddHumanResource(codeProgram), 
                                      { 
                                        onSuccess: data => {
                        
                                          // 686661961571206656
                                        },
                                        onError: () => {
                                          //teste
                                        },  
                                        keepPreviousData: true, 
                                        retry:1,
                                        refetchInterval:false,
                                        enabled:false
                                      });
  
*/
  return (
    <LayoutDefault hideSidebar={true}>
        <ContentStyleNew>
          <EASCard>
            <Box sx={{p:'24px'}}>
              <Grid container spacing={2}> 
                <Grid item xs={12}>
                  <Typography variant="h5" sx={{fontSize:'1.3rem', fontWeight: 700, py:2, textAlign:'center'}}>
                    Crie um programa para controlar os processos de mentoria
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                <Stepper activeStep={activeStep} >
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel optional={<Typography variant="caption">{label[1]}</Typography>}>{label[0]}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                </Grid>
                <Grid item xs={12}>
                <React.Fragment>
                  {activeStep === steps.length ? (
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                  ) : (
                    _renderStepContent(activeStep)
                  )}
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <EASButton onClick={handleReset}>Reset</EASButton>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <EASButton
                          variant="outlined"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Anterior
                        </EASButton>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                          <EASButton color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                          </EASButton>
                        )}
                        <EASButton variant="contained" onClick={handleNext}>
                          {activeStep === steps.length - 1 ? 'Salvar Processo' : 'Próximo'}
                        </EASButton>
                      </Box>
                    </React.Fragment>
                  )}
                </React.Fragment>
                </Grid>
              </Grid>
            </Box>
          </EASCard>
        </ContentStyleNew>
    </LayoutDefault>
  );
};
