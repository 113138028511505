import React from 'react';
//import ReactPlayer from 'react-player';
import ReactPlayer from 'react-player/youtube';
import { styled, Typography, Box, Stack, LinearProgress, Grid, Card, Chip } from '@mui/material';
import { MentoryVideosColum } from './MentoryVideosColum';

import { useMentoringContext  } from '../../shared/contexts';

import { VideosService } from '../../shared/services/api/videos/VideosService';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';



interface IMentoryVideoDetailProps {
  VideoID: string;
}  

export const MentoryVideoDetail: React.FC<IMentoryVideoDetailProps> = ({VideoID}) => {
  const { mentory } = useMentoringContext();
  const { enqueueSnackbar } = useSnackbar();
  const { data:video, isFetching } = useQuery(['view-video', VideoID],() => VideosService.getVideo(VideoID), {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Erro ao carregar video.', { variant:'error'});
    },
  });

  const { data:videos, isFetching:isFetchingList } = useQuery(['list-video-view', [mentory?.id_mentory, VideoID]],() =>  VideosService.getVideos(1, '', mentory?.id_mentory, VideoID),  {
    staleTime: Infinity,
    onError: () => {
      enqueueSnackbar('Ocorreu um problema carregar a lista de vídeos.', { variant:'error'});
    },
  });

  const ReactPlayerStyled = styled(ReactPlayer)(() => ({
      width: '100% !important', 
      height: 'auto !important', 
      aspectRatio: '16/9'
  }));
    

  return (
    <Box sx={{p:'18px'}}>
    {(isFetching && isFetchingList) && (
      <LinearProgress variant='indeterminate' />
    )}
    {!(isFetching && isFetchingList) && video && (
    <Grid container spacing={2}> 
      <Grid item md={12} lg={8}>
        <Grid container spacing={1}> 
          <Grid item xs={12}>
            <Card variant="outlined" sx={{border:'none', borderRadius:'12px'}}>
              <ReactPlayerStyled  url={`https://www.youtube.com/watch?v=${video?.code_video}`} className="react-player" controls  />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{fontSize:'1.3rem', fontWeight: 700}}>
            {video?.option_video == '1' ? video?.title : video?.name_video }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
            {video?.name_tags && video.name_tags.length > 0 && ( video.name_tags.map((tag, index) => (
              <Chip key={index} label={tag} size="small"  />
            )))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack justifyContent="space-between" py={1} px={2}>
              <Typography variant="body1">
              {video?.option_video == '1' ? (video?.description ? video?.description.replace(/<\/?[^>]+(>|$)/g, '') : '') : (video?.description_video ? video?.description_video.replace(/<\/?[^>]+(>|$)/g, '') : '') }
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} lg={4}>
        <MentoryVideosColum videos={videos?.data} direction="column" />
      </Grid>
    </Grid>
    )}
    </Box>
  );
};
