import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Icon, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

const EASListItemButton = styled(ListItemButton)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  padding: '10px 10px 10px 12px;',
  marginBottom:'4px',
  borderRadius: 8,
  width:'200px',
  border: '0',
  lineHeight: 1.5,
  color:theme.palette.text.primary,
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: theme.palette.primary.main + '14 !important',

    boxShadow: 'none',
    /*color:'#FFFFFF',*/
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main + '14 !important',
    /*color:'#FFFFFF',*/
  },
  '&:focus': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main + '14 !important',
    /*color:'#FFFFFF',*/
  },
  '&:selected': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main + '14 !important',
    
    '& .MuiListItemIcon-root': {
      color: 'white'
    }
  }, 

  '&$focused': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main + '14 !important',
  },
}));

interface IEASBtnSidebarProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}

export const BtnSideBar: React.FC<IEASBtnSidebarProps> = ({ to, icon, label, onClick }) => {

  const navigate = useNavigate();

  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <EASListItemButton selected={!!match} onClick={handleClick} >
      <ListItemIcon sx={{minWidth:'30px'}}>
        <Icon fontSize="small">{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={<Typography sx={{fontSize: '0.85rem'}}>{label}</Typography>}/>
    </EASListItemButton>
  );
};
   

